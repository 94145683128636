import env from "@/env";
import {
  CoStakableDevice,
  CoStakableDeviceResponse,
  CoStakeFilterFormType,
  DeviceHardwareResponse,
  UserStakedDevice,
  UserStakedDeviceResponse
} from "@/types/staking";
// import { clusterApiUrl } from "@solana/web3.js";
import { createContext } from "react";
// import { v4 as uuidV4 } from "uuid";
// import * as spl from "@solana/spl-token";
import crypto from "crypto";
import { web3, AnchorProvider, Program } from "@coral-xyz/anchor-0.30.1";
import IDL from "./contracts/idl.devnet.json";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { sleep } from ".";
import { StakingType } from "./contracts/idl.devnet.types";
import { executeAPIRequest, toFriendlyError } from "./api";
import { stringify } from "qs";
import { convertLamportBack, convertToLamport } from "@/store/solution3/utils";
import { getHardware } from "./mapping";
import { DeviceBlockEarningsResponseType, DeviceHardware } from "@/types";
import { normaliseUserStakedDevice } from "@/store/staking";
import { toDuration } from "./date";
import { normaliseDeviceBlockEarnings } from "@/store/device";
import { AreaGraphDataType } from "@/types";
import { pad } from "./string";
// import DASHBOARD_STAKING_METRICS from "./staking-metrics.json";

export const StakeDeviceResultsDataTableContext = createContext<{
  setSelectedDevice: React.Dispatch<React.SetStateAction<UserStakedDevice | undefined>>;
  setSelectedAction: React.Dispatch<React.SetStateAction<string | undefined>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}>(undefined as any);

export const getStakingNetworkApiEndpoint = () => {
  // if (env.STAKING_NETWORK === "devnet") {
  //   return clusterApiUrl(env.STAKING_NETWORK);
  // }
  return env.STAKING_NETWORK_API_ENDPOINT;
};

// const generateKeypairFromSeed = (seed: string) => {
//   const bytes1 = new TextEncoder().encode(seed);
//   let seedUint = new Uint8Array([...bytes1]);
//   let finalSeeds = new Uint8Array(32);
//   finalSeeds.set(seedUint.subarray(0, 32));
//   return web3.Keypair.fromSeed(finalSeeds);
// };

export const uuidToSha256 = (uuid: string): string => {
  // Remove hyphens from the UUID
  const cleanedUuid = uuid.replace(/-/g, "");
  // Create a SHA-256 hash of the UUID
  const hash = crypto.createHash("sha256").update(cleanedUuid).digest("hex");
  // Truncate the hash to 32 characters
  return hash.slice(0, 32);
};

export const getProgram = (wallet: AnchorWallet): Program<StakingType> => {
  // const anchorProvider = new AnchorProvider(
  //   connection,
  //   new Wallet(baseSigner),
  //   {
  //     commitment: "confirmed",
  //   }
  // );
  // return new Program(IDL as any, anchorProvider) as Program<Iostaking>;

  const anchorProvider = new AnchorProvider(connection, wallet, {
    commitment: "confirmed"
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return new Program(IDL as any, anchorProvider) as Program<StakingType>;
};

const connection = new web3.Connection(
  "https://devnet.helius-rpc.com/?api-key=c18b5571-f7f2-4aa9-8f54-d20028d0dea0",
  "confirmed"
);

// const baseSigner = generateKeypairFromSeed("admin");

export const getDeviceInfo = async (id: string, { program }: { program: Program<StakingType> }) => {
  // const id = uuidToSha256(deviceUid);

  const [nodeAccount] = web3.PublicKey.findProgramAddressSync(
    [Buffer.from("node"), Buffer.from(id)],
    program.programId
  );

  console.log("__nodeAccount", nodeAccount.toBase58());

  const nodeAccountInfo = await program.account.nodeAccount.fetchNullable(nodeAccount);

  console.log("__nodeAccountInfo", nodeAccountInfo);

  const nodeAccountInfoAll = await program.account.nodeAccount.fetchMultiple([nodeAccount]);

  console.log("__all", nodeAccountInfoAll);

  // const globalStakeCreateKey = await getFundedKeyPair();

  // const [stakeAccount] = web3.PublicKey.findProgramAddressSync(
  //   [Buffer.from("stake"), globalStakeCreateKey.publicKey.toBuffer()],
  //   program.programId
  // );

  // const stakeAccountInfo = await program.account.stakeAccount.fetchNullable(stakeAccount);

  // console.log("__stakeAccountInfo", stakeAccountInfo);
};

export const fundSOL = async (account: web3.PublicKey) => {
  try {
    await connection.requestAirdrop(account, 1 * web3.LAMPORTS_PER_SOL);

    await sleep(2000);
    // console.log("Funded::", account.toBase58());
  } catch (e) {
    console.error(e);
  }
};

export const getFundedKeyPair = async () => {
  const randomKeypair = web3.Keypair.generate();
  await fundSOL(randomKeypair.publicKey);
  return randomKeypair;
};

/*
{
    "id": "30cdd1fce6dd8ac95761f6b2d8a373cf",
    // device owner (wallet address)
    "authority": "BZCVS4xTifHFrpNCHNdZkrWodaNLEGnfKRL1wuXKcZBm",
    "config": {
        // convert to decimal - block reward commission
        "brCommission": "2710",
        // minimum stake
        "minStake": "0174876e8000",\
        // 50 - 100 (basis points). 1770 = bigint of 60% - function = bn
        "coStakingSplit": "1770"
    },
    // address where tokens are staked
    "nodeStakeAccount": "FpyedctG8Ra5r2QbEwoU7AJm2jBa7KWz8ffd3SNhn2dh",
    // address where co staker stake is
    "coStakeStakeAccount": "A9VXq5gXBfia67DQHwPUDdZWiSKR9dEDv7T2jzWeAZaJ",
    // if co staking has been enabled by the device owner
    "coStakingEnabled": true,
    // ignore
    "bump": 255,
    // if the device has gone into unstaked. unstake specific to the device owner
    "unstakeStarted": false,
    "padding": [
        "00",
        "00",
        "00",
        "00",
        "00",
        "00",
        "00",
        "00"
    ]
}

{
    // on chain represenation of the stake (on chain id)
    "publicKey": "2g5KPvi6EtovMTH4hkAG13FwgCHuvTdSCwyEo2ve6jt3",
    "account": {
      "createKey": "DR7Lci51QkzEiTW71CzYbhaNPKMsJTRFyYCqFWuwq5n2",
      // whos node the stake is linked to
      "node": "6exZpDuc3k6HH3cmgPVcQcr1gyScVPZQBfBmggZ9kXeF",
      // co staker or device owner
      "authority": "66mNXUrN92yYjRvoy3TYYoa7AtYtiB8JPzVrciMoytpH",
      // when the cooldown ends
      "cooldownTime": null,
      // for co staker. co staker has unstaked (7 day penalty)
      "noticeTime": null,
      "bump": 255,
      "padding": [
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0"
      ]
    }
  },

  Requested Amount
  Minimum stake = 16000 tokens
  If minStake = 6000 = 60% (200 basis points)

  150/200 (on UI)
  6400/16000

  Offered % Of Rewards
  100 - brCommission
  Divide brCommission / 100 to convert to %
*/

export const createCoStakingOffer = async ({
  deviceId,
  //userAccount,
  costakeContribution,
  sharedBlockRewardsPercent,
  transactionHash
}: {
  deviceId: string;
  //userAccount: string;
  costakeContribution: number;
  sharedBlockRewardsPercent: number;
  transactionHash: string;
}) => {
  try {
    const response = await executeAPIRequest({
      method: "post",
      url: `/io-worker/users/staking/${deviceId}/offer`,
      options: {
        publicError: true,
        data: {
          co_staking_portion: costakeContribution,
          //user_account: userAccount,
          block_rewards_percent: sharedBlockRewardsPercent,
          serialize_transaction: transactionHash
        }
      }
    });

    return response;
  } catch (e) {
    throw toFriendlyError(e);
  }
};

export const acceptCoStakingOffer = async ({
  userId,
  deviceId,
  costakerAccount,
  //costakeAmount,
  serializedTransaction
}: {
  userId: string;
  deviceId: string;
  costakerAccount: string;
  costakeAmount?: number;
  serializedTransaction: string;
}) => {
  try {
    const response = await executeAPIRequest({
      method: "post",
      url: `/io-worker/users/${userId}/staking/${deviceId}/offer/co_stake`,
      options: {
        publicError: true,
        data: {
          costaker_account: costakerAccount,
          //costake_amount: costakeAmount,
          serialize_transaction: serializedTransaction
        }
      }
    });

    return response;
  } catch (e) {
    throw toFriendlyError(e);
  }
};

export const updateCoStakingOffer = async ({
  userId,
  deviceId,
  shareSplit,
  blockRewardsSplit,
  transactionHash
}: {
  userId: string;
  deviceId: string;
  shareSplit: number;
  blockRewardsSplit: number;
  transactionHash: string;
}) => {
  try {
    const response = await executeAPIRequest({
      method: "put",
      url: `/io-worker/users/${userId}/devices_staking/${deviceId}/offer`,
      options: {
        publicError: true,
        data: {
          co_staking_portion: shareSplit,
          shared_block_rewards_precent: blockRewardsSplit,
          serialize_transaction: transactionHash
        }
      }
    });

    return response;
  } catch (e) {
    throw toFriendlyError(e);
  }
};

export const cancelCoStakingOffer = async ({
  deviceId,
  transactionHash
}: {
  deviceId: string;
  transactionHash: string;
}) => {
  try {
    const response = await executeAPIRequest({
      method: "post",
      url: `/io-worker/users/staking/${deviceId}/offer/cancel`,
      options: {
        publicError: true,
        data: {
          serialize_transaction: transactionHash
        }
      }
    });

    return response;
  } catch (e) {
    throw toFriendlyError(e);
  }
};

export const fetchCoStakedDeviceOffers = async (
  options: {
    hardware: string;
    numberOfGpu: number;
    status: "open" | "staked" | "all";
    limit: number;
    offset: number;
  } & CoStakeFilterFormType
) => {
  const {
    hardware,
    numberOfGpu,
    blockRewardPercentage,
    requestedAmountMin,
    requestedAmountMax,
    deviceReliabilityScore,
    device,
    status,
    limit,
    offset
  } = options;

  try {
    const response = await executeAPIRequest<{
      status: string;
      total_results: number;
      data: CoStakableDeviceResponse[];
      hardwares: DeviceHardwareResponse[];
    }>({
      method: "get",
      url: `/io-worker/co-staking/offers?${stringify({
        ...(hardware === "all"
          ? {}
          : {
              hardware_id: hardware
            }),
        number_of_gpu: numberOfGpu,
        block_reward_percent: blockRewardPercentage?.[0],
        ...(!isNaN(Number(requestedAmountMin))
          ? {
              least_co_stake_amount: convertToLamport(`${requestedAmountMin}`)
            }
          : {}),
        ...(!isNaN(Number(requestedAmountMax))
          ? {
              highest_co_stake_amount: convertToLamport(`${requestedAmountMax}`)
            }
          : {}),
        ...(!isNaN(Number(deviceReliabilityScore?.[0])) && deviceReliabilityScore?.[0] > 0
          ? {
              minimum_reliability_score: deviceReliabilityScore?.[0]
            }
          : {}),
        ...(status !== "all"
          ? {
              status
            }
          : {}),
        ...(device
          ? {
              device_id: device
            }
          : {}),
        limit,
        offset
      })}`
    });

    return {
      resultCount: response.total_results,
      statuses: [],
      results: response.data.map((item) => {
        return normaliseCoStakableDevice(item);
      }),
      hardwares: response.hardwares.map((item) => {
        return normaliseDeviceHardware(item);
      })
    };
  } catch (e) {
    throw toFriendlyError(e);
  }
};

export const fetchUserCoStakedDevices = async (options: {
  userId: string;
  hardwareName?: string;
  status: string;
}) => {
  try {
    const { userId, hardwareName, status } = options;

    const response = await executeAPIRequest<{
      status: string;
      data: {
        page: number;
        page_size: number;
        devices: UserStakedDeviceResponse[];
        hardware: string[];
        statuses: string[];
      };
    }>({
      method: "get",
      url: `/io-worker/users/${userId}/devices_staking/co_staked?page_size=100${stringify({
        ...(hardwareName !== "All Devices"
          ? {
              hardware_name: hardwareName
            }
          : {}),
        ...(status !== "all"
          ? {
              co_stake_status: status
            }
          : {})
      })}`
    });
    const { data } = response;

    console.log(options);

    const results = data.devices.map((item) => {
      return normaliseUserStakedDevice(item);
    });

    return {
      resultCount: results.length,
      statuses: [],
      results,
      hardwares: data.hardware.map((item) => {
        return {
          hardwareId: item,
          hardwareName: item
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any;
      })
    };
  } catch (e) {
    throw toFriendlyError(e);
  }
};

export const fetchUserSoloCoStakedDevices = async ({
  userId,
  deviceId
}: {
  userId: string;
  deviceId: string;
}) => {
  try {
    const response = await executeAPIRequest<{
      data: CoStakableDeviceResponse[];
    }>({
      method: "get",
      url: `/io-worker/users/${userId}/devices_staking/${deviceId}/devices_staking?page_size=100${stringify(
        {
          //
        }
      )}`
    });

    return response.data.map((item) => {
      return normaliseCoStakableDevice(item);
    });
  } catch (e) {
    throw toFriendlyError(e);
  }
};

export const fetchUserThirdPartyCoStakedDevices = async ({ userId }: { userId: string }) => {
  try {
    const response = await executeAPIRequest<{
      data: CoStakableDeviceResponse[];
    }>({
      method: "get",
      url: `/io-worker/users/${userId}/devices_staking/third_party_co_staking?page_size=100${stringify(
        {
          //
        }
      )}`
    });

    const results = response.data.map((item) => {
      return normaliseCoStakableDevice(item);
    });

    return {
      resultCount: results.length,
      results
    };
  } catch (e) {
    throw toFriendlyError(e);
  }
};

const normaliseCoStakableDevice = (response: CoStakableDeviceResponse) => {
  const hardware = getHardware(response.brand_name);
  const earnedBlockRewards =
    typeof response.earned_block_rewards === "number" ? response.earned_block_rewards : 0;
  const sharedBlockRewardsPercent = response.shared_block_rewards_percent;
  const estimatedBlockRewards =
    typeof response.estimated_block_rewards === "number" ? response.estimated_block_rewards : 0;
  const minimumStakeAmount = parseFloat(convertLamportBack(response.minimum_stake_amount));
  const coStakeAmount = parseFloat(convertLamportBack(response.co_stake_amount));
  const stakeAmount = parseFloat(convertLamportBack(response.stake_amount));
  const coStakeContribution = (coStakeAmount / minimumStakeAmount) * 100;
  const status = response.status;

  const frozenDuration = getStakeFrozenDuration();
  const freezeEndTime =
    typeof response.cooldown_ends_at === "string"
      ? new Date(Date.parse(`${response.cooldown_ends_at}Z`))
      : null;
  const freezeEndInterval =
    freezeEndTime instanceof Date
      ? Math.max(0, freezeEndTime.getTime() - new Date().getTime())
      : undefined;
  const remainingInterval = freezeEndInterval === 0 ? undefined : freezeEndInterval;
  const isInCooldown = remainingInterval !== undefined;
  const frozenTimeRemaining =
    typeof remainingInterval === "number" ? toDuration(remainingInterval) : undefined;
  const frozenTimeShortRemaining =
    typeof frozenTimeRemaining === "string"
      ? frozenTimeRemaining.split(" ").slice(0, 2).join(" ")
      : undefined;
  const frozenTimeRemainingAsPercentage =
    typeof remainingInterval === "number"
      ? Math.min(100, Math.max(0, ((frozenDuration - remainingInterval) / frozenDuration) * 100))
      : undefined;
  let amountFrozen = isInCooldown ? coStakeAmount : 0;
  let amountWithdrawable = coStakeAmount;

  if (isInCooldown) {
    amountWithdrawable = 0;
    amountFrozen = coStakeAmount;
  } else {
    amountWithdrawable = coStakeAmount;
    amountFrozen = 0;
  }

  return {
    id: response.id,
    deviceId: response.device_id,
    ownerAccount: response.owner_account,
    ownerId: response.owner_id,
    coStakerAccount: response.costaker_account,
    coStakerId: response.costaker_id,
    sharedBlockRewardsPercent,
    coStakeAmount,
    createdAt: response.created_at,
    minimumStakeAmount,
    stakeAmount,
    hardwareId: response.hardware_id,
    hardwareQuantity: response.hardware_quantity,
    brandId: response.brand_id,
    hardwareName: response.hardware_name,
    ...hardware,
    brandName: response.brand_name,
    totalCount: response.total_count,
    estimatedBlockRewards,
    coStakerEstimatedBlockRewards: (sharedBlockRewardsPercent / 100) * estimatedBlockRewards,
    coStakeContribution,
    ownerContribution: 100 - coStakeContribution,
    ownerStakeAmount: minimumStakeAmount - coStakeAmount,
    reliabilityScore: response.reliability_score,
    minimumStaked: coStakeAmount,
    canUnstakeCustomAmount: false,
    amountStaked: coStakeAmount,
    coStakeOfferId: response.co_stake_offer_id,
    amountWithdrawable,
    type: "costaked",
    status,
    freezeEndTime,
    frozenTimeRemaining,
    frozenTimeShortRemaining,
    frozenTimeRemainingAsPercentage,
    amountFrozen,
    isCoStaker: true,
    earnedBlockRewards
  } as CoStakableDevice;
};

export const CoStakeDeviceResultsDataTableContext = createContext<{
  setSelectedDevice: React.Dispatch<React.SetStateAction<CoStakableDevice | undefined>>;
  setSelectedAction: React.Dispatch<React.SetStateAction<string | undefined>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}>(undefined as any);

const normaliseDeviceHardware = (response: DeviceHardwareResponse) => {
  const hardware = getHardware(response.brand_name);

  return {
    hardwareId: `${response.hardware_id}`,
    brandId: `${response.brand_id} `,
    brandName: response.brand_name,
    hardwareName: response.hardware_name,
    ...hardware
  } as DeviceHardware;
};

export const DEVICE_STATUS_MAP = {
  open: {
    statusLabel: "Sufficient Stake (Waiting for Co-Staker)",
    statusColor: "up"
  },
  staked: {
    statusLabel: "Sufficient Stake (Active Co-Staking)",
    statusColor: "up"
  },
  cooldown: {
    statusLabel: "In Cooldown",
    statusColor: "up"
  },
  "costaker-waitperiod": {
    statusLabel: "Co-Staker Unstaked (In Wait Period)",
    statusColor: "up"
  },
  "costaker-withdrawn": {
    statusLabel: "Co-Staker Withdrawn",
    statusColor: "up"
  },
  "costaker-unstaked": {
    statusLabel: "Co-Staker Unstaked (In Wait Period)",
    statusColor: "failed"
  },
  closed: {
    statusLabel: "Offer Closed",
    statusColor: "failed"
  },
  deleted: {
    statusLabel: "Offer Deleted",
    statusColor: "failed",
    hidden: true
  },
  open_insufficient: {
    statusLabel: "Insufficient Stake (Waiting for Co-Staker)",
    statusColor: "failed"
  }
};

// 2 mins or 14 days
export const getStakeFrozenDuration = () => {
  return env.STAKING_NETWORK === "devnet" ? 1000 * 60 * 2 : 1000 * 60 * 60 * 24 * 14;
};

export const fetchCoStakingBlockEarnings = async ({
  fromDate,
  toDate
}: {
  fromDate: string;
  toDate: string;
}) => {
  const response = await executeAPIRequest<DeviceBlockEarningsResponseType>({
    method: "get",
    url: `/io-blocks/users/block-rewards-summary/${fromDate}/${toDate}?co_staking=true`
  });

  return {
    total: response.total_block_rewards,
    items: normaliseDeviceBlockEarnings(response)
  };
};

export const fetchStakingMetrics = async () => {
  const response = await executeAPIRequest<{
    status: string;
    data: {
      net_staked_amount: number;
      net_staked_amount_in_usd: number;
      gross_staked_amount: number;
      net_staked_amount_in_cooldown: number;
      device_count_with_full_stake: number;
      card_count_with_full_stake: number;
      daily_net_staked_amount: {
        day: string;
        cumulative_net_staked: number;
      }[];
      daily_gross_staked_amount: {
        day: string;
        cumulative_gross_staked: number;
      }[];
      daily_unique_stakers: {
        day: string;
        unique_stakers: number;
      }[];
    };
  }>({
    method: "get",
    url: `/io-explorer/staking/metrics`
  });

  const coStakingResponse = await fetchCoStakingMetrics();

  const { data } = response;
  const categoryIds = [
    "daily_net_staked_amount",
    "daily_gross_staked_amount",
    "daily_unique_stakers"
  ];

  return {
    totals: {
      netStakedAmount: data.net_staked_amount,
      netStakedAmountInUsd: data.net_staked_amount_in_usd,
      grossStakedAmount: data.gross_staked_amount,
      netStakedAmountInCooldown: data.net_staked_amount_in_cooldown,
      deviceCountWithFullStake: data.card_count_with_full_stake,
      coStakingContribution: coStakingResponse.net_staked_amount,
      uniqueCoStakingDevices: coStakingResponse.unique_participating_device_count
    },
    categories: categoryIds
      .map((id) => {
        const category =
          STAKING_DASHBOARD_CATEGORIES[id as keyof typeof STAKING_DASHBOARD_CATEGORIES];
        if (!category) {
          return;
        }

        const { dataKey, valueKey } = category;

        const points = (
          data[dataKey as "daily_net_staked_amount"] as {
            day: string;
          }[]
        )?.map((item) => {
          const { day } = item;
          const value = item[valueKey as keyof typeof item] as unknown as number;

          return {
            time: day,
            value
          } as AreaGraphDataType;
        });

        return {
          ...category,
          data: points
        };
      })
      .filter((item) => !!item) as {
      title: string;
      tooltip?: string;
      unit: string;
      data: AreaGraphDataType[];
      color: string;
    }[]
  };
};

const STAKING_DASHBOARD_CATEGORIES = {
  daily_net_staked_amount: {
    dataKey: "daily_net_staked_amount",
    valueKey: "cumulative_net_staked",
    title: "Net Staked Amount ($IO)",
    unit: "",
    color: "#00C84F"
  },
  daily_gross_staked_amount: {
    dataKey: "daily_gross_staked_amount",
    valueKey: "cumulative_gross_staked",
    title: "Gross Staked Amount ($IO)",
    unit: "",
    color: "#0085EF"
  }
  // daily_net_staked_in_cooldown_amount: {
  //   dataKey: "daily_net_staked_amount",
  //   valueKey: "cumulative_net_staked",
  //   title: "Net Staked Amount In Cooldown",
  //   unit: "",
  //   color: "#FF2B39"
  // },
  // daily_unique_stakers: {
  //   dataKey: "daily_unique_stakers",
  //   valueKey: "unique_stakers",
  //   title: "Number Of Unique Stakers",
  //   unit: "",
  //   color: "#F19700",
  //   tooltip: "Data refreshed Daily at UTC midnight"
  // }
};

export const fetchCoStakingHardwareMetrics = async () => {
  const response = await executeAPIRequest<{
    status: string;
    data: {
      daily_full_stake_device_count: {
        hardware_id: number;
        report_date: string;
        device_count: number;
        card_count: number;
      }[];
      hardware_multiplier_details: {
        id: number;
        name: string;
        multiplier: number;
        type: "GPU" | "CPU";
      }[];
    };
  }>({
    method: "get",
    url: `/io-explorer/staking/daily-full-stake-device-count`
  });
  const { data } = response;

  const hardwareTypeMap = Object.fromEntries(
    HARDWARE_GROUPS.map((group) => {
      const { multiplierRange } = group;
      const hardwareTypes = data.hardware_multiplier_details
        .filter(({ multiplier, type }) => {
          if (!multiplierRange) {
            return type === "CPU";
          }
          return (
            multiplier >= multiplierRange[0] && multiplier <= multiplierRange[1] && type === "GPU"
          );
        })
        .map(({ id }) => {
          return `${id}`;
        });

      return [group.title, hardwareTypes];
    })
  );

  const hardwareNameMap = Object.fromEntries(
    data.hardware_multiplier_details.map(({ id, name }) => {
      return [id, name];
    })
  );

  const dailyData = data.daily_full_stake_device_count.sort((a, b) => {
    return Date.parse(a.report_date) - Date.parse(b.report_date);
  });

  const dailyLookup: Record<
    string,
    {
      hardwareId: string;
      deviceCount: number;
    }[]
  > = {};

  for (const item of dailyData) {
    const { report_date, hardware_id, card_count } = item;
    const dateString = new Date(Date.parse(report_date)).toDateString();

    if (!dailyLookup[dateString]) {
      dailyLookup[dateString] = [];
    }

    dailyLookup[dateString].push({
      hardwareId: `${hardware_id}`,
      deviceCount: card_count
    });
  }

  const range = [0, 1].map((idx) => {
    return new Date(Date.parse(dailyData[idx === 0 ? 0 : dailyData.length - 1].report_date));
  });

  const limit = range[1];
  let date = range[0];

  const series: Record<string, AreaGraphDataType[]> = Object.fromEntries(
    HARDWARE_GROUPS.map(({ title }) => {
      return [title, []];
    })
  );

  while (date <= limit) {
    const dateString = date.toDateString();
    const dayString = `${date.getFullYear()}-${pad(`${date.getMonth() + 1}`)}-${pad(`${date.getDate()}`)}`;
    const dailyValues = dailyLookup[dateString];

    for (const { title } of HARDWARE_GROUPS) {
      const dailyHardwareTypes = hardwareTypeMap[title];
      const dailyDevicesForGroup = dailyValues.filter(({ hardwareId }) => {
        return dailyHardwareTypes.includes(hardwareId);
      });

      const dailyDeviceCountForGroup = dailyDevicesForGroup.reduce((acc, { deviceCount }) => {
        return acc + deviceCount;
      }, 0);

      series[title].push({
        time: dayString,
        value: dailyDeviceCountForGroup,
        devices: dailyDevicesForGroup.map(({ hardwareId, deviceCount }) => {
          return {
            hardwareId,
            hardwareName: hardwareNameMap[hardwareId],
            deviceCount
          };
        })
      });
    }

    date = new Date(date.getTime() + 24 * 60 * 60 * 1000);
  }

  return HARDWARE_GROUPS.map((group) => {
    const { title } = group;

    return {
      ...group,
      data: series[title]
    };
  });
};

// CPUs [CPUs]
// GPUs with earning multipliers < 1 [Consumer Grade GPUs]
// 1  <= GPUs with earning multipliers < 2 [Professional GPUs]
// 2 <= GPUs with earning multipliers < 5 [Entry-level Enterprise GPUs]
// 5<= GPUs with earning multipliers < 10 [Mid-level Enterprise GPUs]
// 10<= GPUs with earning multipliers [High-level Enterprise GPUs]

const HARDWARE_GROUPS = [
  {
    title: "CPUs",
    color: "rgb(255,43,58)"
  },
  {
    title: "Consumer Grade GPUs",
    multiplierRange: [0, 0.99],
    color: "rgb(0,134,255)"
  },
  {
    title: "Professional GPUs",
    multiplierRange: [1, 1.99],
    color: "rgb(0,200,79)"
  },
  {
    title: "Entry-level Enterprise GPUs",
    multiplierRange: [2, 4.99],
    color: "rgb(255,245,0)"
  },
  {
    title: "Mid-level Enterprise GPUs",
    multiplierRange: [5, 9.99],
    color: "rgb(255,161,0)"
  },
  {
    title: "High-level Enterprise GPUs",
    multiplierRange: [10, 100],
    color: "rgb(132,32,210)"
  }
];

export const fetchDailyStakingAprMetrics = async () => {
  const response = await executeAPIRequest<{
    status: string;
    data: {
      daily_apr: {
        date: string;
        apr: number;
      }[];
    };
  }>({
    method: "get",
    url: `/io-staking/daily_apr`
  });

  return response.data.daily_apr;
};

export const fetchStakingAprMetrics = async () => {
  const dailyAprMetrics = await fetchDailyStakingAprMetrics();
  const estimated = await fetchEstimatedStakingAprMetrics();

  return {
    dailyApr: dailyAprMetrics,
    ...estimated
  };
};

export const fetchEstimatedStakingAprMetrics = async () => {
  const response = await executeAPIRequest<{
    status: string;
    data: {
      estimated_staking_apr: number;
    };
  }>({
    method: "get",
    url: `/io-staking/estimated-staking-apr`
  });

  const { estimated_staking_apr } = response.data;

  return {
    estimatedApr: estimated_staking_apr
    // estimatedApr: null
  };
};

export const fetchSafetyModuleMetrics = async () => {
  const response = await executeAPIRequest<{
    status: string;
    data: {
      net_staked_amount: number;
      net_staked_amount_in_usd: number;
      unique_user_account_count: number;
      daily_net_staked_amount: {
        date: string;
        cumulative_net_staked: number;
      }[];
      daily_active_stake_user: {
        date: string;
        count: number;
      }[];
    };
  }>({
    method: "get",
    url: `/io-explorer/staking/safety-module/metrics`
  });

  const { data } = response;
  const categoryIds = ["daily_net_staked_amount", "daily_active_stake_user", "historical_apr"];

  const aprMetrics = await fetchStakingAprMetrics();
  const combinedData = {
    ...data,
    historical_apr: aprMetrics.dailyApr
  };

  return {
    totals: {
      netStakedAmount: data.net_staked_amount,
      netStakedAmountInUsd: data.net_staked_amount_in_usd,
      uniqueUserAccountCount: data.unique_user_account_count,
      historicalApr: aprMetrics.estimatedApr
    },
    categories: categoryIds
      .map((id) => {
        const category =
          SAFETY_MODULE_METRICS_CATEGORIES[id as keyof typeof SAFETY_MODULE_METRICS_CATEGORIES];
        if (!category) {
          return;
        }

        const { dataKey, valueKey } = category;

        const points = combinedData[dataKey as "daily_net_staked_amount"]?.map((item) => {
          const { date } = item;
          const value = item[valueKey as keyof typeof item] as unknown as number;

          return {
            time: date,
            value
          } as AreaGraphDataType;
        });

        return {
          ...category,
          data: points
        };
      })
      .filter((item) => !!item) as {
      title: string;
      tooltip?: string;
      unit: string;
      data: AreaGraphDataType[];
      color: string;
    }[]
  };
};

const SAFETY_MODULE_METRICS_CATEGORIES = {
  daily_net_staked_amount: {
    dataKey: "daily_net_staked_amount",
    valueKey: "cumulative_net_staked",
    title: "Net Staked Amount ($IO)",
    unit: "",
    color: "#00C84F"
  },
  daily_active_stake_user: {
    dataKey: "daily_active_stake_user",
    valueKey: "count",
    title: "Unique Active Participating Accounts",
    unit: "",
    color: "#0085EF"
  },
  historical_apr: {
    dataKey: "historical_apr",
    valueKey: "apr",
    title: "Historical APR",
    unit: "%",
    color: "#F19700"
  }
};

export const fetchCoStakingMetrics = async () => {
  const response = await executeAPIRequest<{
    status: string;
    data: {
      net_staked_amount: number;
      unique_participating_device_count: number;
    };
  }>({
    method: "get",
    url: `/io-explorer/staking/co-staking/metrics`
  });

  return response.data;
};

export const fetchIsWalletAddressIsVerified = async (walletAddress: string) => {
  await executeAPIRequest<unknown>({
    method: "post",
    url: `/me/verify-blockchain-wallet`,
    options: {
      data: {
        wallet_address: walletAddress
      }
    }
  });
};
