import { IconProps } from ".";

export const TickCircleOutlineIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99998 0.333313C3.31808 0.333313 0.333313 3.31808 0.333313 6.99998C0.333313 10.6819 3.31808 13.6666 6.99998 13.6666C10.6819 13.6666 13.6666 10.6819 13.6666 6.99998C13.6666 3.31808 10.6819 0.333313 6.99998 0.333313ZM9.51595 5.75549C9.7491 5.47053 9.7071 5.05051 9.42214 4.81736C9.13718 4.58421 8.71716 4.62621 8.48401 4.91117L5.95049 8.0077L5.13805 7.19526C4.8777 6.93491 4.45559 6.93491 4.19524 7.19526C3.93489 7.45561 3.93489 7.87772 4.19524 8.13807L5.52858 9.4714C5.66176 9.60459 5.84507 9.67522 6.03319 9.66584C6.22131 9.65646 6.39668 9.56793 6.51595 9.42216L9.51595 5.75549Z"
        fill="#59C884"
        style={{ fill: "color(display-p3 0.3490 0.7843 0.5176)", fillOpacity: 1 }}
      />
    </svg>
  );
};
