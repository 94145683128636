import { create } from "zustand";
import {
  getUserTokenBalance,
  IO_TOKEN_MINT,
  getStakeInfoByUserAccount,
  getAllStakeInfo
} from "@/store/solution3/solanaAndApi";
import { toFriendlyPublicError } from "@/utils/api";
import { ComputeBudgetProgram, PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import {
  SUPER_ADMIN_PUBKEY_ACTIVE,
  convertLamportBack,
  convertToLamport,
  findNodeAccount,
  findStakeAccount,
  uuidToOnchainId
} from "./solution3/utils";
import {
  ProxyToMiddlewareEndpoint,
  MiddlewareStakeEligibleDevice,
  MiddlewareStakeEligibleDeviceResponse,
  StakeEventType,
  UserStakedDevice,
  MiddlewareUserStakedDeviceResponse,
  UserStakedDeviceResponse,
  UserStakedDeviceResult,
  UserStakedDevicesRequestOptions,
  UserStakedDeviceStatusType,
  StakableDeviceOffer,
  StakableDeviceOffersRequestOptions,
  StakableDeviceOffersResult,
  CoStakableDevice,
  StakedTotalResult,
  CoStakedTotalResult,
  UserStakingTransactionsResponse,
  UserStakingTransactionsType,
  UserStakingRewardsResponse,
  UserStakingRewardsType
} from "@/types/staking";
import { AnchorWallet, WalletContextState } from "@solana/wallet-adapter-react";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  getAssociatedTokenAddressSync
} from "@solana/spl-token";
import { getProgramInstanceV2, getProgramInstanceInternal } from "./solution3/solanaProgram";
import { Iostaking } from "./solution3/programIdlV2";
import { Program, BN, web3 } from "@coral-xyz/anchor-0.30.1";
import { SOLANA_HOST } from "./solution3/const";
import { formatDateTime, toDuration } from "@/utils/date";
import eventDispatcher from "@/utils/eventDispatcher";
import { useUserStore } from "./user";
import { executeAPIRequest } from "@/utils/api";
import { stringify } from "qs";
import { PaginatedResponse } from "@/types";
import { DEFAULT_PAGINATION_LIMIT } from "@/constants";
import env from "@/env";
import { getHardware } from "@/utils/mapping";
import { STATUS_MAP } from "./device";
import { DeviceStatusType } from "@/utils/device";
import * as analytics from "@/utils/analytics";
import { AnalyticsEventType } from "@/constants/analytics";
import { AxiosError } from "axios";
import { errorIfAborted } from "@/utils";
import { logEvent } from "@/utils/betterstack";
import {
  acceptCoStakingOffer,
  createCoStakingOffer,
  getStakeFrozenDuration,
  cancelCoStakingOffer,
  //fetchCoStakedDeviceOffers,
  fetchUserThirdPartyCoStakedDevices
} from "@/utils/staking";
import { formattedDateTime } from "@/hooks/useFormatDateTime";

export type UseStakingStoreProps = {
  isReady: boolean;
  isConnected: boolean;
  isConnecting?: boolean;
  isStaking?: boolean;
  unstakingDevice?: UserStakedDevice;
  withdrawingDevice?: UserStakedDevice;
  costakingDevice?: UserStakedDevice;
  publicKey?: PublicKey;
  balance?: number;
  adapterName?: string;
  stakingInfo?: {
    totalStakeAmount: number;
    totalRewardAmount: number;
    totalFreezeAmount: number;
    totalWithdrawable: number;
  };
  walletOptions?: WalletContextState;
  setWalletOptions: (options: WalletContextState) => void;
  setPublicKey: (publicKey: PublicKey | null | undefined) => void;
  refreshInfo: () => Promise<void>;
  fetchIoBalance: () => Promise<number>;
  fetchStakingInfo: () => Promise<UseStakingStoreProps["stakingInfo"]>;
  fetchMiddlewareUserStakedDevicesRequest: (options: { page?: number; limit?: number }) => Promise<{
    resultCount: number;
    results: UserStakedDevice[];
  }>;
  fetchMiddlewareStakeEligibleDevicesRequest: (options: {
    page?: number;
    limit?: number;
    query?: string;
  }) => Promise<{
    resultCount: number;
    results: MiddlewareStakeEligibleDevice[];
  }>;
  unstakeDevice: (
    device: UserStakedDevice,
    amount: number,
    abortController?: AbortController
  ) => Promise<void>;
  withdrawDevice: (device: UserStakedDevice, abortController?: AbortController) => Promise<void>;
  stakeDevice: (
    device: UserStakedDevice,
    amount: number,
    abortController?: AbortController
  ) => Promise<void>;
  createCoStakeOffer: (
    device: UserStakedDevice,
    costakeContribution: number,
    sharedBlockRewardsPercent: number
  ) => Promise<void>;
  getStakedTotalForDevices: (devices: UserStakedDevice[]) => Promise<StakedTotalResult>;
  getCoStakedTotalForDevices: (devices: CoStakableDevice[]) => Promise<CoStakedTotalResult>;
  getStakedTotalForCoStakedDevices: (devices: UserStakedDevice[]) => Promise<StakedTotalResult>;
  acceptCoStakeOffer: (device: CoStakableDevice) => Promise<void>;
  cancelCoStakeOffer: (device: UserStakedDevice) => Promise<void>;
  unstakeCoStaker: (device: UserStakedDevice) => Promise<void>;
  withdrawCoStaker: (device: UserStakedDevice) => Promise<void>;
  fetchUserStakedDevicesRequest: (
    option: UserStakedDevicesRequestOptions
  ) => Promise<UserStakedDeviceResult>;
  fetchStakingSearch: (
    option: UserStakedDevicesRequestOptions
  ) => Promise<UserStakedDeviceResult["results"]>;
  trackEvent: (eventName: AnalyticsEventType, options?: Record<string, unknown>) => void;
  trackProcessError: (
    e: unknown,
    events: {
      aborted: AnalyticsEventType;
      cancelled: AnalyticsEventType;
      transaction: AnalyticsEventType;
      middleware: AnalyticsEventType;
      capacity: AnalyticsEventType;
    },
    options?: Record<string, unknown>
  ) => void;
  hardwareList?: string[];
  fetchStakingOffersRequest: (options: { page?: number; limit?: number }) => Promise<{
    resultCount: number;
    results: StakableDeviceOffer[];
  }>;
  fetchUserStakingTransactions: (options: {
    page?: number;
    limit?: number;
    type?: string;
  }) => Promise<{
    resultCount: number;
    results: UserStakingTransactionsType[];
  }>;
  fetchUserStakingRewards: (options: {
    fromDate?: string;
    toDate?: string;
  }) => Promise<UserStakingRewardsType>;
};

let timeout: number;

// const connection = new anchor.web3.Connection(SOLANA_HOST);
const connection = new web3.Connection(SOLANA_HOST, { commitment: "confirmed" });

const fetchStakingOffersRequest = async (
  options: StakableDeviceOffersRequestOptions
): Promise<StakableDeviceOffersResult> => {
  const { page = 1, limit = DEFAULT_PAGINATION_LIMIT } = options;
  const response = await executeAPIRequest<{
    data: PaginatedResponse<{
      statuses: string[];
      devices: UserStakedDeviceResponse[];
      page: number;
      page_size: number;
    }>;
    status: string;
  }>({
    method: "get",
    url: `/io-worker/devices/staking/offers?${stringify({
      page,
      page_size: limit
    })}`
  });

  const { data } = response;

  if (!data || !data.devices) {
    return {
      resultCount: 0,
      results: []
    };
  }

  let results = data.devices.slice(0, 10).map((item) => {
    const { status } = item;
    return normaliseUserStakedDevice({
      ...item,
      status: status ? status : "all"
    });
  });

  if (status && status !== "all") {
    const statues = status.split(",");

    results = results.filter((result) => {
      return statues.includes(result.status);
    });
  }

  if (env.STAKING_SHOW_ONLY_ONLINE_DEVICES) {
    results = results.filter((result) => {
      if (result.amountFrozen > 0 || result.amountStaked > 0 || result.amountWithdrawable > 0) {
        return true;
      }
      return !result.deviceStatus || [DeviceStatusType.UP].includes(result.deviceStatus);
    });
  }

  return {
    results: [] as StakableDeviceOffer[],
    resultCount: results.length
  };
};

export const useStakingStore = create<UseStakingStoreProps>((set, get) => ({
  isReady: false,
  isConnected: false,
  isConnecting: false,
  fetchStakingOffersRequest,
  trackEvent: (eventName: AnalyticsEventType, defaultOptions?: Record<string, unknown>) => {
    const { publicKey, adapterName } = get();
    const device = defaultOptions?.device as UserStakedDevice | undefined;

    const options = {
      ...(defaultOptions ? defaultOptions : {}),
      ...(publicKey
        ? {
            walletAddress: publicKey.toBase58()
          }
        : {}),
      ...(device
        ? {
            deviceId: device.id
          }
        : {}),
      adapterName
    };

    const { userId } = useUserStore.getState();

    logEvent(eventName, {
      userId,
      ...options
    });

    analytics.trackEvent(eventName, options);
  },
  trackProcessError: (e, errorTypes, defaultOptions) => {
    const { trackEvent } = get();

    const message = (e as { message: string })?.message;
    const options = {
      ...defaultOptions,
      message
    };

    if (isCapacityReachedError(e)) {
      trackEvent(errorTypes.capacity, options);
      return;
    }

    if (["Transaction cancelled", "Transaction rejected"].includes(message)) {
      trackEvent(errorTypes.cancelled, options);
      return;
    }

    if (message === "aborted") {
      trackEvent(errorTypes.aborted, options);
      return;
    }

    if (e instanceof AxiosError) {
      trackEvent(errorTypes.middleware, options);
      return;
    }

    trackEvent(errorTypes.transaction, options);
  },
  setWalletOptions: (options) => {
    const { setPublicKey, trackEvent } = get();
    const { connected, connecting, disconnecting, publicKey } = options;

    if (connected && !connecting && !disconnecting && publicKey) {
      trackEvent(AnalyticsEventType.STAKING_CONNECT_WALLET, {
        walletAddress: publicKey.toBase58()
      });
    }

    set({
      walletOptions: options,
      isConnected: connected,
      isConnecting: connecting,
      ...(disconnecting
        ? {
            isReady: false,
            publicKey: undefined,
            balance: undefined,
            stakingInfo: undefined,
            unstakingDevice: undefined,
            withdrawingDevice: undefined,
            costakingDevice: undefined
          }
        : {}),
      publicKey: undefined,
      adapterName: options.wallet?.adapter.name
    });

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = window.setTimeout(() => {
      if (connecting || disconnecting) {
        return;
      }

      set({ isReady: true });
      setPublicKey(publicKey);
    }, 200);
  },
  setPublicKey: (publicKey) => {
    const { refreshInfo } = get();

    if (!publicKey) {
      set({ balance: undefined, isConnected: false });
      return;
    }

    set({ isConnected: true, publicKey, balance: undefined, stakingInfo: undefined });
    refreshInfo();
  },
  refreshInfo: async () => {
    const { fetchIoBalance, fetchStakingInfo } = get();

    fetchIoBalance();
    fetchStakingInfo();
  },
  fetchIoBalance: async () => {
    try {
      const { publicKey } = get();
      if (!publicKey) {
        throw new Error("no wallet connected");
      }

      const balance = await getUserTokenBalance(publicKey, IO_TOKEN_MINT);
      const parsedBalance = parseFloat(`${balance}`);

      set({ balance: parsedBalance });

      return parsedBalance;
    } catch (e) {
      console.log("fetchIoBalance error", e);
      set({ balance: undefined });

      throw toFriendlyPublicError(e);
    }
  },
  fetchStakingInfo: async () => {
    try {
      const { publicKey } = get();
      if (!publicKey) {
        throw new Error("no wallet connected");
      }

      // const data = (await getUserStakeAggregateData(publicKey.toBase58())) as NonNullable<
      //   UseStakingStoreProps["stakingInfo"]
      // >;
      const data = (await proxyToMiddleware(
        ProxyToMiddlewareEndpoint.INFO,
        {
          user_account: publicKey.toBase58()
        },
        "get"
      )) as NonNullable<UseStakingStoreProps["stakingInfo"]>;

      const { totalFreezeAmount, totalRewardAmount, totalStakeAmount, totalWithdrawable } = data;
      const stakingInfo = {
        totalFreezeAmount: parseFloat(convertLamportBack(totalFreezeAmount)),
        totalRewardAmount: parseFloat(convertLamportBack(totalRewardAmount)),
        totalStakeAmount: parseFloat(convertLamportBack(totalStakeAmount)),
        totalWithdrawable: parseFloat(convertLamportBack(totalWithdrawable))
      };

      set({ stakingInfo });

      return stakingInfo;
    } catch (e) {
      console.log("fetchStakeAggregateData error", e);
      set({ stakingInfo: undefined });

      throw toFriendlyPublicError(e);
    }
  },
  fetchMiddlewareUserStakedDevicesRequest: async ({ page = 1 }) => {
    const { publicKey } = get();

    if (!publicKey) {
      throw new Error("no wallet connected");
    }

    const response = (await getStakeInfoByUserAccount(publicKey.toBase58(), page)) as {
      items: MiddlewareUserStakedDeviceResponse[];
      total: number;
      page: string;
      limit: number;
    };

    return {
      results: response.items.map((item) => {
        return normaliseMiddlewareUserStakedDevice(item);
      }),
      resultCount: response.total
    };
  },
  fetchMiddlewareStakeEligibleDevicesRequest: async ({ page = 1, query = "" }) => {
    const response = (await getAllStakeInfo(query, page)) as {
      items: MiddlewareStakeEligibleDeviceResponse[];
      total: number;
      page: string;
      limit: number;
    };

    return {
      results: response.items.map((item) => {
        return normaliseStakeEligibleDevice(item);
      }),
      resultCount: response.total
    };
  },
  stakeDevice: async (device, amount, abortController) => {
    const { publicKey, walletOptions, refreshInfo, trackEvent, trackProcessError } = get();

    try {
      set({ isStaking: true });

      if (!walletOptions || !publicKey) {
        throw new Error("no wallet connected");
      }

      trackEvent(AnalyticsEventType.STAKING_STAKE_PROCESS_STARTED);

      const { signTransaction } = walletOptions;
      const minimum_amount = Number(200);
      const program = getProgramInstanceV2(connection, walletOptions as AnchorWallet);
      const deviceId = device.deviceId;
      const minimumStakeAmount = device.minimumStaked;
      const ioTokenMint = new PublicKey(IO_TOKEN_MINT);
      const nodeAccount = findNodeAccount(deviceId);
      console.log("🚀 ~ handleStaking ~ nodeAccount", nodeAccount.toBase58());
      //// tslint:disable-next-line ts
      const nodeAccountInfo = await program.account.nodeAccount.fetchNullable(nodeAccount);
      const isNodeExist = !!nodeAccountInfo;
      console.log("🚀 ~ handleStaking ~ isNodeExist", isNodeExist);

      let stakeAccount = findStakeAccount(nodeAccount, publicKey);

      const amount_number = Number(amount);
      if (isNodeExist) {
        stakeAccount = nodeAccountInfo.nodeStakeAccount as PublicKey;
        //console.log("🚀 ~ handleStaking ~ stakeAccount", stakeAccount.toBase58());
      }
      const stakeAccountAta = getAssociatedTokenAddressSync(ioTokenMint, stakeAccount, true);
      const signAta = getAssociatedTokenAddressSync(ioTokenMint, publicKey, true);
      const [governorAccount] = PublicKey.findProgramAddressSync(
        [Buffer.from("governor")],
        program.programId
      );
      let tokenBalance;
      try {
        tokenBalance = await program.provider.connection.getTokenAccountBalance(signAta);
      } catch (e) {
        throw new Error("Connected wallet does not have any balance of IO coins");
      }
      const node_uuid = uuidToOnchainId(deviceId);
      let instruction;
      let create_instruction;
      //console.log("TOKEN_PROGRAM_ID", TOKEN_PROGRAM_ID.toBase58());
      //console.log("🚀 ~ handleStaking ~ SUPER_ADMIN_PUBKEY_ACTIVE", SUPER_ADMIN_PUBKEY_ACTIVE);
      if (isNodeExist) {
        instruction = await program.methods
          .addStake(new BN(amount_number * Math.pow(10, 8)))
          .accountsStrict({
            mint: ioTokenMint,
            systemProgram: SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            nodeAccount,
            signer: publicKey,
            signerAta: signAta,
            stakeAccount,
            stakeAccountAta
          })
          .instruction();
      } else {
        if (amount_number < minimum_amount) {
          throw new Error(
            `Provided stake amount less than required minimum amount: ${minimum_amount}`
          );
        }
        // = minimum_amount;
        const createNode = await program.methods
          .createNode(node_uuid)
          .accountsStrict({
            signer: publicKey,
            mint: ioTokenMint,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            governorAccount,
            governorActiveSigner: new PublicKey(SUPER_ADMIN_PUBKEY_ACTIVE),
            nodeAccount: nodeAccount,
            stakeAccount,
            systemProgram: SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
            stakeAccountAta: stakeAccountAta,
            signerAta: signAta
          })
          .instruction();
        const updateMinimumStake = await program.methods
          .updateMinStake(new BN(minimumStakeAmount * Math.pow(10, 8)))
          .accountsStrict({
            signer: new PublicKey(SUPER_ADMIN_PUBKEY_ACTIVE),
            nodeAccount: nodeAccount,
            governorAccount,
            systemProgram: SystemProgram.programId
          })
          .instruction();
        if (amount_number - minimum_amount > 0) {
          const rest_amount = amount_number - minimum_amount;
          const addAmount = await program.methods
            .addStake(new BN(rest_amount * Math.pow(10, 8)))
            .accountsStrict({
              mint: ioTokenMint,
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
              associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
              nodeAccount,
              signer: publicKey,
              signerAta: signAta,
              stakeAccount,
              stakeAccountAta
            })
            .instruction();

          create_instruction = new Transaction()
            .add(createNode)
            .add(updateMinimumStake)
            .add(addAmount);
        } else {
          create_instruction = new Transaction().add(createNode).add(updateMinimumStake);
        }
      }
      //const data_bn_instructions = Buffer.from(instruction.data).toString("hex");
      //console.log("🚀 ~ StakingService ~ staking ~ data_bn_instructions:", data_bn_instructions);

      errorIfAborted(abortController);

      let transaction;
      if (!instruction) {
        transaction = create_instruction as Transaction;
      } else {
        transaction = toTransactionWithPriority(new Transaction()).add(instruction);
      }

      const { blockhash } = await connection.getLatestBlockhash("confirmed");
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      if (!isNodeExist) {
        if (tokenBalance?.value?.uiAmount && tokenBalance.value.uiAmount < minimum_amount) {
          throw new Error(`Insufficient balance for minimum staking amount ${minimum_amount}`);
        }
      }
      const signedTransaction = await signTransaction!(transaction);
      const partiallySignedTransaction = signedTransaction
        .serialize({
          requireAllSignatures: false,
          verifySignatures: false
        })
        .toString("base64");

      await proxyToMiddleware(
        ProxyToMiddlewareEndpoint.STAKE,
        {
          device_id: deviceId,
          user_account: publicKey.toBase58(),
          stake_amount: convertToLamport(amount_number.toString()),
          serialize_transaction: partiallySignedTransaction
        },
        "post"
      );

      refreshInfo();

      eventDispatcher.dispatch({
        name: StakeEventType.STAKED_DEVICE_UPDATED,
        data: {
          device
        }
      });

      trackEvent(AnalyticsEventType.STAKING_STAKE_PROCESS_COMPLETED);
    } catch (e) {
      console.log(e);
      trackProcessError(e, {
        transaction: AnalyticsEventType.STAKING_STAKE_PROCESS_TRANSACTION_FAILED,
        middleware: AnalyticsEventType.STAKING_STAKE_PROCESS_MIDDLEWARE_FAILED,
        cancelled: AnalyticsEventType.STAKING_STAKE_PROCESS_CANCELLED,
        aborted: AnalyticsEventType.STAKING_STAKE_PROCESS_TIMED_OUT,
        capacity: AnalyticsEventType.STAKING_STAKE_PROCESS_CAPACITY_REACHED
      });
      throw toFriendlyPublicError(e);
    } finally {
      set({ isStaking: false });
    }
  },
  getStakedTotalForDevices: async (devices: UserStakedDevice[]): Promise<StakedTotalResult> => {
    const { trackProcessError } = get();
    try {
      const program = getProgramInstanceInternal(connection);
      const ioTokenMint = new PublicKey(IO_TOKEN_MINT);

      if (!devices || devices.length === 0) {
        return {
          totalStakedAmount: 0,
          totalInCooldown: 0,
          totalWithdrawable: 0,
          updatedDevices: []
        } as StakedTotalResult;
      }

      const uniqueDevices = Array.from(new Set(devices.map((device) => device.deviceId))).map(
        (deviceId) => devices.find((device) => device.deviceId === deviceId)
      );

      let totalStakedAmount = 0;
      let totalInCooldown = 0;
      let totalWithdrawable = 0;
      const currentTime = new Date().getTime() / 1000;
      const currentSlot = await connection.getSlot("confirmed");
      const currentBlockTime = await connection.getBlockTime(currentSlot);

      const updatedDevices = await Promise.allSettled(
        uniqueDevices.map(async (device) => {
          let minimum_stake_required = 200;
          let stakeBalanceCostaker = 0;
          let net_amount_staked = 0;
          let overflow_amount = 0;
          let cooldownTimeCostaker;
          let noticeTimeCostaker;
          let freeze_end_time_date: Date | undefined;

          try {
            if (!device) {
              console.log("Device is undefined");
              return device;
            }

            minimum_stake_required = device.minimumStaked;
            const nodeAccount = findNodeAccount(device.deviceId);

            const nodeAccountInfo = await program.account.nodeAccount.fetchNullable(nodeAccount);
            if (!nodeAccountInfo) {
              //console.log(`Node account not found for device ${device.deviceId}`);
              return {
                ...device,
                amountStaked: 0,
                amountFrozen: 0,
                amountWithdrawable: 0,
                ownerStakeAmount: 0,
                overflowAmount: 0,
                coStakeStatus: "closed",
                canUnstakeCustomAmount: false
              };
            }
            //const co_staking_offer = nodeAccountInfo.coStakingEnabled;
            const stakeAccount = nodeAccountInfo.nodeStakeAccount as PublicKey;
            const stakeAccountAta = getAssociatedTokenAddressSync(ioTokenMint, stakeAccount, true);
            const stakeBalance =
              (await program.provider.connection.getTokenAccountBalance(stakeAccountAta)).value
                .uiAmount || 0;
            const stakeAccountInfo = await program.account.stakeAccount.fetchNullable(stakeAccount);
            let cooldownTimeStamp;
            let noticeTimeStamp;

            if (!stakeAccountInfo) {
              return {
                ...device,
                amountStaked: 0,
                amountFrozen: 0,
                amountWithdrawable: 0,
                ownerStakeAmount: 0,
                overflowAmount: 0,
                coStakeStatus: "closed",
                canUnstakeCustomAmount: false
              };
            }

            const cooldownTime = stakeAccountInfo.cooldownTime;

            let costakeAccount = null;
            let costakerstakeAccountAta = null;
            costakeAccount = nodeAccountInfo?.costakerStakeAccount
              ? nodeAccountInfo.costakerStakeAccount
              : null;

            if (costakeAccount) {
              costakerstakeAccountAta = getAssociatedTokenAddressSync(
                ioTokenMint,
                costakeAccount,
                true
              );
              stakeBalanceCostaker =
                (await program.provider.connection.getTokenAccountBalance(costakerstakeAccountAta))
                  .value.uiAmount || 0;
              const costakeAccountInfo =
                await program.account.stakeAccount.fetchNullable(costakeAccount);
              cooldownTimeCostaker = costakeAccountInfo ? costakeAccountInfo.cooldownTime : null;
              noticeTimeCostaker = costakeAccountInfo ? costakeAccountInfo.noticeTime : null;

              if (cooldownTimeCostaker && noticeTimeCostaker) {
                const noticeTimeSlots = Number(noticeTimeCostaker?.toString());

                if (currentBlockTime !== null) {
                  const slotDifference = noticeTimeSlots - currentSlot;
                  const averageSlotTimeSeconds = 0.4;
                  const estimatedTimeDifference = slotDifference * averageSlotTimeSeconds;
                  noticeTimeStamp = currentBlockTime + estimatedTimeDifference;
                }

                if (noticeTimeStamp && noticeTimeStamp < currentTime) {
                  console.log("notice period passed");
                  stakeBalanceCostaker = 0;
                }
                if (noticeTimeStamp && noticeTimeStamp > currentTime) {
                  console.log("notice period active");
                }
              }
            }
            //console.log("co_stake_amount", stakeBalanceCostaker);

            if (cooldownTime) {
              const cooldownTimeSlots = Number(cooldownTime?.toString());
              if (currentBlockTime !== null) {
                const slotDifference = cooldownTimeSlots - currentSlot;
                const averageSlotTimeSeconds = 0.4;
                const estimatedTimeDifference = slotDifference * averageSlotTimeSeconds;
                cooldownTimeStamp = currentBlockTime + estimatedTimeDifference;
                console.log("Co-stake Account Info Cooldown TimeStamp", cooldownTimeStamp);
              }
              freeze_end_time_date = cooldownTimeStamp
                ? new Date(cooldownTimeStamp * 1000)
                : undefined;
            }

            if (!cooldownTime) {
              totalStakedAmount += stakeBalance;

              net_amount_staked = stakeBalance + stakeBalanceCostaker;

              if (net_amount_staked >= minimum_stake_required) {
                if (costakeAccount) {
                  device.status = UserStakedDeviceStatusType.SUFFICIENT;
                  device.type = "staked";
                  device.coStakeStatus = "staked";
                } else {
                  device.status = UserStakedDeviceStatusType.SUFFICIENT;
                  device.type = "staked";
                  device.coStakeStatus = "open";
                }
              } else {
                device.status = UserStakedDeviceStatusType.INSUFFICIENT;
                device.type = "staked";
                device.coStakeStatus = "open_insufficient";
              }

              overflow_amount = Math.max(0, net_amount_staked - minimum_stake_required) ?? 0;

              totalWithdrawable += overflow_amount;

              return {
                ...device,
                amountStaked: net_amount_staked,
                overflowAmount: overflow_amount,
                amountFrozen: 0,
                amountWithdrawable: overflow_amount,
                ownerStakeAmount: stakeBalance,
                coStakeAmount: stakeBalanceCostaker,
                coStakerAmount: stakeBalanceCostaker,
                netAmountStaked: net_amount_staked,
                freezeEndTime: undefined
              };
            } else if (cooldownTimeStamp && cooldownTimeStamp > currentTime) {
              totalInCooldown += stakeBalance;

              return {
                ...device,
                amountStaked: 0,
                overflowAmount: 0,
                amountFrozen: stakeBalance,
                amountWithdrawable: 0,
                ownerStakeAmount: 0,
                coStakeAmount: 0,
                coStakerAmount: 0,
                netAmountStaked: 0,
                coStakeStatus: "cooldown",
                freezeEndTime: freeze_end_time_date
              };
            } else {
              totalWithdrawable += stakeBalance;

              return {
                ...device,
                amountStaked: 0,
                overflowAmount: 0,
                amountFrozen: 0,
                amountWithdrawable: stakeBalance,
                ownerStakeAmount: 0,
                coStakeAmount: 0,
                coStakerAmount: 0,
                netAmountStaked: 0,
                type: "staked",
                coStakeStatus: "cooldown",
                freezeEndTime: freeze_end_time_date
              };
            }
          } catch (error) {
            console.error(`Error processing device`, error);
            return device;
          }
        })
      );

      const updatedDevicesFiltered = updatedDevices
        .map((result) => (result.status === "fulfilled" ? result.value : null))
        .filter((device) => device !== null);

      console.log("Total Active Staked in Solo", totalStakedAmount);
      console.log("Total In Cooldown in Solo", totalInCooldown);
      console.log("Total Withdrawable in Solo", totalWithdrawable);
      //console.log("devices updated", updatedDevicesFiltered);
      return {
        totalStakedAmount,
        totalInCooldown,
        totalWithdrawable,
        updatedDevices: updatedDevicesFiltered
      } as StakedTotalResult;
    } catch (e) {
      console.log(e);
      trackProcessError(
        e,
        {
          transaction: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_TRANSACTION_FAILED,
          middleware: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_MIDDLEWARE_FAILED,
          cancelled: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_CANCELLED,
          aborted: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_TIMED_OUT,
          capacity: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_CAPACITY_REACHED
        },
        {
          devices
        }
      );
      throw toFriendlyPublicError(e);
    }
  },
  getCoStakedTotalForDevices: async (devices: CoStakableDevice[]): Promise<CoStakedTotalResult> => {
    const { trackProcessError } = get();

    try {
      const program = getProgramInstanceInternal(connection);
      const ioTokenMint = new PublicKey(IO_TOKEN_MINT);
      //console.log("devices", devices);

      const uniqueDevices = Array.from(new Set(devices.map((device) => device.deviceId))).map(
        (deviceId) => devices.find((device) => device.deviceId === deviceId)
      );
      //console.log("uniqueDevices", uniqueDevices);

      // TODO : filter unique device_id with max id
      // const uniqueDevicesMap = new Map<string, UserStakedDevice>();
      // devices.forEach((device) => {
      //   const existingDevice = uniqueDevicesMap.get(device.deviceId);
      //   if (!existingDevice || device.id > existingDevice.id) {
      //     uniqueDevicesMap.set(device.deviceId, device);
      //   }
      // });
      // const uniqueDevices = Array.from(uniqueDevicesMap.values());
      // console.log("uniqueDevices", uniqueDevices);

      let totalStakedAmount = 0;
      let totalInCooldown = 0;
      let totalWithdrawable = 0;
      const currentTime = new Date().getTime() / 1000;
      const currentSlot = await connection.getSlot("confirmed");
      const currentBlockTime = await connection.getBlockTime(currentSlot);
      const averageSlotTimeSeconds = 0.4;

      const updatedDevices = await Promise.allSettled(
        uniqueDevices.map(async (device) => {
          let stake_status;
          let freeze_end_time = "null";
          //let notice_end_time = "null";
          try {
            if (!device) {
              console.log("Device is undefined");
              return device;
            }

            const nodeAccount = findNodeAccount(device.deviceId);
            const nodeAccountInfo = await program.account.nodeAccount.fetchNullable(nodeAccount);
            if (!nodeAccountInfo) {
              console.log(`Node account not found for device ${device.deviceId}`);
              return {
                ...device
              };
            }
            let costakeAccount = null;
            let costakerstakeAccountAta = null;
            if (device.coStakerAccount != null || "") {
              const publickey = new PublicKey(device.coStakerAccount);
              costakeAccount = findStakeAccount(nodeAccount, publickey);
            }

            console.log("🚀 ~ handleStaking ~ costakeAccount", costakeAccount);
            let stakeBalanceCostaker = 0;
            if (!costakeAccount) {
              return {
                ...device,
                coStakeAmount: 0,
                stakeAmount: 0,
                amountFrozen: 0,
                amountWithdrawable: 0,
                status: "closed"
              };
            }

            costakerstakeAccountAta = getAssociatedTokenAddressSync(
              ioTokenMint,
              costakeAccount,
              true
            );

            const costakeAccountInfo =
              await program.account.stakeAccount.fetchNullable(costakeAccount);

            if (costakeAccountInfo) {
              stakeBalanceCostaker =
                (await program.provider.connection.getTokenAccountBalance(costakerstakeAccountAta))
                  .value.uiAmount || 0;
              console.log("🚀 ~ handleStaking ~ stakeBalanceCostaker", stakeBalanceCostaker);
            }

            let cooldownTimeStamp;
            let noticeTimeStamp;
            if (!costakeAccountInfo) {
              return {
                ...device,
                coStakeAmount: 0,
                stakeAmount: 0,
                amountFrozen: 0,
                amountWithdrawable: 0,
                status: "closed"
              };
            }
            //console.log("🚀 ~ handleStaking ~ costakeAccountInfo", costakeAccountInfo);
            const cooldownTime = costakeAccountInfo.cooldownTime;

            if (cooldownTime) {
              const cooldownTimeSlots = Number(cooldownTime?.toString());
              if (currentBlockTime !== null) {
                const slotDifference = cooldownTimeSlots - currentSlot;
                const estimatedTimeDifference = slotDifference * averageSlotTimeSeconds;
                cooldownTimeStamp = currentBlockTime + estimatedTimeDifference;
              }

              const freeze_end_time_date = cooldownTimeStamp
                ? new Date(cooldownTimeStamp * 1000)
                : "null";
              freeze_end_time = freeze_end_time_date.toLocaleString();
            }

            const noticeTime = costakeAccountInfo.noticeTime;
            if (noticeTime) {
              const noticeTimeSlots = Number(noticeTime?.toString());

              if (currentBlockTime !== null) {
                const slotDifference = noticeTimeSlots - currentSlot;
                const estimatedTimeDifference = slotDifference * averageSlotTimeSeconds;
                noticeTimeStamp = currentBlockTime + estimatedTimeDifference;
              }
              // const notice_end_time_date = noticeTimeStamp
              //   ? new Date(noticeTimeStamp * 1000)
              //   : "null";
              //notice_end_time = notice_end_time_date.toLocaleString();
            }

            if (!cooldownTime) {
              totalStakedAmount += stakeBalanceCostaker;
              stake_status = "staked"; //"Active Co-staking"
              return {
                ...device,
                stakeAmount: stakeBalanceCostaker,
                coStakeAmount: stakeBalanceCostaker,
                amountFrozen: 0,
                amountWithdrawable: 0,
                status: stake_status,
                freezeEndTime: freeze_end_time
                //notice_end_time
              };
            } else if (
              (cooldownTimeStamp && cooldownTimeStamp > currentTime) ||
              (noticeTimeStamp && noticeTimeStamp > currentTime)
            ) {
              totalInCooldown += stakeBalanceCostaker;
              stake_status = "costaker-unstaked"; // "In Cooldown"
              if (noticeTimeStamp && noticeTimeStamp > currentTime) {
                stake_status = "costaker-waitperiod"; // "In Wait Period"
              }
              return {
                ...device,
                stakeAmount: 0,
                amountFrozen: stakeBalanceCostaker,
                amountWithdrawable: 0,
                status: stake_status,
                freezeEndTime: freeze_end_time
                //notice_end_time
              };
            } else {
              totalWithdrawable += stakeBalanceCostaker;
              stake_status = "costaker-unstaked"; // "Withdrawable"
              return {
                ...device,
                stakeAmount: 0,
                amountFrozen: 0,
                amountWithdrawable: stakeBalanceCostaker,
                status: stake_status,
                freezeEndTime: freeze_end_time
                //notice_end_time
              };
            }
          } catch (error) {
            console.error(`Error processing device`, error);
            return device;
          }
        })
      );

      const updatedDevicesFiltered = updatedDevices
        .map((result) => (result.status === "fulfilled" ? result.value : null))
        .filter((device) => device !== null);

      console.log("Total Co-Staking Contribution staked", totalStakedAmount);
      console.log("Total Co-Staking Contribution in Cooldown", totalInCooldown);
      console.log("Total Co-Staking Contribution Withdrawable", totalWithdrawable);
      //console.log("updatedDevicesFiltered", updatedDevicesFiltered);
      return {
        totalStakedAmount,
        totalInCooldown,
        totalWithdrawable,
        updatedDevices: updatedDevicesFiltered // DO NOT USE updatedDevices until upgrade to new idl
      } as CoStakedTotalResult;

      //refreshInfo();
      // trackEvent(AnalyticsEventType.STAKING_GETTING_TOTALS_FROM_CHAIN_FOR_DEVICES, {
      //   devices
      // });
    } catch (e) {
      console.log(e);
      trackProcessError(
        e,
        {
          transaction: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_TRANSACTION_FAILED,
          middleware: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_MIDDLEWARE_FAILED,
          cancelled: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_CANCELLED,
          aborted: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_TIMED_OUT,
          capacity: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_CAPACITY_REACHED
        },
        {
          devices
        }
      );
      throw toFriendlyPublicError(e);
    }
  },
  getStakedTotalForCoStakedDevices: async (
    devices: UserStakedDevice[]
  ): Promise<StakedTotalResult> => {
    // this func not usable it is just mock for future
    const { publicKey, walletOptions } = get();
    if (!walletOptions || !publicKey) {
      throw new Error("no wallet connected");
    }

    console.log(devices);
    const totalStakedAmount = 0;
    const totalInCooldown = 0;
    const totalWithdrawable = 0;
    const updatedDevices = {};

    return {
      totalStakedAmount,
      totalInCooldown,
      totalWithdrawable,
      updatedDevices: updatedDevices
    } as StakedTotalResult;
  },
  unstakeDevice: async (device, amount, abortController) => {
    const { publicKey, walletOptions, refreshInfo, trackEvent, trackProcessError } = get();

    try {
      if (!walletOptions || !publicKey) {
        throw new Error("no wallet connected");
      }
      set({ unstakingDevice: device });

      trackEvent(AnalyticsEventType.STAKING_UNSTAKE_PROCESS_STARTED, {
        device
      });

      const { signTransaction } = walletOptions;

      const program = getProgramInstanceV2(connection, walletOptions as AnchorWallet);
      const [governorAccount] = PublicKey.findProgramAddressSync(
        [Buffer.from("governor")],
        program.programId
      );
      const ioTokenMint = new PublicKey(IO_TOKEN_MINT);
      const nodeAccount = findNodeAccount(device.deviceId);
      const nodeAccountInfo = await program.account.nodeAccount.fetchNullable(nodeAccount);
      if (!nodeAccountInfo) {
        throw new Error("Node account not found");
      }
      const nodeAccountAthority = nodeAccountInfo.authority.toString();
      if (nodeAccountAthority !== publicKey.toString()) {
        throw new Error(`This device was staked with a wallet address: ${nodeAccountAthority}`);
      }
      const stakeAccount = nodeAccountInfo.nodeStakeAccount as PublicKey;
      const stakeAccountAta = getAssociatedTokenAddressSync(ioTokenMint, stakeAccount, true);
      const signAta = getAssociatedTokenAddressSync(ioTokenMint, publicKey, true);
      // const stakeBalanceDeviceOwner =
      //   (await program.provider.connection.getTokenAccountBalance(stakeAccountAta)).value
      //     .uiAmount || 0;
      const unstakeAmount = amount;
      let costakeAccount = null;
      let costakerstakeAccountAta = null;
      costakeAccount = nodeAccountInfo?.costakerStakeAccount;
      //let stakeBalanceCostaker = 0;
      if (costakeAccount) {
        costakerstakeAccountAta = getAssociatedTokenAddressSync(ioTokenMint, costakeAccount, true);
        // stakeBalanceCostaker =
        //   (await program.provider.connection.getTokenAccountBalance(costakerstakeAccountAta)).value
        //     .uiAmount || 0;
      }
      // currently will unstake all, need to pass the amount to unstake partially
      // if amount is passed less than the (stakeBalanceDeviceOwner + stakeBalanceCostaker - minimum_stake_amount) it will withdraw the amount immediately
      //const currentAmountOnChain = stakeBalanceDeviceOwner + stakeBalanceCostaker;
      //console.log("currentAmountOnChain", currentAmountOnChain);
      const instruction = await program.methods
        .unstake(new BN(unstakeAmount * Math.pow(10, 8)))
        .accountsStrict({
          mint: ioTokenMint,
          governorAccount,
          governorActiveSigner: new PublicKey(SUPER_ADMIN_PUBKEY_ACTIVE),
          nodeAccount: nodeAccount,
          signer: publicKey,
          signerAta: signAta,
          stakeAccount: stakeAccount,
          stakeAccountAta: stakeAccountAta,
          systemProgram: web3.SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          costakeAccount: costakeAccount,
          costakeAccountAta: costakerstakeAccountAta
        })
        .instruction();
      errorIfAborted(abortController);
      const transaction = toTransactionWithPriority(new Transaction()).add(instruction);

      const { blockhash } = await connection.getLatestBlockhash("confirmed");
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey!;

      const signedTransaction = await signTransaction!(transaction);

      const partiallySignedTransaction = signedTransaction
        .serialize({
          requireAllSignatures: false,
          verifySignatures: false
        })
        .toString("base64");

      await proxyToMiddleware(
        ProxyToMiddlewareEndpoint.UNSTAKE,
        {
          stake_detail_id: device.id,
          device_id: device.deviceId,
          unstake_amount: convertToLamport(unstakeAmount.toString()),
          user_account: publicKey.toBase58(),
          serialize_transaction: partiallySignedTransaction.toString()
        },
        "post"
      );
      //console.log("unstakeDevice response", response);

      refreshInfo();

      eventDispatcher.dispatch({
        name: StakeEventType.STAKED_DEVICE_UPDATED,
        data: {
          device
        }
      });

      trackEvent(AnalyticsEventType.STAKING_UNSTAKE_PROCESS_COMPLETED, {
        device
      });
    } catch (e) {
      console.log(e);
      trackProcessError(
        e,
        {
          transaction: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_TRANSACTION_FAILED,
          middleware: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_MIDDLEWARE_FAILED,
          cancelled: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_CANCELLED,
          aborted: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_TIMED_OUT,
          capacity: AnalyticsEventType.STAKING_UNSTAKE_PROCESS_CAPACITY_REACHED
        },
        {
          device
        }
      );
      throw toFriendlyPublicError(e);
    } finally {
      set({ unstakingDevice: undefined });
    }
  },
  withdrawDevice: async (device, abortController) => {
    const { trackProcessError } = get();
    try {
      const { publicKey, walletOptions, refreshInfo, trackEvent } = get();
      if (!walletOptions || !publicKey) {
        throw new Error("no wallet connected");
      }
      trackEvent(AnalyticsEventType.STAKING_WITHDRAW_PROCESS_STARTED, {
        device
      });
      set({ withdrawingDevice: device });

      const { signTransaction } = walletOptions;
      const ioTokenMint = new PublicKey(IO_TOKEN_MINT);
      const program = getProgramInstanceV2(connection, walletOptions as AnchorWallet);
      const [governorAccount] = PublicKey.findProgramAddressSync(
        [Buffer.from("governor")],
        program.programId
      );
      const nodeAccount = findNodeAccount(device.deviceId);
      const nodeAccountInfo = await program.account.nodeAccount.fetchNullable(nodeAccount);
      if (!nodeAccountInfo) {
        throw new Error("Node account not found");
      }
      const nodeAccountAthority = nodeAccountInfo.authority.toString();
      if (nodeAccountAthority !== publicKey.toString()) {
        throw new Error(`This device was staked with a wallet address: ${nodeAccountAthority}`);
      }
      if (!nodeAccountInfo.nodeStakeAccount) {
        throw new Error("Stake account not found");
      }
      // fixed in contract , in future should be removed
      // if (nodeAccountInfo.coStakingEnabled) {
      //   throw new Error("Need to disable costaking offer first");
      // }
      const stakeAccount = nodeAccountInfo.nodeStakeAccount as PublicKey;
      // added to check noticeTime and cooldown period
      const stakeAccountInfo = await program.account.stakeAccount.fetch(stakeAccount);
      // console.log(
      //   "🚀 ~ handleStaking ~ stakeAccountInfo authority",
      //   stakeAccountInfo.authority.toBase58()
      // );
      const currentSlot = await connection.getSlot("confirmed");
      const currentBlockTime = await connection.getBlockTime(currentSlot);
      const averageSlotTimeSeconds = 0.4;
      const cooldownTime = stakeAccountInfo.cooldownTime;
      //const noticeTime = stakeAccountInfo.noticeTime;
      if (!cooldownTime) {
        throw new Error("Need to unstake first and wait for cooldown period");
      }
      let cooldownTimeStamp;
      if (cooldownTime) {
        const cooldownTimeSlots = Number(cooldownTime?.toString());
        if (currentBlockTime !== null) {
          const slotDifference = cooldownTimeSlots - currentSlot;
          const estimatedTimeDifference = slotDifference * averageSlotTimeSeconds;
          cooldownTimeStamp = currentBlockTime + estimatedTimeDifference;
          //console.log("costakeAccountInfo cooldownTimeStamp", cooldownTimeStamp);
        }
      }

      const currentTime = new Date().getTime();
      if (cooldownTimeStamp && cooldownTimeStamp > currentTime) {
        throw new Error("Cooldown period has not yet passed.");
      }
      const stakeAccountAta = getAssociatedTokenAddressSync(ioTokenMint, stakeAccount, true);
      // const stakeBalanceDeviceOwner =
      //   (await program.provider.connection.getTokenAccountBalance(stakeAccountAta)).value
      //     .uiAmount || 0;
      const signAta = getAssociatedTokenAddressSync(ioTokenMint, publicKey, true);
      //console.log("🚀 ~ handleStaking ~ signAta", signAta.toString());
      let costakeAccount = nodeAccountInfo?.costakerStakeAccount;
      let costakerstakeAccountAta = null;
      let costakersignerAta = null;
      if (costakeAccount) {
        const costakeAccountInfo = await program.account.stakeAccount.fetch(costakeAccount);
        const costakerPubkey = costakeAccountInfo.authority;
        costakerstakeAccountAta = getAssociatedTokenAddressSync(ioTokenMint, costakeAccount, true);
        costakersignerAta = getAssociatedTokenAddressSync(ioTokenMint, costakerPubkey, true);
      } else {
        costakeAccount = null;
      }
      const instruction = await program.methods
        .withdraw()
        .accountsStrict({
          mint: ioTokenMint,
          signerAta: signAta,
          signer: publicKey,
          governorAccount,
          governorActiveSigner: new PublicKey(SUPER_ADMIN_PUBKEY_ACTIVE),
          nodeAccount: nodeAccount,
          stakeAccount: stakeAccount,
          stakeAccountAta: stakeAccountAta,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          costakeAccount: costakeAccount,
          costakeAccountAta: costakerstakeAccountAta,
          costakeAuthorityAta: costakersignerAta
        })
        .instruction();

      errorIfAborted(abortController);

      const transaction = toTransactionWithPriority(new Transaction()).add(instruction);
      //const transaction = new Transaction().add(instruction);
      const { blockhash } = await connection.getLatestBlockhash("confirmed");
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey!;

      const signedTransaction = await signTransaction!(transaction);
      const fullySignedTransaction = signedTransaction
        .serialize({
          requireAllSignatures: false,
          verifySignatures: false
        })
        .toString("base64");

      await proxyToMiddleware(
        ProxyToMiddlewareEndpoint.WITHDRAW,
        {
          stake_detail_id: "",
          device_id: device.deviceId,
          user_account: publicKey.toBase58(),
          serialize_transaction: fullySignedTransaction.toString()
        },
        "post"
      );

      refreshInfo();

      eventDispatcher.dispatch({
        name: StakeEventType.STAKED_DEVICE_UPDATED,
        data: {
          device
        }
      });

      trackEvent(AnalyticsEventType.STAKING_WITHDRAW_PROCESS_COMPLETED, {
        device
      });
    } catch (e) {
      console.error(e);
      trackProcessError(
        e,
        {
          transaction: AnalyticsEventType.STAKING_WITHDRAW_PROCESS_TRANSACTION_FAILED,
          middleware: AnalyticsEventType.STAKING_WITHDRAW_PROCESS_MIDDLEWARE_FAILED,
          cancelled: AnalyticsEventType.STAKING_WITHDRAW_PROCESS_CANCELLED,
          aborted: AnalyticsEventType.STAKING_WITHDRAW_PROCESS_TIMED_OUT,
          capacity: AnalyticsEventType.STAKING_WITHDRAW_PROCESS_CAPACITY_REACHED
        },
        {
          device
        }
      );
      throw toFriendlyPublicError(e);
    } finally {
      set({ withdrawingDevice: undefined });
    }
  },
  fetchUserStakedDevicesRequest: async (
    options: UserStakedDevicesRequestOptions
  ): Promise<UserStakedDeviceResult> => {
    const {
      status = "all",
      page = 1,
      limit = DEFAULT_PAGINATION_LIMIT,
      deviceId = undefined,
      hardwareName
    } = options;

    const userId = useUserStore.getState().userId;
    const response = await executeAPIRequest<{
      data: PaginatedResponse<{
        statuses: string[];
        devices: UserStakedDeviceResponse[];
        hardware: string[];
        page: number;
        page_size: number;
      }>;
      status: string;
    }>({
      method: "get",
      url: `/io-worker/users/${userId}/devices_staking?${stringify({
        // ...(status != "all" ? { status } : {}),
        page,
        device_id: deviceId,
        page_size: limit,
        ...(hardwareName !== "All Devices"
          ? {
              hardware_name: hardwareName
            }
          : {})
      })}`
    });

    const { data } = response;

    if (!data || !data.devices) {
      return {
        resultCount: 0,
        results: [],
        statuses: []
      };
    }

    const hasAllResults = data.devices.length < limit;

    let results = data.devices.map((item) => {
      const { status } = item;
      return normaliseUserStakedDevice({
        ...item,
        status: status ? status : "all"
      });
    });

    if (status && status !== "all") {
      const statues = status.split(",");

      results = results.filter((result) => {
        return statues.includes(result.status);
      });
    }

    if (env.STAKING_SHOW_ONLY_ONLINE_DEVICES) {
      results = results.filter((result) => {
        if (result.amountFrozen > 0 || result.amountStaked > 0 || result.amountWithdrawable > 0) {
          return true;
        }
        return !result.deviceStatus || [DeviceStatusType.UP].includes(result.deviceStatus);
      });
    }

    if (page === 1 && !deviceId) {
      set({ hardwareList: data.hardware });
    }

    return {
      results,
      statuses: data.statuses,
      ...(hasAllResults
        ? {
            resultCount: results.length
          }
        : {})
    };
  },
  fetchStakingSearch: async (...args) => {
    const { fetchUserStakedDevicesRequest } = get();

    const { results } = await fetchUserStakedDevicesRequest(...args);
    return results;
  },
  createCoStakeOffer: async (device, costakeContribution, sharedBlockRewardsPercent) => {
    const { publicKey, walletOptions, refreshInfo } = get();
    set({ isStaking: true });
    try {
      if (!walletOptions || !publicKey) {
        throw new Error("No wallet connected");
      }
      const costakeContributionConverted = 100 - costakeContribution;
      // value in BPS, 10_000 = 100%
      // co_staker_portion = co_staker_portion || 5000; // splitvalue for costaker amount max 50%
      // br_percent = br_percent || 9000; // comission MIN 5% MAX 100%
      const { signTransaction } = walletOptions;
      const program = getProgramInstanceV2(
        connection,
        walletOptions as AnchorWallet
      ) as Program<Iostaking>;
      const deviceId = device.deviceId;
      const ioTokenMint = new PublicKey(IO_TOKEN_MINT);
      const nodeAccount = findNodeAccount(deviceId);
      const nodeAccountInfo = await program.account.nodeAccount.fetchNullable(nodeAccount);
      if (!nodeAccountInfo) {
        throw new Error("Node account not found");
      }
      const nodeAccountAthority = nodeAccountInfo.authority.toString();
      if (nodeAccountAthority !== publicKey.toString()) {
        throw new Error(`This device was staked with a wallet address: ${nodeAccountAthority}`);
      }
      const stakeAccount = nodeAccountInfo.nodeStakeAccount as PublicKey;
      const stakeAccountAta = getAssociatedTokenAddressSync(ioTokenMint, stakeAccount, true);
      //const signAta = getAssociatedTokenAddressSync(ioTokenMint, publicKey, true);
      const enableCoStake = await program.methods
        .enableCostaker(true)
        .accountsStrict({
          signer: publicKey,
          nodeAccount,
          systemProgram: SystemProgram.programId,
          mint: ioTokenMint,
          stakeAccount: stakeAccount,
          stakeAccountAta: stakeAccountAta
        })
        .instruction();

      const updateCommission = await program.methods
        .updateNodeCommission(new BN(sharedBlockRewardsPercent * 100))
        .accountsStrict({
          signer: publicKey,
          nodeAccount,
          systemProgram: SystemProgram.programId
        })
        .instruction();

      const updateSplit = await program.methods
        .updateNodeStakeContributionSplit(new BN(costakeContributionConverted * 100))
        .accountsStrict({
          signer: publicKey,
          nodeAccount,
          systemProgram: SystemProgram.programId
        })
        .instruction();

      const transaction = new Transaction()
        .add(enableCoStake)
        .add(updateCommission)
        .add(updateSplit);

      const { blockhash } = await connection.getLatestBlockhash("confirmed");
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;
      const signedTransaction = await signTransaction!(transaction);

      const fullySignedTransaction = signedTransaction
        .serialize({
          requireAllSignatures: false,
          verifySignatures: false
        })
        .toString("base64");

      await createCoStakingOffer({
        deviceId: device.deviceId,
        //userAccount: publicKey.toString(),
        costakeContribution,
        sharedBlockRewardsPercent,
        transactionHash: fullySignedTransaction.toString()
      });

      refreshInfo();

      eventDispatcher.dispatch({
        name: StakeEventType.STAKED_DEVICE_UPDATED,
        data: {
          device
        }
      });
    } catch (e) {
      console.error(e);
      throw toFriendlyPublicError(e);
    } finally {
      set({ costakingDevice: undefined });
    }
  },
  acceptCoStakeOffer: async (device) => {
    const { publicKey, walletOptions, refreshInfo } = get();
    set({ isStaking: true });
    try {
      if (!walletOptions || !publicKey) {
        throw new Error("No wallet connected");
      }
      const { signTransaction } = walletOptions;
      const userId = useUserStore.getState().userId;
      //const open_offers = await fetchCoStakedDeviceOffers({status: "open"} as any);
      //console.log("open_offers", open_offers);
      const user_offers = await fetchUserThirdPartyCoStakedDevices({ userId });
      //console.log("user_offers", user_offers);
      const coStakeOfferUsedWithWallet = user_offers.results.find(
        (offer) =>
          offer.deviceId === device.deviceId && offer.coStakerAccount === publicKey.toBase58()
      ); //
      //console.log("coStakeOfferUsedWithWallet", coStakeOfferUsedWithWallet);
      if (device.ownerAccount === publicKey.toBase58()) {
        throw new Error("You cannot accept your own offer.");
      }
      if (coStakeOfferUsedWithWallet) {
        throw new Error(
          "The connected wallet had been used for co-staking with this device in the past. Due to technical constraints, please re-try with a different wallet."
        );
      }
      const program = getProgramInstanceV2(connection, walletOptions as AnchorWallet);
      const deviceId = device.deviceId;
      const ioTokenMint = new PublicKey(IO_TOKEN_MINT);
      const nodeAccount = findNodeAccount(deviceId);
      const nodeAccountInfo = await program.account.nodeAccount.fetchNullable(nodeAccount);
      if (!nodeAccountInfo) {
        throw new Error("Node account not found");
      }
      // check if the offer has already been taken
      if (nodeAccountInfo.costakerStakeAccount) {
        throw new Error(
          "Costake offer taken by different account. This offer must be not open for costake"
        );
      }

      const stakeAccount = nodeAccountInfo.nodeStakeAccount as PublicKey;
      const coStakingSplit = nodeAccountInfo.config.coStakingSplit.toNumber() / 100;
      const minStake = nodeAccountInfo.config.minStake.toNumber();
      const costake_amount = (Number(convertLamportBack(minStake)) / 100) * (100 - coStakingSplit);
      const stakeAccountAta = getAssociatedTokenAddressSync(ioTokenMint, stakeAccount, true);
      //const signAta = getAssociatedTokenAddressSync(ioTokenMint, publicKey, true);
      const costakeAccount = findStakeAccount(nodeAccount, publicKey);
      const costakerstakeAccountAta = getAssociatedTokenAddressSync(
        ioTokenMint,
        costakeAccount,
        true
      );
      const costakersignerAta = getAssociatedTokenAddressSync(ioTokenMint, publicKey, true);
      let tokenBalance;
      try {
        tokenBalance = await program.provider.connection.getTokenAccountBalance(costakersignerAta);
      } catch (e) {
        throw new Error("Connected wallet does not have any balance of IO coins");
      }
      if (tokenBalance.value.uiAmount && tokenBalance.value.uiAmount < costake_amount) {
        throw new Error(`Insufficient balance on connected wallet for co-staking.`);
      }
      const [governorAccount] = PublicKey.findProgramAddressSync(
        [Buffer.from("governor")],
        program.programId
      );

      const takeCoStake = await program.methods
        .createCostake()
        .accountsStrict({
          signer: publicKey,
          governorActiveSigner: new PublicKey(SUPER_ADMIN_PUBKEY_ACTIVE),
          governorAccount,
          costakeAccount: costakeAccount,
          nodeAccount: nodeAccount,
          nodeStakeAccountAta: stakeAccountAta,
          mint: ioTokenMint,
          signerAta: costakersignerAta,
          costakeAccountAta: costakerstakeAccountAta,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId
        })
        .instruction();

      const transaction = new Transaction().add(takeCoStake);

      const { blockhash } = await connection.getLatestBlockhash("confirmed");
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;
      const signedTransaction = await signTransaction!(transaction);

      const fullySignedTransaction = signedTransaction
        .serialize({
          requireAllSignatures: false, // true for production
          verifySignatures: false // true for production
        })
        .toString("base64");

      await acceptCoStakingOffer({
        userId,
        deviceId: deviceId as string,
        costakerAccount: publicKey.toBase58(),
        //costakeAmount: Number(convertToLamport(costake_amount.toString())),
        serializedTransaction: fullySignedTransaction.toString()
      });

      refreshInfo();

      eventDispatcher.dispatch({
        name: StakeEventType.STAKED_DEVICE_UPDATED, // COSTAKE_TAKEN
        data: {
          device
        }
      });
    } catch (e) {
      console.log(e);

      throw toFriendlyPublicError(e);
    } finally {
      set({ costakingDevice: undefined });
    }
  },
  cancelCoStakeOffer: async (device) => {
    const { publicKey, walletOptions, refreshInfo } = get();
    set({ isStaking: true });
    try {
      if (!walletOptions || !publicKey) {
        throw new Error("No wallet connected");
      }

      const { signTransaction } = walletOptions;
      const program = getProgramInstanceV2(
        connection,
        walletOptions as AnchorWallet
      ) as Program<Iostaking>;
      const deviceId = device.deviceId;
      const ioTokenMint = new PublicKey(IO_TOKEN_MINT);
      const nodeAccount = findNodeAccount(deviceId);
      const nodeAccountInfo = await program.account.nodeAccount.fetchNullable(nodeAccount);
      console.log("🚀 ~ handleStaking ~ nodeAccountInfo", nodeAccountInfo || "undefined");
      if (!nodeAccountInfo) {
        throw new Error("Node account not found");
      }
      if (!nodeAccountInfo.authority.equals(publicKey)) {
        throw new Error(
          `This offer was created and signning by different wallet ${nodeAccountInfo.authority.toString()}`
        );
      }
      if (BigInt(device.netAmountStaked) < BigInt(device.minimumStaked)) {
        throw new Error(
          "You can't cancel the offer, because the amount staked is less than the minimum stake amount. Please unstake first or add amount."
        );
      }
      const stakeAccount = nodeAccountInfo.nodeStakeAccount as PublicKey;
      const stakeAccountAta = getAssociatedTokenAddressSync(ioTokenMint, stakeAccount, true);
      const disableCostake = await program.methods
        .enableCostaker(false)
        .accountsStrict({
          signer: publicKey,
          nodeAccount,
          systemProgram: SystemProgram.programId,
          mint: ioTokenMint,
          stakeAccount: stakeAccount,
          stakeAccountAta: stakeAccountAta
        })
        .instruction();

      const transaction = new Transaction().add(disableCostake);

      const { blockhash } = await connection.getLatestBlockhash("confirmed");
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;
      const signedTransaction = await signTransaction!(transaction);

      const fullySignedTransaction = signedTransaction
        .serialize({
          requireAllSignatures: false,
          verifySignatures: false
        })
        .toString("base64");

      await cancelCoStakingOffer({
        deviceId,
        transactionHash: fullySignedTransaction.toString()
      });

      refreshInfo();

      eventDispatcher.dispatch({
        name: StakeEventType.STAKED_DEVICE_UPDATED,
        data: {
          device
        }
      });
    } catch (e) {
      console.error(e);
      throw toFriendlyPublicError(e);
    } finally {
      set({ costakingDevice: undefined });
    }
  },
  unstakeCoStaker: async (device) => {
    const { publicKey, walletOptions, refreshInfo } = get();
    set({ isStaking: true });
    try {
      if (!walletOptions || !publicKey) {
        throw new Error("No wallet connected");
      }
      const { signTransaction } = walletOptions;
      const program = getProgramInstanceV2(
        connection,
        walletOptions as AnchorWallet
      ) as Program<Iostaking>;
      const [governorAccount] = PublicKey.findProgramAddressSync(
        [Buffer.from("governor")],
        program.programId
      );
      const deviceId = device.deviceId;
      const ioTokenMint = new PublicKey(IO_TOKEN_MINT);
      const nodeAccount = findNodeAccount(deviceId);
      const nodeAccountInfo = await program.account.nodeAccount.fetchNullable(nodeAccount);
      if (!nodeAccountInfo) {
        throw new Error("Node account not found");
      }
      if (!nodeAccountInfo.costakerStakeAccount) {
        throw new Error("Costaker stake account not found");
      }
      const costakeAccount = nodeAccountInfo.costakerStakeAccount as PublicKey;
      const costakeAccountInfo = await program.account.stakeAccount.fetchNullable(costakeAccount);
      if (!costakeAccountInfo) {
        throw new Error(
          "Costaker stake account not found. Please try to connect different wallet address"
        );
      }
      if (!costakeAccountInfo.authority.equals(publicKey)) {
        throw new Error(
          `This offer was costaked and signning by different wallet ${costakeAccountInfo.authority.toString()}`
        );
      }
      const costakerstakeAccountAta = getAssociatedTokenAddressSync(
        ioTokenMint,
        costakeAccount,
        true
      );
      const costakersignerAta = getAssociatedTokenAddressSync(ioTokenMint, publicKey, true);

      const unstakeCoStaker = await program.methods
        .unstakeCostaker()
        .accountsStrict({
          mint: ioTokenMint,
          nodeAccount: nodeAccount,
          signer: publicKey,
          governorActiveSigner: new PublicKey(SUPER_ADMIN_PUBKEY_ACTIVE),
          governorAccount,
          signerAta: costakersignerAta,
          stakeAccount: costakeAccount,
          stakeAccountAta: costakerstakeAccountAta,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID
        })
        .instruction();

      const transaction = new Transaction().add(unstakeCoStaker);

      const { blockhash } = await connection.getLatestBlockhash("confirmed");
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      const signedTransaction = await signTransaction!(transaction);

      const fullySignedTransaction = signedTransaction
        .serialize({
          requireAllSignatures: false,
          verifySignatures: false
        })
        .toString("base64");

      await proxyToMiddleware(
        ProxyToMiddlewareEndpoint.UNSTAKECOSTAKER,
        {
          device_id: device.deviceId,
          user_account: publicKey.toBase58(),
          serialize_transaction: fullySignedTransaction.toString(),
          unstake_amount: convertToLamport(device.coStakeAmount.toString()),
          offer_id: device.coStakeOfferId
        },
        "post"
      );

      refreshInfo();

      eventDispatcher.dispatch({
        name: StakeEventType.STAKED_DEVICE_UPDATED, // COSTAKER_UNSTAKED
        data: {
          device
        }
      });
    } catch (e) {
      console.error(e);
      throw toFriendlyPublicError(e);
    } finally {
      set({ costakingDevice: undefined }); // ?
    }
  },
  withdrawCoStaker: async (device) => {
    const { publicKey, walletOptions, refreshInfo } = get();
    set({ isStaking: true });
    try {
      if (!walletOptions || !publicKey) {
        throw new Error("No wallet connected");
      }

      const { signTransaction } = walletOptions;
      const program = getProgramInstanceV2(
        connection,
        walletOptions as AnchorWallet
      ) as Program<Iostaking>;
      const [governorAccount] = PublicKey.findProgramAddressSync(
        [Buffer.from("governor")],
        program.programId
      );
      const deviceId = device.deviceId;
      const ioTokenMint = new PublicKey(IO_TOKEN_MINT);
      const nodeAccount = findNodeAccount(deviceId);
      const costakeAccount = findStakeAccount(nodeAccount, publicKey);
      if (!costakeAccount) {
        throw new Error("Co-stake account not found.");
      }
      // TODO: need to check if costaker account is equal to the current wallet address!
      // added to check noticeTime and cooldown period
      const costakeAccountInfo = await program.account.stakeAccount.fetchNullable(costakeAccount);
      if (!costakeAccountInfo) {
        throw new Error(
          "Costaker stake account not found. Please try to connect different wallet address"
        );
      }
      if (!costakeAccountInfo.authority.equals(publicKey)) {
        throw new Error(
          `This offer was costaked and signning by different wallet ${costakeAccountInfo.authority.toString()}`
        );
      }
      const cooldownTime = costakeAccountInfo.cooldownTime;
      //const noticeTime = costakeAccountInfo.noticeTime;
      // if cooldownTime is null then throw error
      if (!cooldownTime) {
        throw new Error("Need to unstake first and wait for cooldown period");
      }
      // TODO: if cooldownTime is not passed then throw error, need to convert slots to time first
      const costakerstakeAccountAta = getAssociatedTokenAddressSync(
        ioTokenMint,
        costakeAccount,
        true
      );
      const costakersignerAta = getAssociatedTokenAddressSync(ioTokenMint, publicKey, true);

      const widrawCoStaker = await program.methods
        .withdrawCostaker()
        .accountsStrict({
          mint: ioTokenMint,
          signerAta: costakersignerAta,
          signer: publicKey,
          governorActiveSigner: new PublicKey(SUPER_ADMIN_PUBKEY_ACTIVE),
          governorAccount,
          costakeAccount: costakeAccount,
          costakeAccountAta: costakerstakeAccountAta,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID
        })
        .instruction();

      const transaction = new Transaction().add(widrawCoStaker);

      const { blockhash } = await connection.getLatestBlockhash("confirmed");
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      const signedTransaction = await signTransaction!(transaction);

      const fullySignedTransaction = signedTransaction
        .serialize({
          requireAllSignatures: false,
          verifySignatures: false
        })
        .toString("base64");

      await proxyToMiddleware(
        ProxyToMiddlewareEndpoint.WITHDRAWCOSTAKER,
        {
          device_id: device.deviceId,
          user_account: publicKey.toBase58(),
          serialize_transaction: fullySignedTransaction.toString(),
          offer_id: device.coStakeOfferId
        },
        "post"
      );

      refreshInfo();

      eventDispatcher.dispatch({
        name: StakeEventType.STAKED_DEVICE_UPDATED, // COSTAKER_WITHDRAWN
        data: {
          device
        }
      });
    } catch (e) {
      console.error(e);
      throw toFriendlyPublicError(e);
    } finally {
      set({ costakingDevice: undefined }); // ?
    }
  },
  fetchUserStakingTransactions: async (options) => {
    const { page = 1, limit = DEFAULT_PAGINATION_LIMIT, type } = options;

    const response = await executeAPIRequest<{
      data: PaginatedResponse<{
        transactions: UserStakingTransactionsResponse[];
        total_results: number;
      }>;
      status: string;
    }>({
      method: "get",
      url: `/io-staking/user/transactions?${stringify({
        page,
        page_size: limit,
        type
      })}`
    });

    const { data } = response;

    return {
      resultCount: data.total_results,
      results: data.transactions.map((transaction) => {
        return normaliseUserStakingTransaction(transaction);
      })
    };
  },
  fetchUserStakingRewards: async (options) => {
    const response = await executeAPIRequest<{ data: UserStakingRewardsResponse; status: string }>({
      method: "get",
      url: `/io-staking/user/rewards?${stringify({
        from_date: options.fromDate,
        to_date: options.toDate
      })}`
    });

    const { data } = response;

    return {
      todaysTotalRewards: data.todays_total_rewards,
      totalUserRewards: data.total_user_rewards,
      rewards: data.rewards
    } as UserStakingRewardsType;
  }
}));

const proxyToMiddleware = async (
  endPoint: ProxyToMiddlewareEndpoint,
  data: Record<string, string | undefined>,
  method: "get" | "delete" | "post"
) => {
  const userId = useUserStore.getState().userId;
  const response = await executeAPIRequest<{
    data: unknown;
  }>({
    method,
    url: `/io-worker/users${MAPPING[endPoint]({
      user_id: userId,
      ...data
    })}${method === "get" ? `?${stringify(data)}` : ``}`,
    options: {
      data,
      publicError: true
    }
  });

  return response?.data;
};

// POST /{user_id}/devices_staking/{device_id}/stake
// POST /{user_id}/devices_staking/{device_id}/unstake
// POST /{user_id}/devices_staking/withdraw
// GET /{user_id}/devices_staking/aggregate

// new endpoint for costake at api_io_db
// POST /staking/{device_id}/offer
// POST /{user_id}/staking/{device_id}/offer/co_stake
// DELETE /staking/{device_id}/offer

// next endpoints need to add at api_io_db
// POST{user_id}/staking/{device_id}/offer/unstake
// POST{user_id}/staking/{device_id}/offer/withdraw
const MAPPING = {
  [ProxyToMiddlewareEndpoint.INFO]: (data) => {
    return `/${data.user_id}/devices_staking/aggregate`;
  },
  [ProxyToMiddlewareEndpoint.STAKE]: (data) => {
    return `/${data.user_id}/devices_staking/${data.device_id}/stake`;
  },
  [ProxyToMiddlewareEndpoint.UNSTAKE]: (data) => {
    return `/devices_staking/${data.device_id}/unstake`;
  },
  [ProxyToMiddlewareEndpoint.WITHDRAW]: (data) => {
    return `/devices_staking/${data.device_id}/withdraw`;
  },
  [ProxyToMiddlewareEndpoint.CREATECOSTAKE]: (data) => {
    return `/staking/${data.device_id}/offer`;
  },
  [ProxyToMiddlewareEndpoint.TAKECOSTAKE]: (data) => {
    return `/${data.user_id}/staking/${data.device_id}/offer/co_stake`;
  },
  [ProxyToMiddlewareEndpoint.CANCELCOSTAKE]: (data) => {
    return `/staking/${data.device_id}/offer`;
  },
  [ProxyToMiddlewareEndpoint.UNSTAKECOSTAKER]: (data) => {
    return `/devices_staking/${data.device_id}/unstake`;
  },
  [ProxyToMiddlewareEndpoint.WITHDRAWCOSTAKER]: (data) => {
    return `/devices_staking/${data.device_id}/withdraw`;
  }
} as Record<ProxyToMiddlewareEndpoint, (data: Record<string, string>) => string>;

const normaliseMiddlewareUserStakedDevice = (response: MiddlewareUserStakedDeviceResponse) => {
  const freezeEndTime =
    typeof response.freeze_end_time === "string"
      ? new Date(Date.parse(response.freeze_end_time))
      : null;

  return {
    id: `${response.id}`,
    stakeInfoId: response.stake_info_id,
    deviceId: response.device_id,
    userId: response.user_id,
    userAccount: response.user_account,
    amountStaked: parseFloat(convertLamportBack(response.stake_amount)),
    totalRewardAmount: parseFloat(convertLamportBack(response.total_reward_amount)),
    createBlockTime: response.create_block_time,
    createDate: response.create_date,
    updateDate: response.update_date,
    amountFrozen: parseFloat(convertLamportBack(response.freeze_amount)),
    freezeEndTime,
    amountWithdrawable: parseFloat(convertLamportBack(response.withdrawable)),
    status: response.status,
    hardwareQuantity: 1,
    hardwareManufacturer: "Unknown",
    hardwareName: "Unknown",
    hardwareManufacturerColor: "text-green-dark-100"
  } as UserStakedDevice;
};

const normaliseStakeEligibleDevice = (response: MiddlewareStakeEligibleDeviceResponse) => {
  return {
    createDate: formatDateTime(response.create_date),
    deviceId: response.device_id,
    id: `${response.id}`,
    lastRewardsTime: formatDateTime(response.last_rewards_time),
    minimumStakeAmount: parseFloat(convertLamportBack(response.minimum_stake_amount)),
    totalRewards: parseFloat(convertLamportBack(response.total_rewards)),
    totalStakeAccountCount: parseInt(response.total_stake_account, 10),
    totalStakeAmount: parseFloat(convertLamportBack(response.total_stake_amount)),
    updateDate: formatDateTime(response.update_date)
  } as MiddlewareStakeEligibleDevice;
};

export const toStakeEligibleDevice = (device: UserStakedDevice) => {
  return {
    createDate: "",
    deviceId: `${device.deviceId}`,
    deviceName: device.deviceName,
    id: `${device.id}`,
    lastRewardsTime: "",
    minimumStakeAmount: 0,
    totalRewards: 0,
    totalStakeAccountCount: 1,
    totalStakeAmount: device.amountStaked,
    updateDate: ""
  } as MiddlewareStakeEligibleDevice;
};

export const normaliseUserStakedDevice = (result: UserStakedDeviceResponse) => {
  const statusType = result.status.toLowerCase();
  const deviceStatus = STATUS_MAP[`${statusType}` as keyof typeof STATUS_MAP];
  const id = `${result.device_id}`;
  const hardware = getHardware(result.brand_name);
  const freezeEndTime =
    typeof result.freeze_end_time === "string"
      ? new Date(Date.parse(`${result.freeze_end_time}Z`))
      : null;
  const hasStakedMinimum = (result?.amount_staked || 0) >= (result?.minimum_staked || -1);
  const remainingInterval =
    freezeEndTime instanceof Date
      ? Math.max(0, freezeEndTime.getTime() - new Date().getTime())
      : undefined;
  const frozenTimeRemaining =
    typeof remainingInterval === "number" ? toDuration(remainingInterval) : undefined;
  const frozenTimeShortRemaining =
    typeof frozenTimeRemaining === "string"
      ? frozenTimeRemaining.split(" ").slice(0, 2).join(" ")
      : undefined;
  const frozenDuration = getStakeFrozenDuration();
  const frozenTimeRemainingAsPercentage =
    typeof remainingInterval === "number"
      ? Math.min(100, Math.max(0, ((frozenDuration - remainingInterval) / frozenDuration) * 100))
      : undefined;
  const stakedWalletAddress =
    result.amount_staked === 0 && result.amount_frozen === 0 && result.amount_withdrawable === 0
      ? undefined
      : result.user_account;
  const estimatedBlockRewards =
    typeof result.estimated_block_rewards === "number" ? result.estimated_block_rewards : 0;
  const sharedBlockRewardsPercent = result.shared_block_rewards_percent as number;
  const costakerEstimatedBlockRewards = (sharedBlockRewardsPercent / 100) * estimatedBlockRewards;
  const minimumStaked = parseFloat(convertLamportBack(result.minimum_staked));
  const costakeAmount = parseFloat(convertLamportBack(result.co_stake_amount));
  const costakeContribution = (costakeAmount / minimumStaked) * 100;
  const ownerContribution = 100 - costakeContribution;
  const ownerStakeAmount = (minimumStaked as number) * (ownerContribution / 100);
  const costakerAmount = (minimumStaked as number) * (costakeContribution / 100);
  const overflowAmount = parseFloat(convertLamportBack(result.overflow_amount));
  const netAmountStaked = parseFloat(convertLamportBack(result.net_amount_staked));
  const amountWithdrawable = parseFloat(convertLamportBack(result.amount_withdrawable));

  // if (amountWithdrawable === 0 || isNaN(amountWithdrawable)) {
  //   amountWithdrawable = Math.max(0, overflowAmount);
  // }

  return {
    id,
    status: getUserStakedDeviceStatus(result),
    deviceId: result.device_id,
    deviceName: result.device_name,
    minimumStaked,
    hardwareQuantity: result.hardware_quantity,
    hardwareName: result.hardware_name ?? "Unknown",
    blockRewards: result.block_rewards,
    amountStaked: parseFloat(convertLamportBack(result.amount_staked)),
    amountFrozen: parseFloat(convertLamportBack(result.amount_frozen)),
    amountWithdrawable,
    aprEstimate: result.apr_estimate,
    deviceStatus: deviceStatus.status,
    ...(hardware
      ? hardware
      : {
          hardwareManufacturerColor: "text-green-dark-100",
          hardwareManufacturer: "Unknown"
        }),
    freezeEndTime,
    hasStakedMinimum,
    frozenTimeRemaining,
    frozenTimeShortRemaining,
    frozenTimeRemainingAsPercentage,
    stakedWalletAddress,
    estimatedBlockRewards,
    sharedBlockRewardsPercent,
    costakerEstimatedBlockRewards,
    coStakeContribution: costakeContribution,
    ownerStakeAmount,
    ownerContribution,
    coStakeAmount: costakeAmount,
    coStakeStatus: result.co_stake_status,
    coStakerAmount: costakerAmount,
    stakingAllowed: result.staking_allowed !== false,
    overflowAmount,
    netAmountStaked,
    canUnstakeCustomAmount: true,
    coStakeOfferId: result.co_stake_offer_id,
    type: result.co_stake_status ? "costaked" : "staked",
    isCoStaker: false
  } as UserStakedDevice;
};

const getUserStakedDeviceStatus = (result: UserStakedDeviceResponse) => {
  if (result.amount_staked >= result.minimum_staked) {
    return UserStakedDeviceStatusType.SUFFICIENT;
  }
  return UserStakedDeviceStatusType.INSUFFICIENT;
};

export const MODIFY_COMPUTE_UNITS = ComputeBudgetProgram.setComputeUnitLimit({
  units: env.STAKING_COMPUTE_UNIT_LIMIT
});

export const ADD_PRIORITY_FEE = ComputeBudgetProgram.setComputeUnitPrice({
  microLamports: env.STAKING_COMPUTE_UNIT_PRICE
});

const toTransactionWithPriority = (transaction: Transaction) => {
  transaction.add(MODIFY_COMPUTE_UNITS).add(ADD_PRIORITY_FEE); //

  return transaction;
};

export const isCapacityReachedError = (e: unknown) => {
  const friendlyError = toFriendlyPublicError(e);

  return friendlyError?.message === "Hardware staking pool capacity exceeded";
};

export const normaliseUserStakingTransaction = (transaction: UserStakingTransactionsResponse) => {
  const isAmountWithdrawn = transaction.is_amount_withdrawn;

  return {
    date: transaction.date,
    hash: transaction.hash,
    type: transaction.type,
    amount: transaction.amount,
    isAmountWithdrawn,
    userWallet: transaction.user_wallet,
    ...calculateStakeCooldown(transaction)
  } as UserStakingTransactionsType;
};

const calculateStakeCooldown = (transaction: UserStakingTransactionsResponse) => {
  const time = transaction.date;
  const isAmountWithdrawn = transaction.is_amount_withdrawn;
  const transactionTime = new Date(Date.parse(`${time}Z`)).getTime();
  const cooldownDuration = getStakeFrozenDuration();
  const cooldownEndTime = transactionTime + cooldownDuration;
  const remainingTime = cooldownEndTime - new Date().getTime();
  const cooldownTimeRemaining =
    typeof cooldownEndTime === "number" ? toDuration(remainingTime) : undefined;
  const cooldownEndTimeAsPercentage = Math.min(
    100,
    Math.max(0, ((cooldownDuration - remainingTime) / cooldownDuration) * 100)
  );
  const cooldownEndTimeString = new Date(cooldownEndTime).toISOString();

  return {
    cooldownEndTime: cooldownEndTimeString,
    cooldownTimeRemaining,
    cooldownEndTimeAsPercentage,
    getCooldownTooltip: (userTimezone: string) => {
      if (!["unstake"].includes(transaction.type)) {
        return;
      }

      if (isAmountWithdrawn) {
        return "The amount is withdrawn";
      }
      if (remainingTime < 0) {
        return "The unstaked amount is now available for withdrawal";
      }
      return `Amount available for withdraw after ${formattedDateTime(cooldownEndTimeString, userTimezone)}`;
    }
  };
};
