import * as React from "react";
import * as SeparatorPrimitive from "@radix-ui/react-separator";

import { cn } from "@/lib/utils";
import { IoIcon } from "../Icons/IoIcon";

const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(({ className, orientation = "horizontal", decorative = true, ...props }, ref) => (
  <SeparatorPrimitive.Root
    ref={ref}
    decorative={decorative}
    orientation={orientation}
    className={cn(
      "shrink-0 bg-gray-dark-900",
      orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
      className
    )}
    {...props}
  />
));
Separator.displayName = SeparatorPrimitive.Root.displayName;

const LogoSeparator = () => {
  return (
    <Separator className="flex flex-row items-center justify-center">
      <div className="flex flex-row items-center justify-center bg-black px-2">
        <IoIcon className="h-[10px] text-gray-dark-900" />
      </div>
    </Separator>
  );
};

export { Separator, LogoSeparator };
