export type Iostaking = {
  address: "3RRz3bZ7Khr3Cw2i7JURpKuUPT3G9QFV7fNVPmhSsF2i";
  metadata: {
    name: "iostaking";
    version: "2.1.2";
    spec: "0.1.0";
    description: "Created with Anchor";
  };
  instructions: [
    {
      name: "addStake";
      discriminator: [58, 135, 189, 105, 160, 120, 165, 224];
      accounts: [
        {
          name: "signer";
          signer: true;
        },
        {
          name: "nodeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [110, 111, 100, 101];
              },
              {
                kind: "account";
                path: "node_account.id";
                account: "nodeAccount";
              }
            ];
          };
        },
        {
          name: "stakeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [115, 116, 97, 107, 101];
              },
              {
                kind: "account";
                path: "stake_account.node";
                account: "stakeAccount";
              },
              {
                kind: "account";
                path: "stake_account.authority";
                account: "stakeAccount";
              }
            ];
          };
        },
        {
          name: "mint";
        },
        {
          name: "signerAta";
          writable: true;
        },
        {
          name: "stakeAccountAta";
          writable: true;
        },
        {
          name: "associatedTokenProgram";
          address: "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL";
        },
        {
          name: "tokenProgram";
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA";
        },
        {
          name: "systemProgram";
          address: "11111111111111111111111111111111";
        }
      ];
      args: [
        {
          name: "amount";
          type: "u64";
        }
      ];
    },
    {
      name: "createCostake";
      discriminator: [72, 229, 37, 67, 192, 244, 104, 220];
      accounts: [
        {
          name: "signer";
          writable: true;
          signer: true;
        },
        {
          name: "governorActiveSigner";
          writable: true;
          signer: true;
        },
        {
          name: "governorAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [103, 111, 118, 101, 114, 110, 111, 114];
              }
            ];
          };
        },
        {
          name: "costakeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [115, 116, 97, 107, 101];
              },
              {
                kind: "account";
                path: "nodeAccount";
              },
              {
                kind: "account";
                path: "signer";
              }
            ];
          };
        },
        {
          name: "nodeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [110, 111, 100, 101];
              },
              {
                kind: "account";
                path: "node_account.id";
                account: "nodeAccount";
              }
            ];
          };
        },
        {
          name: "nodeStakeAccountAta";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "account";
                path: "node_account.node_stake_account";
                account: "nodeAccount";
              },
              {
                kind: "const";
                value: [
                  6,
                  221,
                  246,
                  225,
                  215,
                  101,
                  161,
                  147,
                  217,
                  203,
                  225,
                  70,
                  206,
                  235,
                  121,
                  172,
                  28,
                  180,
                  133,
                  237,
                  95,
                  91,
                  55,
                  145,
                  58,
                  140,
                  245,
                  133,
                  126,
                  255,
                  0,
                  169
                ];
              },
              {
                kind: "account";
                path: "mint";
              }
            ];
            program: {
              kind: "const";
              value: [
                140,
                151,
                37,
                143,
                78,
                36,
                137,
                241,
                187,
                61,
                16,
                41,
                20,
                142,
                13,
                131,
                11,
                90,
                19,
                153,
                218,
                255,
                16,
                132,
                4,
                142,
                123,
                216,
                219,
                233,
                248,
                89
              ];
            };
          };
        },
        {
          name: "mint";
        },
        {
          name: "signerAta";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "account";
                path: "signer";
              },
              {
                kind: "const";
                value: [
                  6,
                  221,
                  246,
                  225,
                  215,
                  101,
                  161,
                  147,
                  217,
                  203,
                  225,
                  70,
                  206,
                  235,
                  121,
                  172,
                  28,
                  180,
                  133,
                  237,
                  95,
                  91,
                  55,
                  145,
                  58,
                  140,
                  245,
                  133,
                  126,
                  255,
                  0,
                  169
                ];
              },
              {
                kind: "account";
                path: "mint";
              }
            ];
            program: {
              kind: "const";
              value: [
                140,
                151,
                37,
                143,
                78,
                36,
                137,
                241,
                187,
                61,
                16,
                41,
                20,
                142,
                13,
                131,
                11,
                90,
                19,
                153,
                218,
                255,
                16,
                132,
                4,
                142,
                123,
                216,
                219,
                233,
                248,
                89
              ];
            };
          };
        },
        {
          name: "costakeAccountAta";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "account";
                path: "costakeAccount";
              },
              {
                kind: "const";
                value: [
                  6,
                  221,
                  246,
                  225,
                  215,
                  101,
                  161,
                  147,
                  217,
                  203,
                  225,
                  70,
                  206,
                  235,
                  121,
                  172,
                  28,
                  180,
                  133,
                  237,
                  95,
                  91,
                  55,
                  145,
                  58,
                  140,
                  245,
                  133,
                  126,
                  255,
                  0,
                  169
                ];
              },
              {
                kind: "account";
                path: "mint";
              }
            ];
            program: {
              kind: "const";
              value: [
                140,
                151,
                37,
                143,
                78,
                36,
                137,
                241,
                187,
                61,
                16,
                41,
                20,
                142,
                13,
                131,
                11,
                90,
                19,
                153,
                218,
                255,
                16,
                132,
                4,
                142,
                123,
                216,
                219,
                233,
                248,
                89
              ];
            };
          };
        },
        {
          name: "associatedTokenProgram";
          address: "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL";
        },
        {
          name: "tokenProgram";
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA";
        },
        {
          name: "systemProgram";
          address: "11111111111111111111111111111111";
        }
      ];
      args: [];
    },
    {
      name: "createGovernor";
      discriminator: [103, 30, 78, 252, 28, 128, 40, 3];
      accounts: [
        {
          name: "signer";
          writable: true;
          signer: true;
        },
        {
          name: "governorAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [103, 111, 118, 101, 114, 110, 111, 114];
              }
            ];
          };
        },
        {
          name: "mint";
        },
        {
          name: "governorAccountAta";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "account";
                path: "governorAccount";
              },
              {
                kind: "const";
                value: [
                  6,
                  221,
                  246,
                  225,
                  215,
                  101,
                  161,
                  147,
                  217,
                  203,
                  225,
                  70,
                  206,
                  235,
                  121,
                  172,
                  28,
                  180,
                  133,
                  237,
                  95,
                  91,
                  55,
                  145,
                  58,
                  140,
                  245,
                  133,
                  126,
                  255,
                  0,
                  169
                ];
              },
              {
                kind: "account";
                path: "mint";
              }
            ];
            program: {
              kind: "const";
              value: [
                140,
                151,
                37,
                143,
                78,
                36,
                137,
                241,
                187,
                61,
                16,
                41,
                20,
                142,
                13,
                131,
                11,
                90,
                19,
                153,
                218,
                255,
                16,
                132,
                4,
                142,
                123,
                216,
                219,
                233,
                248,
                89
              ];
            };
          };
        },
        {
          name: "associatedTokenProgram";
          address: "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL";
        },
        {
          name: "tokenProgram";
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA";
        },
        {
          name: "systemProgram";
          address: "11111111111111111111111111111111";
        }
      ];
      args: [
        {
          name: "activeSigner";
          type: "pubkey";
        }
      ];
    },
    {
      name: "createNode";
      discriminator: [20, 183, 134, 233, 51, 51, 115, 83];
      accounts: [
        {
          name: "signer";
          writable: true;
          signer: true;
        },
        {
          name: "governorActiveSigner";
          writable: true;
          signer: true;
        },
        {
          name: "governorAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [103, 111, 118, 101, 114, 110, 111, 114];
              }
            ];
          };
        },
        {
          name: "nodeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [110, 111, 100, 101];
              },
              {
                kind: "arg";
                path: "id";
              }
            ];
          };
        },
        {
          name: "mint";
        },
        {
          name: "signerAta";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "account";
                path: "signer";
              },
              {
                kind: "const";
                value: [
                  6,
                  221,
                  246,
                  225,
                  215,
                  101,
                  161,
                  147,
                  217,
                  203,
                  225,
                  70,
                  206,
                  235,
                  121,
                  172,
                  28,
                  180,
                  133,
                  237,
                  95,
                  91,
                  55,
                  145,
                  58,
                  140,
                  245,
                  133,
                  126,
                  255,
                  0,
                  169
                ];
              },
              {
                kind: "account";
                path: "mint";
              }
            ];
            program: {
              kind: "const";
              value: [
                140,
                151,
                37,
                143,
                78,
                36,
                137,
                241,
                187,
                61,
                16,
                41,
                20,
                142,
                13,
                131,
                11,
                90,
                19,
                153,
                218,
                255,
                16,
                132,
                4,
                142,
                123,
                216,
                219,
                233,
                248,
                89
              ];
            };
          };
        },
        {
          name: "stakeAccountAta";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "account";
                path: "stakeAccount";
              },
              {
                kind: "const";
                value: [
                  6,
                  221,
                  246,
                  225,
                  215,
                  101,
                  161,
                  147,
                  217,
                  203,
                  225,
                  70,
                  206,
                  235,
                  121,
                  172,
                  28,
                  180,
                  133,
                  237,
                  95,
                  91,
                  55,
                  145,
                  58,
                  140,
                  245,
                  133,
                  126,
                  255,
                  0,
                  169
                ];
              },
              {
                kind: "account";
                path: "mint";
              }
            ];
            program: {
              kind: "const";
              value: [
                140,
                151,
                37,
                143,
                78,
                36,
                137,
                241,
                187,
                61,
                16,
                41,
                20,
                142,
                13,
                131,
                11,
                90,
                19,
                153,
                218,
                255,
                16,
                132,
                4,
                142,
                123,
                216,
                219,
                233,
                248,
                89
              ];
            };
          };
        },
        {
          name: "stakeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [115, 116, 97, 107, 101];
              },
              {
                kind: "account";
                path: "nodeAccount";
              },
              {
                kind: "account";
                path: "signer";
              }
            ];
          };
        },
        {
          name: "associatedTokenProgram";
          address: "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL";
        },
        {
          name: "tokenProgram";
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA";
        },
        {
          name: "systemProgram";
          address: "11111111111111111111111111111111";
        }
      ];
      args: [
        {
          name: "id";
          type: "string";
        }
      ];
    },
    {
      name: "enableCostaker";
      discriminator: [101, 15, 32, 28, 175, 223, 118, 111];
      accounts: [
        {
          name: "signer";
          signer: true;
        },
        {
          name: "nodeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [110, 111, 100, 101];
              },
              {
                kind: "account";
                path: "node_account.id";
                account: "nodeAccount";
              }
            ];
          };
        },
        {
          name: "mint";
        },
        {
          name: "stakeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [115, 116, 97, 107, 101];
              },
              {
                kind: "account";
                path: "stake_account.node";
                account: "stakeAccount";
              },
              {
                kind: "account";
                path: "stake_account.authority";
                account: "stakeAccount";
              }
            ];
          };
        },
        {
          name: "stakeAccountAta";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "account";
                path: "stakeAccount";
              },
              {
                kind: "const";
                value: [
                  6,
                  221,
                  246,
                  225,
                  215,
                  101,
                  161,
                  147,
                  217,
                  203,
                  225,
                  70,
                  206,
                  235,
                  121,
                  172,
                  28,
                  180,
                  133,
                  237,
                  95,
                  91,
                  55,
                  145,
                  58,
                  140,
                  245,
                  133,
                  126,
                  255,
                  0,
                  169
                ];
              },
              {
                kind: "account";
                path: "mint";
              }
            ];
            program: {
              kind: "const";
              value: [
                140,
                151,
                37,
                143,
                78,
                36,
                137,
                241,
                187,
                61,
                16,
                41,
                20,
                142,
                13,
                131,
                11,
                90,
                19,
                153,
                218,
                255,
                16,
                132,
                4,
                142,
                123,
                216,
                219,
                233,
                248,
                89
              ];
            };
          };
        },
        {
          name: "systemProgram";
          address: "11111111111111111111111111111111";
        }
      ];
      args: [
        {
          name: "costakeEnabled";
          type: "bool";
        }
      ];
    },
    {
      name: "governorWithdraw";
      discriminator: [160, 81, 131, 104, 236, 111, 97, 150];
      accounts: [
        {
          name: "signer";
          signer: true;
        },
        {
          name: "signerAta";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "account";
                path: "signer";
              },
              {
                kind: "const";
                value: [
                  6,
                  221,
                  246,
                  225,
                  215,
                  101,
                  161,
                  147,
                  217,
                  203,
                  225,
                  70,
                  206,
                  235,
                  121,
                  172,
                  28,
                  180,
                  133,
                  237,
                  95,
                  91,
                  55,
                  145,
                  58,
                  140,
                  245,
                  133,
                  126,
                  255,
                  0,
                  169
                ];
              },
              {
                kind: "account";
                path: "mint";
              }
            ];
            program: {
              kind: "const";
              value: [
                140,
                151,
                37,
                143,
                78,
                36,
                137,
                241,
                187,
                61,
                16,
                41,
                20,
                142,
                13,
                131,
                11,
                90,
                19,
                153,
                218,
                255,
                16,
                132,
                4,
                142,
                123,
                216,
                219,
                233,
                248,
                89
              ];
            };
          };
        },
        {
          name: "governorAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [103, 111, 118, 101, 114, 110, 111, 114];
              }
            ];
          };
        },
        {
          name: "mint";
        },
        {
          name: "governorAccountAta";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "account";
                path: "governorAccount";
              },
              {
                kind: "const";
                value: [
                  6,
                  221,
                  246,
                  225,
                  215,
                  101,
                  161,
                  147,
                  217,
                  203,
                  225,
                  70,
                  206,
                  235,
                  121,
                  172,
                  28,
                  180,
                  133,
                  237,
                  95,
                  91,
                  55,
                  145,
                  58,
                  140,
                  245,
                  133,
                  126,
                  255,
                  0,
                  169
                ];
              },
              {
                kind: "account";
                path: "mint";
              }
            ];
            program: {
              kind: "const";
              value: [
                140,
                151,
                37,
                143,
                78,
                36,
                137,
                241,
                187,
                61,
                16,
                41,
                20,
                142,
                13,
                131,
                11,
                90,
                19,
                153,
                218,
                255,
                16,
                132,
                4,
                142,
                123,
                216,
                219,
                233,
                248,
                89
              ];
            };
          };
        },
        {
          name: "associatedTokenProgram";
          address: "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL";
        },
        {
          name: "tokenProgram";
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA";
        },
        {
          name: "systemProgram";
          address: "11111111111111111111111111111111";
        }
      ];
      args: [];
    },
    {
      name: "migrate";
      discriminator: [155, 234, 231, 146, 236, 158, 162, 30];
      accounts: [
        {
          name: "signer";
          writable: true;
          signer: true;
        },
        {
          name: "governorAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [103, 111, 118, 101, 114, 110, 111, 114];
              }
            ];
          };
        },
        {
          name: "nodeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [110, 111, 100, 101];
              },
              {
                kind: "arg";
                path: "id";
              }
            ];
          };
        },
        {
          name: "mint";
        },
        {
          name: "stakeAccountAta";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "account";
                path: "stakeAccount";
              },
              {
                kind: "const";
                value: [
                  6,
                  221,
                  246,
                  225,
                  215,
                  101,
                  161,
                  147,
                  217,
                  203,
                  225,
                  70,
                  206,
                  235,
                  121,
                  172,
                  28,
                  180,
                  133,
                  237,
                  95,
                  91,
                  55,
                  145,
                  58,
                  140,
                  245,
                  133,
                  126,
                  255,
                  0,
                  169
                ];
              },
              {
                kind: "account";
                path: "mint";
              }
            ];
            program: {
              kind: "const";
              value: [
                140,
                151,
                37,
                143,
                78,
                36,
                137,
                241,
                187,
                61,
                16,
                41,
                20,
                142,
                13,
                131,
                11,
                90,
                19,
                153,
                218,
                255,
                16,
                132,
                4,
                142,
                123,
                216,
                219,
                233,
                248,
                89
              ];
            };
          };
        },
        {
          name: "stakeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [115, 116, 97, 107, 101];
              },
              {
                kind: "account";
                path: "nodeAccount";
              },
              {
                kind: "arg";
                path: "deviceOwner";
              }
            ];
          };
        },
        {
          name: "associatedTokenProgram";
          address: "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL";
        },
        {
          name: "tokenProgram";
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA";
        },
        {
          name: "systemProgram";
          address: "11111111111111111111111111111111";
        }
      ];
      args: [
        {
          name: "id";
          type: "string";
        },
        {
          name: "deviceOwner";
          type: "pubkey";
        },
        {
          name: "cooldown";
          type: {
            option: "u64";
          };
        }
      ];
    },
    {
      name: "slash";
      discriminator: [204, 141, 18, 161, 8, 177, 92, 142];
      accounts: [
        {
          name: "signer";
          signer: true;
        },
        {
          name: "governorAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [103, 111, 118, 101, 114, 110, 111, 114];
              }
            ];
          };
        },
        {
          name: "nodeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [110, 111, 100, 101];
              },
              {
                kind: "account";
                path: "node_account.id";
                account: "nodeAccount";
              }
            ];
          };
        },
        {
          name: "stakeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [115, 116, 97, 107, 101];
              },
              {
                kind: "account";
                path: "stake_account.node";
                account: "stakeAccount";
              },
              {
                kind: "account";
                path: "stake_account.authority";
                account: "stakeAccount";
              }
            ];
          };
        },
        {
          name: "costakerAccount";
          writable: true;
          optional: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [115, 116, 97, 107, 101];
              },
              {
                kind: "account";
                path: "costaker_account.node";
                account: "stakeAccount";
              },
              {
                kind: "account";
                path: "costaker_account.authority";
                account: "stakeAccount";
              }
            ];
          };
        },
        {
          name: "stakeAccountAta";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "account";
                path: "stakeAccount";
              },
              {
                kind: "const";
                value: [
                  6,
                  221,
                  246,
                  225,
                  215,
                  101,
                  161,
                  147,
                  217,
                  203,
                  225,
                  70,
                  206,
                  235,
                  121,
                  172,
                  28,
                  180,
                  133,
                  237,
                  95,
                  91,
                  55,
                  145,
                  58,
                  140,
                  245,
                  133,
                  126,
                  255,
                  0,
                  169
                ];
              },
              {
                kind: "account";
                path: "mint";
              }
            ];
            program: {
              kind: "const";
              value: [
                140,
                151,
                37,
                143,
                78,
                36,
                137,
                241,
                187,
                61,
                16,
                41,
                20,
                142,
                13,
                131,
                11,
                90,
                19,
                153,
                218,
                255,
                16,
                132,
                4,
                142,
                123,
                216,
                219,
                233,
                248,
                89
              ];
            };
          };
        },
        {
          name: "costakerAccountAta";
          writable: true;
          optional: true;
          pda: {
            seeds: [
              {
                kind: "account";
                path: "costakerAccount";
              },
              {
                kind: "const";
                value: [
                  6,
                  221,
                  246,
                  225,
                  215,
                  101,
                  161,
                  147,
                  217,
                  203,
                  225,
                  70,
                  206,
                  235,
                  121,
                  172,
                  28,
                  180,
                  133,
                  237,
                  95,
                  91,
                  55,
                  145,
                  58,
                  140,
                  245,
                  133,
                  126,
                  255,
                  0,
                  169
                ];
              },
              {
                kind: "account";
                path: "mint";
              }
            ];
            program: {
              kind: "const";
              value: [
                140,
                151,
                37,
                143,
                78,
                36,
                137,
                241,
                187,
                61,
                16,
                41,
                20,
                142,
                13,
                131,
                11,
                90,
                19,
                153,
                218,
                255,
                16,
                132,
                4,
                142,
                123,
                216,
                219,
                233,
                248,
                89
              ];
            };
          };
        },
        {
          name: "mint";
        },
        {
          name: "governorAccountAta";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "account";
                path: "governorAccount";
              },
              {
                kind: "const";
                value: [
                  6,
                  221,
                  246,
                  225,
                  215,
                  101,
                  161,
                  147,
                  217,
                  203,
                  225,
                  70,
                  206,
                  235,
                  121,
                  172,
                  28,
                  180,
                  133,
                  237,
                  95,
                  91,
                  55,
                  145,
                  58,
                  140,
                  245,
                  133,
                  126,
                  255,
                  0,
                  169
                ];
              },
              {
                kind: "account";
                path: "mint";
              }
            ];
            program: {
              kind: "const";
              value: [
                140,
                151,
                37,
                143,
                78,
                36,
                137,
                241,
                187,
                61,
                16,
                41,
                20,
                142,
                13,
                131,
                11,
                90,
                19,
                153,
                218,
                255,
                16,
                132,
                4,
                142,
                123,
                216,
                219,
                233,
                248,
                89
              ];
            };
          };
        },
        {
          name: "associatedTokenProgram";
          address: "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL";
        },
        {
          name: "tokenProgram";
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA";
        },
        {
          name: "systemProgram";
          address: "11111111111111111111111111111111";
        }
      ];
      args: [
        {
          name: "removeNodeAmount";
          type: "u64";
        },
        {
          name: "removeCostakerAmount";
          type: {
            option: "u64";
          };
        }
      ];
    },
    {
      name: "unstake";
      discriminator: [90, 95, 107, 42, 205, 124, 50, 225];
      accounts: [
        {
          name: "signer";
          signer: true;
        },
        {
          name: "governorActiveSigner";
          writable: true;
          signer: true;
        },
        {
          name: "governorAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [103, 111, 118, 101, 114, 110, 111, 114];
              }
            ];
          };
        },
        {
          name: "nodeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [110, 111, 100, 101];
              },
              {
                kind: "account";
                path: "node_account.id";
                account: "nodeAccount";
              }
            ];
          };
        },
        {
          name: "stakeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [115, 116, 97, 107, 101];
              },
              {
                kind: "account";
                path: "stake_account.node";
                account: "stakeAccount";
              },
              {
                kind: "account";
                path: "stake_account.authority";
                account: "stakeAccount";
              }
            ];
          };
        },
        {
          name: "mint";
        },
        {
          name: "signerAta";
          writable: true;
        },
        {
          name: "stakeAccountAta";
          writable: true;
        },
        {
          name: "costakeAccount";
          writable: true;
          optional: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [115, 116, 97, 107, 101];
              },
              {
                kind: "account";
                path: "costake_account.node";
                account: "stakeAccount";
              },
              {
                kind: "account";
                path: "costake_account.authority";
                account: "stakeAccount";
              }
            ];
          };
        },
        {
          name: "costakeAccountAta";
          optional: true;
          pda: {
            seeds: [
              {
                kind: "account";
                path: "costakeAccount";
              },
              {
                kind: "const";
                value: [
                  6,
                  221,
                  246,
                  225,
                  215,
                  101,
                  161,
                  147,
                  217,
                  203,
                  225,
                  70,
                  206,
                  235,
                  121,
                  172,
                  28,
                  180,
                  133,
                  237,
                  95,
                  91,
                  55,
                  145,
                  58,
                  140,
                  245,
                  133,
                  126,
                  255,
                  0,
                  169
                ];
              },
              {
                kind: "account";
                path: "mint";
              }
            ];
            program: {
              kind: "const";
              value: [
                140,
                151,
                37,
                143,
                78,
                36,
                137,
                241,
                187,
                61,
                16,
                41,
                20,
                142,
                13,
                131,
                11,
                90,
                19,
                153,
                218,
                255,
                16,
                132,
                4,
                142,
                123,
                216,
                219,
                233,
                248,
                89
              ];
            };
          };
        },
        {
          name: "tokenProgram";
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA";
        },
        {
          name: "systemProgram";
          address: "11111111111111111111111111111111";
        }
      ];
      args: [
        {
          name: "amount";
          type: "u64";
        }
      ];
    },
    {
      name: "unstakeCostaker";
      discriminator: [200, 220, 154, 176, 205, 66, 127, 52];
      accounts: [
        {
          name: "signer";
          signer: true;
        },
        {
          name: "governorActiveSigner";
          writable: true;
          signer: true;
        },
        {
          name: "governorAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [103, 111, 118, 101, 114, 110, 111, 114];
              }
            ];
          };
        },
        {
          name: "nodeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [110, 111, 100, 101];
              },
              {
                kind: "account";
                path: "node_account.id";
                account: "nodeAccount";
              }
            ];
          };
        },
        {
          name: "stakeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [115, 116, 97, 107, 101];
              },
              {
                kind: "account";
                path: "stake_account.node";
                account: "stakeAccount";
              },
              {
                kind: "account";
                path: "stake_account.authority";
                account: "stakeAccount";
              }
            ];
          };
        },
        {
          name: "mint";
        },
        {
          name: "signerAta";
          writable: true;
        },
        {
          name: "stakeAccountAta";
          writable: true;
        },
        {
          name: "tokenProgram";
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA";
        },
        {
          name: "systemProgram";
          address: "11111111111111111111111111111111";
        }
      ];
      args: [];
    },
    {
      name: "updateGovernorAuthority";
      discriminator: [133, 131, 38, 220, 178, 56, 139, 197];
      accounts: [
        {
          name: "signer";
          signer: true;
        },
        {
          name: "governorAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [103, 111, 118, 101, 114, 110, 111, 114];
              }
            ];
          };
        },
        {
          name: "systemProgram";
          address: "11111111111111111111111111111111";
        }
      ];
      args: [
        {
          name: "newAuthority";
          type: "pubkey";
        }
      ];
    },
    {
      name: "updateGovernorSigner";
      discriminator: [23, 102, 136, 42, 175, 18, 28, 4];
      accounts: [
        {
          name: "signer";
          signer: true;
        },
        {
          name: "governorAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [103, 111, 118, 101, 114, 110, 111, 114];
              }
            ];
          };
        },
        {
          name: "systemProgram";
          address: "11111111111111111111111111111111";
        }
      ];
      args: [
        {
          name: "newActiveSigner";
          type: "pubkey";
        }
      ];
    },
    {
      name: "updateMinStake";
      discriminator: [209, 210, 237, 163, 84, 150, 199, 196];
      accounts: [
        {
          name: "signer";
          signer: true;
        },
        {
          name: "nodeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [110, 111, 100, 101];
              },
              {
                kind: "account";
                path: "node_account.id";
                account: "nodeAccount";
              }
            ];
          };
        },
        {
          name: "governorAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [103, 111, 118, 101, 114, 110, 111, 114];
              }
            ];
          };
        },
        {
          name: "systemProgram";
          address: "11111111111111111111111111111111";
        }
      ];
      args: [
        {
          name: "minStake";
          type: "u64";
        }
      ];
    },
    {
      name: "updateNodeCommission";
      discriminator: [190, 99, 227, 118, 93, 94, 237, 15];
      accounts: [
        {
          name: "signer";
          signer: true;
        },
        {
          name: "nodeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [110, 111, 100, 101];
              },
              {
                kind: "account";
                path: "node_account.id";
                account: "nodeAccount";
              }
            ];
          };
        },
        {
          name: "systemProgram";
          address: "11111111111111111111111111111111";
        }
      ];
      args: [
        {
          name: "newCommission";
          type: "u64";
        }
      ];
    },
    {
      name: "updateNodeStakeContributionSplit";
      discriminator: [19, 103, 246, 52, 81, 157, 154, 39];
      accounts: [
        {
          name: "signer";
          signer: true;
        },
        {
          name: "nodeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [110, 111, 100, 101];
              },
              {
                kind: "account";
                path: "node_account.id";
                account: "nodeAccount";
              }
            ];
          };
        },
        {
          name: "systemProgram";
          address: "11111111111111111111111111111111";
        }
      ];
      args: [
        {
          name: "updatedSplit";
          type: "u64";
        }
      ];
    },
    {
      name: "withdraw";
      discriminator: [183, 18, 70, 156, 148, 109, 161, 34];
      accounts: [
        {
          name: "signer";
          signer: true;
        },
        {
          name: "governorActiveSigner";
          writable: true;
          signer: true;
        },
        {
          name: "governorAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [103, 111, 118, 101, 114, 110, 111, 114];
              }
            ];
          };
        },
        {
          name: "stakeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [115, 116, 97, 107, 101];
              },
              {
                kind: "account";
                path: "stake_account.node";
                account: "stakeAccount";
              },
              {
                kind: "account";
                path: "stake_account.authority";
                account: "stakeAccount";
              }
            ];
          };
        },
        {
          name: "nodeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [110, 111, 100, 101];
              },
              {
                kind: "account";
                path: "node_account.id";
                account: "nodeAccount";
              }
            ];
          };
        },
        {
          name: "mint";
        },
        {
          name: "stakeAccountAta";
          writable: true;
        },
        {
          name: "signerAta";
          writable: true;
        },
        {
          name: "costakeAccount";
          writable: true;
          optional: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [115, 116, 97, 107, 101];
              },
              {
                kind: "account";
                path: "costake_account.node";
                account: "stakeAccount";
              },
              {
                kind: "account";
                path: "costake_account.authority";
                account: "stakeAccount";
              }
            ];
          };
        },
        {
          name: "costakeAccountAta";
          writable: true;
          optional: true;
          pda: {
            seeds: [
              {
                kind: "account";
                path: "costakeAccount";
              },
              {
                kind: "const";
                value: [
                  6,
                  221,
                  246,
                  225,
                  215,
                  101,
                  161,
                  147,
                  217,
                  203,
                  225,
                  70,
                  206,
                  235,
                  121,
                  172,
                  28,
                  180,
                  133,
                  237,
                  95,
                  91,
                  55,
                  145,
                  58,
                  140,
                  245,
                  133,
                  126,
                  255,
                  0,
                  169
                ];
              },
              {
                kind: "account";
                path: "mint";
              }
            ];
            program: {
              kind: "const";
              value: [
                140,
                151,
                37,
                143,
                78,
                36,
                137,
                241,
                187,
                61,
                16,
                41,
                20,
                142,
                13,
                131,
                11,
                90,
                19,
                153,
                218,
                255,
                16,
                132,
                4,
                142,
                123,
                216,
                219,
                233,
                248,
                89
              ];
            };
          };
        },
        {
          name: "costakeAuthorityAta";
          writable: true;
          optional: true;
          pda: {
            seeds: [
              {
                kind: "account";
                path: "costakeAccount";
              },
              {
                kind: "const";
                value: [
                  6,
                  221,
                  246,
                  225,
                  215,
                  101,
                  161,
                  147,
                  217,
                  203,
                  225,
                  70,
                  206,
                  235,
                  121,
                  172,
                  28,
                  180,
                  133,
                  237,
                  95,
                  91,
                  55,
                  145,
                  58,
                  140,
                  245,
                  133,
                  126,
                  255,
                  0,
                  169
                ];
              },
              {
                kind: "account";
                path: "mint";
              }
            ];
            program: {
              kind: "const";
              value: [
                140,
                151,
                37,
                143,
                78,
                36,
                137,
                241,
                187,
                61,
                16,
                41,
                20,
                142,
                13,
                131,
                11,
                90,
                19,
                153,
                218,
                255,
                16,
                132,
                4,
                142,
                123,
                216,
                219,
                233,
                248,
                89
              ];
            };
          };
        },
        {
          name: "tokenProgram";
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA";
        },
        {
          name: "systemProgram";
          address: "11111111111111111111111111111111";
        }
      ];
      args: [];
    },
    {
      name: "withdrawCostaker";
      discriminator: [112, 42, 251, 125, 159, 100, 232, 124];
      accounts: [
        {
          name: "signer";
          signer: true;
        },
        {
          name: "governorActiveSigner";
          writable: true;
          signer: true;
        },
        {
          name: "governorAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [103, 111, 118, 101, 114, 110, 111, 114];
              }
            ];
          };
        },
        {
          name: "costakeAccount";
          writable: true;
          pda: {
            seeds: [
              {
                kind: "const";
                value: [115, 116, 97, 107, 101];
              },
              {
                kind: "account";
                path: "costake_account.node";
                account: "stakeAccount";
              },
              {
                kind: "account";
                path: "costake_account.authority";
                account: "stakeAccount";
              }
            ];
          };
        },
        {
          name: "mint";
        },
        {
          name: "costakeAccountAta";
          writable: true;
        },
        {
          name: "signerAta";
          writable: true;
        },
        {
          name: "tokenProgram";
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA";
        },
        {
          name: "systemProgram";
          address: "11111111111111111111111111111111";
        }
      ];
      args: [];
    }
  ];
  accounts: [
    {
      name: "governorAccount";
      discriminator: [72, 243, 244, 220, 100, 83, 111, 55];
    },
    {
      name: "nodeAccount";
      discriminator: [125, 166, 18, 146, 195, 127, 86, 220];
    },
    {
      name: "stakeAccount";
      discriminator: [80, 158, 67, 124, 50, 189, 192, 255];
    }
  ];
  events: [
    {
      name: "addStakeEvent";
      discriminator: [156, 25, 90, 186, 60, 65, 57, 58];
    },
    {
      name: "createCoStakerEvent";
      discriminator: [242, 161, 149, 177, 49, 35, 5, 177];
    },
    {
      name: "unstakeCoStakerEvent";
      discriminator: [107, 24, 32, 98, 180, 99, 52, 212];
    },
    {
      name: "unstakeEvent";
      discriminator: [162, 104, 137, 228, 81, 3, 79, 197];
    },
    {
      name: "withdrawCoStakerEvent";
      discriminator: [108, 43, 45, 205, 177, 196, 218, 149];
    },
    {
      name: "withdrawEvent";
      discriminator: [22, 9, 133, 26, 160, 44, 71, 192];
    }
  ];
  errors: [
    {
      code: 6000;
      name: "maxSplitNodeAccount";
      msg: "Error: Max Split is 10,000 bps";
    },
    {
      code: 6001;
      name: "minSplitNodeAccount";
      msg: "Error: Min Split is 5000 bps";
    },
    {
      code: 6002;
      name: "maxCommissionNodeAccount";
      msg: "Error: Max Commission is 10,000 bps";
    },
    {
      code: 6003;
      name: "minCommissionNodeAccount";
      msg: "Error: Min Commission is 0 bps";
    },
    {
      code: 6004;
      name: "coStakingDisabled";
      msg: "Error: Co Staking is disabled by the node";
    },
    {
      code: 6005;
      name: "nodeAlreadyStaked";
      msg: "Error: Node Already has a Co staker";
    },
    {
      code: 6006;
      name: "invalidAmount";
      msg: "Error: Invalid Amount";
    },
    {
      code: 6007;
      name: "invalidCooldownPeriod";
      msg: "Error: Cooldown Period Not Set";
    },
    {
      code: 6008;
      name: "missingCoStaker";
      msg: "Error: Missing Co-Staker";
    },
    {
      code: 6009;
      name: "unstakeStarted";
      msg: "Error: Unstake has started";
    },
    {
      code: 6010;
      name: "missingUnStakeCoStaker";
      msg: "Error: Missing Unstake Co-Staker";
    },
    {
      code: 6011;
      name: "coStakerAlreadyExist";
      msg: "Error: Missing Unstake Co-Staker";
    },
    {
      code: 6012;
      name: "arithmeticError";
      msg: "Error: Arithmetic Error";
    }
  ];
  types: [
    {
      name: "addStakeEvent";
      type: {
        kind: "struct";
        fields: [
          {
            name: "amount";
            type: "u64";
          },
          {
            name: "nodeId";
            type: "string";
          }
        ];
      };
    },
    {
      name: "createCoStakerEvent";
      type: {
        kind: "struct";
        fields: [
          {
            name: "nodeId";
            type: "string";
          },
          {
            name: "coStakerAuthority";
            type: "pubkey";
          }
        ];
      };
    },
    {
      name: "governorAccount";
      repr: {
        kind: "c";
      };
      type: {
        kind: "struct";
        fields: [
          {
            name: "authority";
            type: "pubkey";
          },
          {
            name: "activeSigner";
            type: "pubkey";
          },
          {
            name: "bump";
            type: "u8";
          },
          {
            name: "padding";
            type: {
              array: ["u128", 8];
            };
          }
        ];
      };
    },
    {
      name: "nodeAccount";
      type: {
        kind: "struct";
        fields: [
          {
            name: "id";
            type: "string";
          },
          {
            name: "authority";
            type: "pubkey";
          },
          {
            name: "config";
            type: {
              defined: {
                name: "nodeConfig";
              };
            };
          },
          {
            name: "nodeStakeAccount";
            type: "pubkey";
          },
          {
            name: "costakerStakeAccount";
            type: {
              option: "pubkey";
            };
          },
          {
            name: "coStakingEnabled";
            type: "bool";
          },
          {
            name: "bump";
            type: "u8";
          },
          {
            name: "unstakeStarted";
            type: "bool";
          },
          {
            name: "minStakeCheckExemption";
            type: {
              option: "u64";
            };
          },
          {
            name: "padding";
            type: {
              array: ["u128", 8];
            };
          }
        ];
      };
    },
    {
      name: "nodeConfig";
      type: {
        kind: "struct";
        fields: [
          {
            name: "brCommission";
            type: "u64";
          },
          {
            name: "minStake";
            type: "u64";
          },
          {
            name: "coStakingSplit";
            type: "u64";
          }
        ];
      };
    },
    {
      name: "stakeAccount";
      type: {
        kind: "struct";
        fields: [
          {
            name: "node";
            type: "pubkey";
          },
          {
            name: "authority";
            type: "pubkey";
          },
          {
            name: "cooldownTime";
            type: {
              option: "u64";
            };
          },
          {
            name: "noticeTime";
            type: {
              option: "u64";
            };
          },
          {
            name: "isCostaker";
            type: "bool";
          },
          {
            name: "bump";
            type: "u8";
          },
          {
            name: "padding";
            type: {
              array: ["u128", 8];
            };
          }
        ];
      };
    },
    {
      name: "unstakeCoStakerEvent";
      type: {
        kind: "struct";
        fields: [
          {
            name: "authority";
            type: "pubkey";
          },
          {
            name: "nodeId";
            type: "string";
          }
        ];
      };
    },
    {
      name: "unstakeEvent";
      type: {
        kind: "struct";
        fields: [
          {
            name: "authority";
            type: "pubkey";
          },
          {
            name: "nodeId";
            type: "string";
          }
        ];
      };
    },
    {
      name: "withdrawCoStakerEvent";
      type: {
        kind: "struct";
        fields: [
          {
            name: "coStaker";
            type: "pubkey";
          },
          {
            name: "node";
            type: "pubkey";
          }
        ];
      };
    },
    {
      name: "withdrawEvent";
      type: {
        kind: "struct";
        fields: [
          {
            name: "authority";
            type: "pubkey";
          },
          {
            name: "coStaker";
            type: {
              option: "pubkey";
            };
          },
          {
            name: "nodeId";
            type: "string";
          }
        ];
      };
    }
  ];
};

export const IDLV2: Iostaking = {
  address: "3RRz3bZ7Khr3Cw2i7JURpKuUPT3G9QFV7fNVPmhSsF2i",
  metadata: {
    name: "iostaking",
    version: "2.1.2",
    spec: "0.1.0",
    description: "Created with Anchor"
  },
  instructions: [
    {
      name: "addStake",
      discriminator: [58, 135, 189, 105, 160, 120, 165, 224],
      accounts: [
        {
          name: "signer",
          signer: true
        },
        {
          name: "nodeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [110, 111, 100, 101]
              },
              {
                kind: "account",
                path: "node_account.id",
                account: "nodeAccount"
              }
            ]
          }
        },
        {
          name: "stakeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [115, 116, 97, 107, 101]
              },
              {
                kind: "account",
                path: "stake_account.node",
                account: "stakeAccount"
              },
              {
                kind: "account",
                path: "stake_account.authority",
                account: "stakeAccount"
              }
            ]
          }
        },
        {
          name: "mint"
        },
        {
          name: "signerAta",
          writable: true
        },
        {
          name: "stakeAccountAta",
          writable: true
        },
        {
          name: "associatedTokenProgram",
          address: "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
        },
        {
          name: "tokenProgram",
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
        },
        {
          name: "systemProgram",
          address: "11111111111111111111111111111111"
        }
      ],
      args: [
        {
          name: "amount",
          type: "u64"
        }
      ]
    },
    {
      name: "createCostake",
      discriminator: [72, 229, 37, 67, 192, 244, 104, 220],
      accounts: [
        {
          name: "signer",
          writable: true,
          signer: true
        },
        {
          name: "governorActiveSigner",
          writable: true,
          signer: true
        },
        {
          name: "governorAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [103, 111, 118, 101, 114, 110, 111, 114]
              }
            ]
          }
        },
        {
          name: "costakeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [115, 116, 97, 107, 101]
              },
              {
                kind: "account",
                path: "nodeAccount"
              },
              {
                kind: "account",
                path: "signer"
              }
            ]
          }
        },
        {
          name: "nodeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [110, 111, 100, 101]
              },
              {
                kind: "account",
                path: "node_account.id",
                account: "nodeAccount"
              }
            ]
          }
        },
        {
          name: "nodeStakeAccountAta",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "account",
                path: "node_account.node_stake_account",
                account: "nodeAccount"
              },
              {
                kind: "const",
                value: [
                  6, 221, 246, 225, 215, 101, 161, 147, 217, 203, 225, 70, 206, 235, 121, 172, 28,
                  180, 133, 237, 95, 91, 55, 145, 58, 140, 245, 133, 126, 255, 0, 169
                ]
              },
              {
                kind: "account",
                path: "mint"
              }
            ],
            program: {
              kind: "const",
              value: [
                140, 151, 37, 143, 78, 36, 137, 241, 187, 61, 16, 41, 20, 142, 13, 131, 11, 90, 19,
                153, 218, 255, 16, 132, 4, 142, 123, 216, 219, 233, 248, 89
              ]
            }
          }
        },
        {
          name: "mint"
        },
        {
          name: "signerAta",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "account",
                path: "signer"
              },
              {
                kind: "const",
                value: [
                  6, 221, 246, 225, 215, 101, 161, 147, 217, 203, 225, 70, 206, 235, 121, 172, 28,
                  180, 133, 237, 95, 91, 55, 145, 58, 140, 245, 133, 126, 255, 0, 169
                ]
              },
              {
                kind: "account",
                path: "mint"
              }
            ],
            program: {
              kind: "const",
              value: [
                140, 151, 37, 143, 78, 36, 137, 241, 187, 61, 16, 41, 20, 142, 13, 131, 11, 90, 19,
                153, 218, 255, 16, 132, 4, 142, 123, 216, 219, 233, 248, 89
              ]
            }
          }
        },
        {
          name: "costakeAccountAta",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "account",
                path: "costakeAccount"
              },
              {
                kind: "const",
                value: [
                  6, 221, 246, 225, 215, 101, 161, 147, 217, 203, 225, 70, 206, 235, 121, 172, 28,
                  180, 133, 237, 95, 91, 55, 145, 58, 140, 245, 133, 126, 255, 0, 169
                ]
              },
              {
                kind: "account",
                path: "mint"
              }
            ],
            program: {
              kind: "const",
              value: [
                140, 151, 37, 143, 78, 36, 137, 241, 187, 61, 16, 41, 20, 142, 13, 131, 11, 90, 19,
                153, 218, 255, 16, 132, 4, 142, 123, 216, 219, 233, 248, 89
              ]
            }
          }
        },
        {
          name: "associatedTokenProgram",
          address: "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
        },
        {
          name: "tokenProgram",
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
        },
        {
          name: "systemProgram",
          address: "11111111111111111111111111111111"
        }
      ],
      args: []
    },
    {
      name: "createGovernor",
      discriminator: [103, 30, 78, 252, 28, 128, 40, 3],
      accounts: [
        {
          name: "signer",
          writable: true,
          signer: true
        },
        {
          name: "governorAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [103, 111, 118, 101, 114, 110, 111, 114]
              }
            ]
          }
        },
        {
          name: "mint"
        },
        {
          name: "governorAccountAta",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "account",
                path: "governorAccount"
              },
              {
                kind: "const",
                value: [
                  6, 221, 246, 225, 215, 101, 161, 147, 217, 203, 225, 70, 206, 235, 121, 172, 28,
                  180, 133, 237, 95, 91, 55, 145, 58, 140, 245, 133, 126, 255, 0, 169
                ]
              },
              {
                kind: "account",
                path: "mint"
              }
            ],
            program: {
              kind: "const",
              value: [
                140, 151, 37, 143, 78, 36, 137, 241, 187, 61, 16, 41, 20, 142, 13, 131, 11, 90, 19,
                153, 218, 255, 16, 132, 4, 142, 123, 216, 219, 233, 248, 89
              ]
            }
          }
        },
        {
          name: "associatedTokenProgram",
          address: "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
        },
        {
          name: "tokenProgram",
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
        },
        {
          name: "systemProgram",
          address: "11111111111111111111111111111111"
        }
      ],
      args: [
        {
          name: "activeSigner",
          type: "pubkey"
        }
      ]
    },
    {
      name: "createNode",
      discriminator: [20, 183, 134, 233, 51, 51, 115, 83],
      accounts: [
        {
          name: "signer",
          writable: true,
          signer: true
        },
        {
          name: "governorActiveSigner",
          writable: true,
          signer: true
        },
        {
          name: "governorAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [103, 111, 118, 101, 114, 110, 111, 114]
              }
            ]
          }
        },
        {
          name: "nodeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [110, 111, 100, 101]
              },
              {
                kind: "arg",
                path: "id"
              }
            ]
          }
        },
        {
          name: "mint"
        },
        {
          name: "signerAta",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "account",
                path: "signer"
              },
              {
                kind: "const",
                value: [
                  6, 221, 246, 225, 215, 101, 161, 147, 217, 203, 225, 70, 206, 235, 121, 172, 28,
                  180, 133, 237, 95, 91, 55, 145, 58, 140, 245, 133, 126, 255, 0, 169
                ]
              },
              {
                kind: "account",
                path: "mint"
              }
            ],
            program: {
              kind: "const",
              value: [
                140, 151, 37, 143, 78, 36, 137, 241, 187, 61, 16, 41, 20, 142, 13, 131, 11, 90, 19,
                153, 218, 255, 16, 132, 4, 142, 123, 216, 219, 233, 248, 89
              ]
            }
          }
        },
        {
          name: "stakeAccountAta",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "account",
                path: "stakeAccount"
              },
              {
                kind: "const",
                value: [
                  6, 221, 246, 225, 215, 101, 161, 147, 217, 203, 225, 70, 206, 235, 121, 172, 28,
                  180, 133, 237, 95, 91, 55, 145, 58, 140, 245, 133, 126, 255, 0, 169
                ]
              },
              {
                kind: "account",
                path: "mint"
              }
            ],
            program: {
              kind: "const",
              value: [
                140, 151, 37, 143, 78, 36, 137, 241, 187, 61, 16, 41, 20, 142, 13, 131, 11, 90, 19,
                153, 218, 255, 16, 132, 4, 142, 123, 216, 219, 233, 248, 89
              ]
            }
          }
        },
        {
          name: "stakeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [115, 116, 97, 107, 101]
              },
              {
                kind: "account",
                path: "nodeAccount"
              },
              {
                kind: "account",
                path: "signer"
              }
            ]
          }
        },
        {
          name: "associatedTokenProgram",
          address: "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
        },
        {
          name: "tokenProgram",
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
        },
        {
          name: "systemProgram",
          address: "11111111111111111111111111111111"
        }
      ],
      args: [
        {
          name: "id",
          type: "string"
        }
      ]
    },
    {
      name: "enableCostaker",
      discriminator: [101, 15, 32, 28, 175, 223, 118, 111],
      accounts: [
        {
          name: "signer",
          signer: true
        },
        {
          name: "nodeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [110, 111, 100, 101]
              },
              {
                kind: "account",
                path: "node_account.id",
                account: "nodeAccount"
              }
            ]
          }
        },
        {
          name: "mint"
        },
        {
          name: "stakeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [115, 116, 97, 107, 101]
              },
              {
                kind: "account",
                path: "stake_account.node",
                account: "stakeAccount"
              },
              {
                kind: "account",
                path: "stake_account.authority",
                account: "stakeAccount"
              }
            ]
          }
        },
        {
          name: "stakeAccountAta",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "account",
                path: "stakeAccount"
              },
              {
                kind: "const",
                value: [
                  6, 221, 246, 225, 215, 101, 161, 147, 217, 203, 225, 70, 206, 235, 121, 172, 28,
                  180, 133, 237, 95, 91, 55, 145, 58, 140, 245, 133, 126, 255, 0, 169
                ]
              },
              {
                kind: "account",
                path: "mint"
              }
            ],
            program: {
              kind: "const",
              value: [
                140, 151, 37, 143, 78, 36, 137, 241, 187, 61, 16, 41, 20, 142, 13, 131, 11, 90, 19,
                153, 218, 255, 16, 132, 4, 142, 123, 216, 219, 233, 248, 89
              ]
            }
          }
        },
        {
          name: "systemProgram",
          address: "11111111111111111111111111111111"
        }
      ],
      args: [
        {
          name: "costakeEnabled",
          type: "bool"
        }
      ]
    },
    {
      name: "governorWithdraw",
      discriminator: [160, 81, 131, 104, 236, 111, 97, 150],
      accounts: [
        {
          name: "signer",
          signer: true
        },
        {
          name: "signerAta",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "account",
                path: "signer"
              },
              {
                kind: "const",
                value: [
                  6, 221, 246, 225, 215, 101, 161, 147, 217, 203, 225, 70, 206, 235, 121, 172, 28,
                  180, 133, 237, 95, 91, 55, 145, 58, 140, 245, 133, 126, 255, 0, 169
                ]
              },
              {
                kind: "account",
                path: "mint"
              }
            ],
            program: {
              kind: "const",
              value: [
                140, 151, 37, 143, 78, 36, 137, 241, 187, 61, 16, 41, 20, 142, 13, 131, 11, 90, 19,
                153, 218, 255, 16, 132, 4, 142, 123, 216, 219, 233, 248, 89
              ]
            }
          }
        },
        {
          name: "governorAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [103, 111, 118, 101, 114, 110, 111, 114]
              }
            ]
          }
        },
        {
          name: "mint"
        },
        {
          name: "governorAccountAta",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "account",
                path: "governorAccount"
              },
              {
                kind: "const",
                value: [
                  6, 221, 246, 225, 215, 101, 161, 147, 217, 203, 225, 70, 206, 235, 121, 172, 28,
                  180, 133, 237, 95, 91, 55, 145, 58, 140, 245, 133, 126, 255, 0, 169
                ]
              },
              {
                kind: "account",
                path: "mint"
              }
            ],
            program: {
              kind: "const",
              value: [
                140, 151, 37, 143, 78, 36, 137, 241, 187, 61, 16, 41, 20, 142, 13, 131, 11, 90, 19,
                153, 218, 255, 16, 132, 4, 142, 123, 216, 219, 233, 248, 89
              ]
            }
          }
        },
        {
          name: "associatedTokenProgram",
          address: "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
        },
        {
          name: "tokenProgram",
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
        },
        {
          name: "systemProgram",
          address: "11111111111111111111111111111111"
        }
      ],
      args: []
    },
    {
      name: "migrate",
      discriminator: [155, 234, 231, 146, 236, 158, 162, 30],
      accounts: [
        {
          name: "signer",
          writable: true,
          signer: true
        },
        {
          name: "governorAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [103, 111, 118, 101, 114, 110, 111, 114]
              }
            ]
          }
        },
        {
          name: "nodeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [110, 111, 100, 101]
              },
              {
                kind: "arg",
                path: "id"
              }
            ]
          }
        },
        {
          name: "mint"
        },
        {
          name: "stakeAccountAta",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "account",
                path: "stakeAccount"
              },
              {
                kind: "const",
                value: [
                  6, 221, 246, 225, 215, 101, 161, 147, 217, 203, 225, 70, 206, 235, 121, 172, 28,
                  180, 133, 237, 95, 91, 55, 145, 58, 140, 245, 133, 126, 255, 0, 169
                ]
              },
              {
                kind: "account",
                path: "mint"
              }
            ],
            program: {
              kind: "const",
              value: [
                140, 151, 37, 143, 78, 36, 137, 241, 187, 61, 16, 41, 20, 142, 13, 131, 11, 90, 19,
                153, 218, 255, 16, 132, 4, 142, 123, 216, 219, 233, 248, 89
              ]
            }
          }
        },
        {
          name: "stakeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [115, 116, 97, 107, 101]
              },
              {
                kind: "account",
                path: "nodeAccount"
              },
              {
                kind: "arg",
                path: "deviceOwner"
              }
            ]
          }
        },
        {
          name: "associatedTokenProgram",
          address: "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
        },
        {
          name: "tokenProgram",
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
        },
        {
          name: "systemProgram",
          address: "11111111111111111111111111111111"
        }
      ],
      args: [
        {
          name: "id",
          type: "string"
        },
        {
          name: "deviceOwner",
          type: "pubkey"
        },
        {
          name: "cooldown",
          type: {
            option: "u64"
          }
        }
      ]
    },
    {
      name: "slash",
      discriminator: [204, 141, 18, 161, 8, 177, 92, 142],
      accounts: [
        {
          name: "signer",
          signer: true
        },
        {
          name: "governorAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [103, 111, 118, 101, 114, 110, 111, 114]
              }
            ]
          }
        },
        {
          name: "nodeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [110, 111, 100, 101]
              },
              {
                kind: "account",
                path: "node_account.id",
                account: "nodeAccount"
              }
            ]
          }
        },
        {
          name: "stakeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [115, 116, 97, 107, 101]
              },
              {
                kind: "account",
                path: "stake_account.node",
                account: "stakeAccount"
              },
              {
                kind: "account",
                path: "stake_account.authority",
                account: "stakeAccount"
              }
            ]
          }
        },
        {
          name: "costakerAccount",
          writable: true,
          optional: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [115, 116, 97, 107, 101]
              },
              {
                kind: "account",
                path: "costaker_account.node",
                account: "stakeAccount"
              },
              {
                kind: "account",
                path: "costaker_account.authority",
                account: "stakeAccount"
              }
            ]
          }
        },
        {
          name: "stakeAccountAta",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "account",
                path: "stakeAccount"
              },
              {
                kind: "const",
                value: [
                  6, 221, 246, 225, 215, 101, 161, 147, 217, 203, 225, 70, 206, 235, 121, 172, 28,
                  180, 133, 237, 95, 91, 55, 145, 58, 140, 245, 133, 126, 255, 0, 169
                ]
              },
              {
                kind: "account",
                path: "mint"
              }
            ],
            program: {
              kind: "const",
              value: [
                140, 151, 37, 143, 78, 36, 137, 241, 187, 61, 16, 41, 20, 142, 13, 131, 11, 90, 19,
                153, 218, 255, 16, 132, 4, 142, 123, 216, 219, 233, 248, 89
              ]
            }
          }
        },
        {
          name: "costakerAccountAta",
          writable: true,
          optional: true,
          pda: {
            seeds: [
              {
                kind: "account",
                path: "costakerAccount"
              },
              {
                kind: "const",
                value: [
                  6, 221, 246, 225, 215, 101, 161, 147, 217, 203, 225, 70, 206, 235, 121, 172, 28,
                  180, 133, 237, 95, 91, 55, 145, 58, 140, 245, 133, 126, 255, 0, 169
                ]
              },
              {
                kind: "account",
                path: "mint"
              }
            ],
            program: {
              kind: "const",
              value: [
                140, 151, 37, 143, 78, 36, 137, 241, 187, 61, 16, 41, 20, 142, 13, 131, 11, 90, 19,
                153, 218, 255, 16, 132, 4, 142, 123, 216, 219, 233, 248, 89
              ]
            }
          }
        },
        {
          name: "mint"
        },
        {
          name: "governorAccountAta",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "account",
                path: "governorAccount"
              },
              {
                kind: "const",
                value: [
                  6, 221, 246, 225, 215, 101, 161, 147, 217, 203, 225, 70, 206, 235, 121, 172, 28,
                  180, 133, 237, 95, 91, 55, 145, 58, 140, 245, 133, 126, 255, 0, 169
                ]
              },
              {
                kind: "account",
                path: "mint"
              }
            ],
            program: {
              kind: "const",
              value: [
                140, 151, 37, 143, 78, 36, 137, 241, 187, 61, 16, 41, 20, 142, 13, 131, 11, 90, 19,
                153, 218, 255, 16, 132, 4, 142, 123, 216, 219, 233, 248, 89
              ]
            }
          }
        },
        {
          name: "associatedTokenProgram",
          address: "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
        },
        {
          name: "tokenProgram",
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
        },
        {
          name: "systemProgram",
          address: "11111111111111111111111111111111"
        }
      ],
      args: [
        {
          name: "removeNodeAmount",
          type: "u64"
        },
        {
          name: "removeCostakerAmount",
          type: {
            option: "u64"
          }
        }
      ]
    },
    {
      name: "unstake",
      discriminator: [90, 95, 107, 42, 205, 124, 50, 225],
      accounts: [
        {
          name: "signer",
          signer: true
        },
        {
          name: "governorActiveSigner",
          writable: true,
          signer: true
        },
        {
          name: "governorAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [103, 111, 118, 101, 114, 110, 111, 114]
              }
            ]
          }
        },
        {
          name: "nodeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [110, 111, 100, 101]
              },
              {
                kind: "account",
                path: "node_account.id",
                account: "nodeAccount"
              }
            ]
          }
        },
        {
          name: "stakeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [115, 116, 97, 107, 101]
              },
              {
                kind: "account",
                path: "stake_account.node",
                account: "stakeAccount"
              },
              {
                kind: "account",
                path: "stake_account.authority",
                account: "stakeAccount"
              }
            ]
          }
        },
        {
          name: "mint"
        },
        {
          name: "signerAta",
          writable: true
        },
        {
          name: "stakeAccountAta",
          writable: true
        },
        {
          name: "costakeAccount",
          writable: true,
          optional: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [115, 116, 97, 107, 101]
              },
              {
                kind: "account",
                path: "costake_account.node",
                account: "stakeAccount"
              },
              {
                kind: "account",
                path: "costake_account.authority",
                account: "stakeAccount"
              }
            ]
          }
        },
        {
          name: "costakeAccountAta",
          optional: true,
          pda: {
            seeds: [
              {
                kind: "account",
                path: "costakeAccount"
              },
              {
                kind: "const",
                value: [
                  6, 221, 246, 225, 215, 101, 161, 147, 217, 203, 225, 70, 206, 235, 121, 172, 28,
                  180, 133, 237, 95, 91, 55, 145, 58, 140, 245, 133, 126, 255, 0, 169
                ]
              },
              {
                kind: "account",
                path: "mint"
              }
            ],
            program: {
              kind: "const",
              value: [
                140, 151, 37, 143, 78, 36, 137, 241, 187, 61, 16, 41, 20, 142, 13, 131, 11, 90, 19,
                153, 218, 255, 16, 132, 4, 142, 123, 216, 219, 233, 248, 89
              ]
            }
          }
        },
        {
          name: "tokenProgram",
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
        },
        {
          name: "systemProgram",
          address: "11111111111111111111111111111111"
        }
      ],
      args: [
        {
          name: "amount",
          type: "u64"
        }
      ]
    },
    {
      name: "unstakeCostaker",
      discriminator: [200, 220, 154, 176, 205, 66, 127, 52],
      accounts: [
        {
          name: "signer",
          signer: true
        },
        {
          name: "governorActiveSigner",
          writable: true,
          signer: true
        },
        {
          name: "governorAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [103, 111, 118, 101, 114, 110, 111, 114]
              }
            ]
          }
        },
        {
          name: "nodeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [110, 111, 100, 101]
              },
              {
                kind: "account",
                path: "node_account.id",
                account: "nodeAccount"
              }
            ]
          }
        },
        {
          name: "stakeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [115, 116, 97, 107, 101]
              },
              {
                kind: "account",
                path: "stake_account.node",
                account: "stakeAccount"
              },
              {
                kind: "account",
                path: "stake_account.authority",
                account: "stakeAccount"
              }
            ]
          }
        },
        {
          name: "mint"
        },
        {
          name: "signerAta",
          writable: true
        },
        {
          name: "stakeAccountAta",
          writable: true
        },
        {
          name: "tokenProgram",
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
        },
        {
          name: "systemProgram",
          address: "11111111111111111111111111111111"
        }
      ],
      args: []
    },
    {
      name: "updateGovernorAuthority",
      discriminator: [133, 131, 38, 220, 178, 56, 139, 197],
      accounts: [
        {
          name: "signer",
          signer: true
        },
        {
          name: "governorAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [103, 111, 118, 101, 114, 110, 111, 114]
              }
            ]
          }
        },
        {
          name: "systemProgram",
          address: "11111111111111111111111111111111"
        }
      ],
      args: [
        {
          name: "newAuthority",
          type: "pubkey"
        }
      ]
    },
    {
      name: "updateGovernorSigner",
      discriminator: [23, 102, 136, 42, 175, 18, 28, 4],
      accounts: [
        {
          name: "signer",
          signer: true
        },
        {
          name: "governorAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [103, 111, 118, 101, 114, 110, 111, 114]
              }
            ]
          }
        },
        {
          name: "systemProgram",
          address: "11111111111111111111111111111111"
        }
      ],
      args: [
        {
          name: "newActiveSigner",
          type: "pubkey"
        }
      ]
    },
    {
      name: "updateMinStake",
      discriminator: [209, 210, 237, 163, 84, 150, 199, 196],
      accounts: [
        {
          name: "signer",
          signer: true
        },
        {
          name: "nodeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [110, 111, 100, 101]
              },
              {
                kind: "account",
                path: "node_account.id",
                account: "nodeAccount"
              }
            ]
          }
        },
        {
          name: "governorAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [103, 111, 118, 101, 114, 110, 111, 114]
              }
            ]
          }
        },
        {
          name: "systemProgram",
          address: "11111111111111111111111111111111"
        }
      ],
      args: [
        {
          name: "minStake",
          type: "u64"
        }
      ]
    },
    {
      name: "updateNodeCommission",
      discriminator: [190, 99, 227, 118, 93, 94, 237, 15],
      accounts: [
        {
          name: "signer",
          signer: true
        },
        {
          name: "nodeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [110, 111, 100, 101]
              },
              {
                kind: "account",
                path: "node_account.id",
                account: "nodeAccount"
              }
            ]
          }
        },
        {
          name: "systemProgram",
          address: "11111111111111111111111111111111"
        }
      ],
      args: [
        {
          name: "newCommission",
          type: "u64"
        }
      ]
    },
    {
      name: "updateNodeStakeContributionSplit",
      discriminator: [19, 103, 246, 52, 81, 157, 154, 39],
      accounts: [
        {
          name: "signer",
          signer: true
        },
        {
          name: "nodeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [110, 111, 100, 101]
              },
              {
                kind: "account",
                path: "node_account.id",
                account: "nodeAccount"
              }
            ]
          }
        },
        {
          name: "systemProgram",
          address: "11111111111111111111111111111111"
        }
      ],
      args: [
        {
          name: "updatedSplit",
          type: "u64"
        }
      ]
    },
    {
      name: "withdraw",
      discriminator: [183, 18, 70, 156, 148, 109, 161, 34],
      accounts: [
        {
          name: "signer",
          signer: true
        },
        {
          name: "governorActiveSigner",
          writable: true,
          signer: true
        },
        {
          name: "governorAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [103, 111, 118, 101, 114, 110, 111, 114]
              }
            ]
          }
        },
        {
          name: "stakeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [115, 116, 97, 107, 101]
              },
              {
                kind: "account",
                path: "stake_account.node",
                account: "stakeAccount"
              },
              {
                kind: "account",
                path: "stake_account.authority",
                account: "stakeAccount"
              }
            ]
          }
        },
        {
          name: "nodeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [110, 111, 100, 101]
              },
              {
                kind: "account",
                path: "node_account.id",
                account: "nodeAccount"
              }
            ]
          }
        },
        {
          name: "mint"
        },
        {
          name: "stakeAccountAta",
          writable: true
        },
        {
          name: "signerAta",
          writable: true
        },
        {
          name: "costakeAccount",
          writable: true,
          optional: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [115, 116, 97, 107, 101]
              },
              {
                kind: "account",
                path: "costake_account.node",
                account: "stakeAccount"
              },
              {
                kind: "account",
                path: "costake_account.authority",
                account: "stakeAccount"
              }
            ]
          }
        },
        {
          name: "costakeAccountAta",
          writable: true,
          optional: true,
          pda: {
            seeds: [
              {
                kind: "account",
                path: "costakeAccount"
              },
              {
                kind: "const",
                value: [
                  6, 221, 246, 225, 215, 101, 161, 147, 217, 203, 225, 70, 206, 235, 121, 172, 28,
                  180, 133, 237, 95, 91, 55, 145, 58, 140, 245, 133, 126, 255, 0, 169
                ]
              },
              {
                kind: "account",
                path: "mint"
              }
            ],
            program: {
              kind: "const",
              value: [
                140, 151, 37, 143, 78, 36, 137, 241, 187, 61, 16, 41, 20, 142, 13, 131, 11, 90, 19,
                153, 218, 255, 16, 132, 4, 142, 123, 216, 219, 233, 248, 89
              ]
            }
          }
        },
        {
          name: "costakeAuthorityAta",
          writable: true,
          optional: true,
          pda: {
            seeds: [
              {
                kind: "account",
                path: "costakeAccount"
              },
              {
                kind: "const",
                value: [
                  6, 221, 246, 225, 215, 101, 161, 147, 217, 203, 225, 70, 206, 235, 121, 172, 28,
                  180, 133, 237, 95, 91, 55, 145, 58, 140, 245, 133, 126, 255, 0, 169
                ]
              },
              {
                kind: "account",
                path: "mint"
              }
            ],
            program: {
              kind: "const",
              value: [
                140, 151, 37, 143, 78, 36, 137, 241, 187, 61, 16, 41, 20, 142, 13, 131, 11, 90, 19,
                153, 218, 255, 16, 132, 4, 142, 123, 216, 219, 233, 248, 89
              ]
            }
          }
        },
        {
          name: "tokenProgram",
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
        },
        {
          name: "systemProgram",
          address: "11111111111111111111111111111111"
        }
      ],
      args: []
    },
    {
      name: "withdrawCostaker",
      discriminator: [112, 42, 251, 125, 159, 100, 232, 124],
      accounts: [
        {
          name: "signer",
          signer: true
        },
        {
          name: "governorActiveSigner",
          writable: true,
          signer: true
        },
        {
          name: "governorAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [103, 111, 118, 101, 114, 110, 111, 114]
              }
            ]
          }
        },
        {
          name: "costakeAccount",
          writable: true,
          pda: {
            seeds: [
              {
                kind: "const",
                value: [115, 116, 97, 107, 101]
              },
              {
                kind: "account",
                path: "costake_account.node",
                account: "stakeAccount"
              },
              {
                kind: "account",
                path: "costake_account.authority",
                account: "stakeAccount"
              }
            ]
          }
        },
        {
          name: "mint"
        },
        {
          name: "costakeAccountAta",
          writable: true
        },
        {
          name: "signerAta",
          writable: true
        },
        {
          name: "tokenProgram",
          address: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
        },
        {
          name: "systemProgram",
          address: "11111111111111111111111111111111"
        }
      ],
      args: []
    }
  ],
  accounts: [
    {
      name: "governorAccount",
      discriminator: [72, 243, 244, 220, 100, 83, 111, 55]
    },
    {
      name: "nodeAccount",
      discriminator: [125, 166, 18, 146, 195, 127, 86, 220]
    },
    {
      name: "stakeAccount",
      discriminator: [80, 158, 67, 124, 50, 189, 192, 255]
    }
  ],
  events: [
    {
      name: "addStakeEvent",
      discriminator: [156, 25, 90, 186, 60, 65, 57, 58]
    },
    {
      name: "createCoStakerEvent",
      discriminator: [242, 161, 149, 177, 49, 35, 5, 177]
    },
    {
      name: "unstakeCoStakerEvent",
      discriminator: [107, 24, 32, 98, 180, 99, 52, 212]
    },
    {
      name: "unstakeEvent",
      discriminator: [162, 104, 137, 228, 81, 3, 79, 197]
    },
    {
      name: "withdrawCoStakerEvent",
      discriminator: [108, 43, 45, 205, 177, 196, 218, 149]
    },
    {
      name: "withdrawEvent",
      discriminator: [22, 9, 133, 26, 160, 44, 71, 192]
    }
  ],
  errors: [
    {
      code: 6000,
      name: "maxSplitNodeAccount",
      msg: "Error: Max Split is 10,000 bps"
    },
    {
      code: 6001,
      name: "minSplitNodeAccount",
      msg: "Error: Min Split is 5000 bps"
    },
    {
      code: 6002,
      name: "maxCommissionNodeAccount",
      msg: "Error: Max Commission is 10,000 bps"
    },
    {
      code: 6003,
      name: "minCommissionNodeAccount",
      msg: "Error: Min Commission is 0 bps"
    },
    {
      code: 6004,
      name: "coStakingDisabled",
      msg: "Error: Co Staking is disabled by the node"
    },
    {
      code: 6005,
      name: "nodeAlreadyStaked",
      msg: "Error: Node Already has a Co staker"
    },
    {
      code: 6006,
      name: "invalidAmount",
      msg: "Error: Invalid Amount"
    },
    {
      code: 6007,
      name: "invalidCooldownPeriod",
      msg: "Error: Cooldown Period Not Set"
    },
    {
      code: 6008,
      name: "missingCoStaker",
      msg: "Error: Missing Co-Staker"
    },
    {
      code: 6009,
      name: "unstakeStarted",
      msg: "Error: Unstake has started"
    },
    {
      code: 6010,
      name: "missingUnStakeCoStaker",
      msg: "Error: Missing Unstake Co-Staker"
    },
    {
      code: 6011,
      name: "coStakerAlreadyExist",
      msg: "Error: Missing Unstake Co-Staker"
    },
    {
      code: 6012,
      name: "arithmeticError",
      msg: "Error: Arithmetic Error"
    }
  ],
  types: [
    {
      name: "addStakeEvent",
      type: {
        kind: "struct",
        fields: [
          {
            name: "amount",
            type: "u64"
          },
          {
            name: "nodeId",
            type: "string"
          }
        ]
      }
    },
    {
      name: "createCoStakerEvent",
      type: {
        kind: "struct",
        fields: [
          {
            name: "nodeId",
            type: "string"
          },
          {
            name: "coStakerAuthority",
            type: "pubkey"
          }
        ]
      }
    },
    {
      name: "governorAccount",
      repr: {
        kind: "c"
      },
      type: {
        kind: "struct",
        fields: [
          {
            name: "authority",
            type: "pubkey"
          },
          {
            name: "activeSigner",
            type: "pubkey"
          },
          {
            name: "bump",
            type: "u8"
          },
          {
            name: "padding",
            type: {
              array: ["u128", 8]
            }
          }
        ]
      }
    },
    {
      name: "nodeAccount",
      type: {
        kind: "struct",
        fields: [
          {
            name: "id",
            type: "string"
          },
          {
            name: "authority",
            type: "pubkey"
          },
          {
            name: "config",
            type: {
              defined: {
                name: "nodeConfig"
              }
            }
          },
          {
            name: "nodeStakeAccount",
            type: "pubkey"
          },
          {
            name: "costakerStakeAccount",
            type: {
              option: "pubkey"
            }
          },
          {
            name: "coStakingEnabled",
            type: "bool"
          },
          {
            name: "bump",
            type: "u8"
          },
          {
            name: "unstakeStarted",
            type: "bool"
          },
          {
            name: "minStakeCheckExemption",
            type: {
              option: "u64"
            }
          },
          {
            name: "padding",
            type: {
              array: ["u128", 8]
            }
          }
        ]
      }
    },
    {
      name: "nodeConfig",
      type: {
        kind: "struct",
        fields: [
          {
            name: "brCommission",
            type: "u64"
          },
          {
            name: "minStake",
            type: "u64"
          },
          {
            name: "coStakingSplit",
            type: "u64"
          }
        ]
      }
    },
    {
      name: "stakeAccount",
      type: {
        kind: "struct",
        fields: [
          {
            name: "node",
            type: "pubkey"
          },
          {
            name: "authority",
            type: "pubkey"
          },
          {
            name: "cooldownTime",
            type: {
              option: "u64"
            }
          },
          {
            name: "noticeTime",
            type: {
              option: "u64"
            }
          },
          {
            name: "isCostaker",
            type: "bool"
          },
          {
            name: "bump",
            type: "u8"
          },
          {
            name: "padding",
            type: {
              array: ["u128", 8]
            }
          }
        ]
      }
    },
    {
      name: "unstakeCoStakerEvent",
      type: {
        kind: "struct",
        fields: [
          {
            name: "authority",
            type: "pubkey"
          },
          {
            name: "nodeId",
            type: "string"
          }
        ]
      }
    },
    {
      name: "unstakeEvent",
      type: {
        kind: "struct",
        fields: [
          {
            name: "authority",
            type: "pubkey"
          },
          {
            name: "nodeId",
            type: "string"
          }
        ]
      }
    },
    {
      name: "withdrawCoStakerEvent",
      type: {
        kind: "struct",
        fields: [
          {
            name: "coStaker",
            type: "pubkey"
          },
          {
            name: "node",
            type: "pubkey"
          }
        ]
      }
    },
    {
      name: "withdrawEvent",
      type: {
        kind: "struct",
        fields: [
          {
            name: "authority",
            type: "pubkey"
          },
          {
            name: "coStaker",
            type: {
              option: "pubkey"
            }
          },
          {
            name: "nodeId",
            type: "string"
          }
        ]
      }
    }
  ]
};
