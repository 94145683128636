import env from "@/env";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { PublicKey, Keypair } from "@solana/web3.js";
import { IDLV2 } from "./programIdlV2";
import CryptoJS from "crypto-js";

export const PROGRAM_ID = env.STAKING_CONTRACT_ADDRESS;
export const NEW_PROGRAM_ID = IDLV2.address.toString();
export const SUPER_ADMIN_PUBKEY = env.STAKING_SUPER_ADMIN_PUBLIC_KEY;
export const SUPER_ADMIN_PUBKEY_ACTIVE = env.STAKING_SUPER_ADMIN_PUBLIC_KEY_ACTIVE;
export const LAMPORTS_PER_SOL_IO_TOKEN = env.STAKING_NETWORK === "devnet" ? 100000000 : 100000000;

export const convertToLamport = (amount: string) => {
  const numberAmount = Number(amount);
  return (numberAmount * LAMPORTS_PER_SOL_IO_TOKEN).toString();
};

export const convertLamportBack = (amount: string | number) => {
  const numberAmount = Number(amount);
  return (numberAmount / LAMPORTS_PER_SOL_IO_TOKEN).toString();
};

export const convertFromLamportsToNumber = (amount: string | number) => {
  const numberAmount = Number(amount);
  return (numberAmount / LAMPORTS_PER_SOL_IO_TOKEN) as number;
};

export const findMerkleRootStateAccount = async (): Promise<PublicKey> => {
  const programId = new PublicKey(PROGRAM_ID);
  const seeds = [Buffer.from("merkle-root")];
  const [key] = PublicKey.findProgramAddressSync(seeds, programId);
  return key;
};

export const findGlobalVaultTokenAccount = (): PublicKey => {
  const programId = new PublicKey(PROGRAM_ID);
  const seeds = [Buffer.from("global-vault")];
  const [key] = PublicKey.findProgramAddressSync(seeds, programId);
  return key;
};

export const findGlobalAccount = (): PublicKey => {
  const programId = new PublicKey(PROGRAM_ID);
  const seeds = [Buffer.from("global-owner")];
  const [key] = PublicKey.findProgramAddressSync(seeds, programId);
  return key;
};

export const findPoolInfoAccount = (device_uuid: string): PublicKey => {
  const programId = new PublicKey(PROGRAM_ID);

  const hashedDeviceUUID = CryptoJS.SHA256(device_uuid);

  const hashedDeviceUUIDBytes = CryptoJS.enc.Hex.parse(
    hashedDeviceUUID.toString(CryptoJS.enc.Hex)
  ).words.slice(0, 8);

  const buffer = new ArrayBuffer(32);
  const view = new DataView(buffer);

  for (let i = 0; i < hashedDeviceUUIDBytes.length; i++) {
    view.setUint32(i * 4, hashedDeviceUUIDBytes[i], false);
  }

  const hashedDeviceUUIDBuffer = Buffer.from(buffer);

  const seeds = [Buffer.from("pool-info"), Buffer.from(hashedDeviceUUIDBuffer)];

  const [key] = PublicKey.findProgramAddressSync(seeds, programId);
  return key;
};

export const findStakeInfoAccount = (pool: PublicKey, user: PublicKey): PublicKey => {
  const programId = new PublicKey(PROGRAM_ID);
  const seeds = [Buffer.from("stake-info"), pool.toBuffer(), user.toBuffer()];
  const [key] = PublicKey.findProgramAddressSync(seeds, programId);
  return key;
};

export const findAccount = async (seeds: Buffer[]): Promise<PublicKey> => {
  const programId = new PublicKey(PROGRAM_ID);

  const [key] = PublicKey.findProgramAddressSync(seeds, programId);
  return key;
};

export const getUserAssociatedTokenAccount = async (wallet: PublicKey, mint: PublicKey) => {
  const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new PublicKey(
    "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
  );

  const seeds = [wallet.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()];

  return PublicKey.findProgramAddressSync(seeds, SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID)[0];
};

//new for co-stake

export function uuidToOnchainId(uuid: string): string {
  // Removes dashes from UUID to create a 32-character on-chain ID
  return uuid.replace(/-/g, "");
}

export function onchainIdToUuid(onchainId: string): string {
  // Inserts dashes back to the 32-character on-chain ID to format as a UUID
  return `${onchainId.slice(0, 8)}-${onchainId.slice(8, 12)}-${onchainId.slice(
    12,
    16
  )}-${onchainId.slice(16, 20)}-${onchainId.slice(20)}`;
}

// export const findNodeAccount = (device_uuid: string) => {
//   const programId = new PublicKey(NEW_PROGRAM_ID);

//   const seeds = [Buffer.from("node"), Buffer.from(uuidToOnchainId(device_uuid))];
//   const [key] = PublicKey.findProgramAddressSync(seeds, programId);
//   return key;
// };

// export const findStakeAccount = (create_key: PublicKey) => {
//   const programId = new PublicKey(NEW_PROGRAM_ID);
//   const [stakeAccount] = PublicKey.findProgramAddressSync(
//     [Buffer.from("stake"), create_key.toBuffer()],
//     programId
//   );

//   return stakeAccount;
// };

export const findStakeAccount = (node: PublicKey, authority: PublicKey) => {
  const programId = new PublicKey(NEW_PROGRAM_ID);
  const [pda] = PublicKey.findProgramAddressSync(
    [Buffer.from("stake"), node.toBuffer(), authority.toBuffer()],
    programId
  );

  return pda;
};

export const findNodeAccount = (id: string) => {
  const programId = new PublicKey(NEW_PROGRAM_ID);
  const [pda] = PublicKey.findProgramAddressSync(
    [Buffer.from("node"), Buffer.from(uuidToOnchainId(id))],
    programId
  );

  return pda;
};

// export const getNodeKey = () => {
//   return Keypair.generate();
// };

export const generateKeypairFromSeed = (seed: string) => {
  const bytes1 = new TextEncoder().encode(seed);

  const seedUint = new Uint8Array([...bytes1]);
  const finalSeeds = new Uint8Array(32);
  finalSeeds.set(seedUint.subarray(0, 32));
  return Keypair.fromSeed(finalSeeds);
};
