import { NotificationItem } from "../UserNotificationsButton/NotificationItem";
import React, { useEffect } from "react";
import { usePaginatedUserNotificationStore } from "@/store/paginated";
import { DataPagination } from "../DataPagination/DataPagination";
import { useUserStore } from "@/store/user";
import { UserNotification } from "@/types";
import { AsyncSuspense } from "../AsyncSuspense/AsyncSuspense";
import { NotificationFilterType, NotificationType } from "@/types/user";
import { useToast } from "@/components/ui/use-toast";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import isToday from "dayjs/plugin/isToday";
import { IoIcon } from "@/components/Icons/IoIcon";

dayjs.extend(relativeTime);
dayjs.extend(isToday);

type UserNotificationResultsProps = {
  priority: NotificationType | "all";
  type: NotificationFilterType;
  isFull: boolean;
  setIsFull: (value: boolean) => void;
};

export const UserNotificationResults: React.FC<UserNotificationResultsProps> = ({
  type,
  priority,
  isFull,
  setIsFull
}) => {
  const notifications = usePaginatedUserNotificationStore(
    (state) => state.results as UserNotification[] | undefined
  );
  const setResultsRequestOptions = usePaginatedUserNotificationStore(
    (state) => state.setResultsRequestOptions
  );
  const page = usePaginatedUserNotificationStore((state) => state.page);
  const setPage = usePaginatedUserNotificationStore((state) => state.setPage);
  const setLimit = usePaginatedUserNotificationStore((state) => state.setLimit);
  const pageCount = usePaginatedUserNotificationStore((state) => state.pageCount);
  const isLoading = usePaginatedUserNotificationStore((state) => state.isLoading);
  const fetchResults = usePaginatedUserNotificationStore((state) => state.fetchResults);
  const fetchResultsError = usePaginatedUserNotificationStore((state) => state.fetchResultsError);
  const markAsRead = useUserStore((store) => store.markNotificationAsRead);
  const { toast } = useToast();

  const handleMarkAsRead = async (item: UserNotification) => {
    try {
      await markAsRead(item.id, !!item.isRead);
      await fetchResults();
    } catch (e) {
      toast({ variant: "error", title: e instanceof Error ? e.message : "Something went wrong" });
    }
  };

  useEffect(() => {
    return () => {
      setIsFull(false);
    };
  }, [setIsFull]);

  useEffect(() => {
    (async () => {
      setLimit(5);
      setResultsRequestOptions({
        seen: isFull ? undefined : false,
        priority: priority === "all" ? undefined : priority,
        type: type === "all" ? undefined : type
      });

      await fetchResults();
    })();
  }, [type, priority, fetchResults, setResultsRequestOptions, setLimit, isFull]);

  return (
    <div className="flex flex-col ">
      <AsyncSuspense isLoading={isLoading} error={fetchResultsError}>
        {notifications && notifications?.length > 0 ? (
          <>
            {notifications?.map((notification, index) => {
              const date = getDateLabel(notification.createdAt);
              return (
                <div key={notification.id}>
                  {date &&
                    (index === 0 || date != getDateLabel(notifications[index - 1]?.createdAt)) && (
                      <div
                        className={`subtext-small mb-2 ${index !== 0 && "mt-4"} text-gray-dark-1500`}
                      >
                        {date}
                      </div>
                    )}
                  <NotificationItem markAsRead={handleMarkAsRead} notification={notification} />
                </div>
              );
            })}
            {isFull && (
              <div className="my-8 h-[1px] bg-gray-dark-900">
                <IoIcon className="m-auto mt-[-5px] h-[10px] bg-black px-2 text-gray-dark-900" />
              </div>
            )}
            {isFull && (
              <DataPagination
                page={page}
                pageCount={pageCount}
                maxItems={4}
                onChange={async (newPage) => {
                  setPage(newPage);
                  await fetchResults();
                }}
              />
            )}
          </>
        ) : (
          <div className="subtext-x-small p-4 text-gray-dark-300">
            You currently have no notifications.
          </div>
        )}
      </AsyncSuspense>
    </div>
  );
};

const getDateLabel = (date: string) => {
  if (!date) {
    return null;
  }

  if (dayjs(date).isToday()) {
    return "Today";
  }

  return dayjs(date).endOf("day").fromNow();
};
