import { useCallback } from "react";
import env from "@/env";
import { useUserTimezone } from "@/hooks/useUserTimezone";
import { formatDateTime, formatDateTimeInTimeZone } from "@/utils/date";

export const useFormattedDateTime = () => {
  const userTimezone = useUserTimezone();

  return useCallback(
    (dateTime: unknown) => {
      return formattedDateTime(dateTime, userTimezone);
    },
    [userTimezone]
  );
};

export const formattedDateTime = (dateTime: unknown, userTimezone: string) => {
  return env.USER_TIMEZONE_ENABLED
    ? formatDateTimeInTimeZone(dateTime, userTimezone)
    : formatDateTime(dateTime);
};
