import { IconProps } from ".";

export const BC8WhiteIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 51 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.879 6L22.8429 9H30.2527L33.2167 12H21.6159L14.8196 5.121L13.9512 6L16.9151 9L13.9512 12H0V0H13.9512L17.7835 3.879L21.6159 0H33.2167L30.2518 3H22.8429L19.879 6ZM2.96392 7.5V9H12.7241L11.2422 7.5H2.96392ZM2.96392 4.5H11.2422L12.7241 3H2.96392V4.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.0361 0L51 3L48.0361 6L51 9L48.0361 12H34.9523L31.9884 9L34.9523 6L31.9884 3L34.9523 0H48.0361ZM37.6624 7.5L36.1804 9H46.808L45.3261 7.5H37.6624ZM37.6633 4.5H45.3271L46.809 3H36.1814L37.6633 4.5Z"
        fill="white"
      />
    </svg>
  );
};
export default BC8WhiteIcon;
