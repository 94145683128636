import { cn } from "@/utils/styles";
import { Tooltip } from "react-tooltip";
import type { PlacesType } from "react-tooltip";

type SimpleTooltipType = {
  children: React.ReactNode;
  content: React.ReactNode;
  id: string;
  className?: string;
  place?: PlacesType;
  tooltipClassName?: string;
};

export const SimpleTooltip = ({
  children,
  content,
  id,
  className,
  place,
  tooltipClassName = ""
}: SimpleTooltipType) => {
  return (
    <>
      <div
        data-tooltip-id={id}
        data-testid={id}
        data-tooltip-variant="dark"
        className={cn(content ? "cursor-pointer" : "", className)}
      >
        {children}
      </div>
      <Tooltip
        opacity={1}
        id={id}
        style={{ fontSize: "12px", backgroundColor: "#0A0A0A", zIndex: 999999999 }}
        className={cn("z-10 border max-sm:max-w-[300px]", tooltipClassName)}
        place={place}
        border={"1px solid #1F1F1F"}
      >
        {content}
      </Tooltip>
    </>
  );
};
