import { create } from "zustand";
import { executeAPIRequest } from "@/utils/api";
import {
  BlockRewardsStats,
  BlockRewardsStatsResponse,
  BlockReward,
  BlockRewardResponse,
  BlockRecord,
  BlockRecordResponse
} from "@/types/blockRewards";
import {
  PaginatedResponse,
  UsereBlockEarningsResponse,
  UsereBlockEarningsType,
  BlockDailyEarnings,
  UserLatestBlockRewardsResponse,
  UserLatestBlockRewards
} from "@/types";
import { CpuIcon } from "@/components/Icons/CpuIcon";
import { CONNECTIVITY_TIER_ICONS, HARDWARE_MAP } from "@/utils/mapping";
import { useUserStore } from "./user";

type UseBlockRewardsStoreProps = {
  fetchStats: () => Promise<BlockRewardsStats>;
  fetchResults: (options: { page?: number; status: string }) => Promise<{
    results: BlockReward[];
    resultCount: number;
  }>;
  fetchBlockDetail: (id: string) => Promise<BlockReward>;
  fetchBlockRecordResults: (options: {
    page?: number;
    status: string;
    blockId: string;
  }) => Promise<{
    results: BlockRecord[];
    resultCount: number;
  }>;
  fetchBlocksBySearchQuery: (options: { searchQuery?: string }) => Promise<
    {
      id: string;
      name: string;
      url: string;
    }[]
  >;
  fetchDeviceBlocksBySearchQuery: (options: {
    searchQuery?: string;
    blockId: string;
  }) => Promise<BlockRecord>;
  fetchUserBlockRewards: (options: {
    from_date: string;
    to_date: string;
  }) => Promise<UsereBlockEarningsType>;
  fetchUserLatestBlockRewards: () => Promise<UserLatestBlockRewards>;
};
export const useBlockRewardsStore = create<UseBlockRewardsStoreProps>(() => ({
  fetchStats: async () => {
    const response = await executeAPIRequest<BlockRewardsStatsResponse>({
      method: "get",
      url: `/io-blocks/blocks/summary`
    });

    return {
      totalDistributed: response.total_coins_distributed,
      todayDistributed: response.todays_coins_distributed,
      totalComputed: response.total_blocks_computed,
      totalWorkersPaid: response.total_unique_workers_paid,
      todayWorkersPaid: response.todays_unique_workers_paid,
      nextBlockTime: response.next_block_start_time
    };
  },
  fetchResults: async ({ page = 1, status }) => {
    const response = await executeAPIRequest<
      PaginatedResponse<{
        page: number;
        page_size: number;
        total_blocks: number;
        blocks: BlockRewardResponse[];
      }>
    >({
      method: "get",
      url: `/io-blocks/blocks/${status}/${page}`
    });

    const { blocks, total_blocks } = response;

    return {
      resultCount: total_blocks,
      results: blocks.map((item) => {
        return normalizeBlockDetail(item);
      })
    };
  },
  fetchBlockDetail: async (blockId) => {
    const response = await executeAPIRequest<BlockRewardResponse>({
      method: "get",
      url: `/io-blocks/blocks/${blockId}`
    });

    return normalizeBlockDetail(response);
  },
  fetchBlockRecordResults: async ({ page = 1, status, blockId }) => {
    const response = await executeAPIRequest<
      PaginatedResponse<{
        page: number;
        page_size: number;
        total_rewards: number;
        block_rewards: BlockRecordResponse[];
      }>
    >({
      method: "get",
      url: `/io-blocks/blocks/${blockId}/workers/${status}/${page}`
    });

    const { block_rewards, total_rewards } = response;

    return {
      resultCount: total_rewards,
      results: block_rewards.map((item) => {
        return normalizeBlockRecord(item);
      })
    };
  },
  fetchBlocksBySearchQuery: async ({ searchQuery = "" }) => {
    try {
      const response = await executeAPIRequest<BlockRewardResponse>({
        method: "get",
        url: `/io-blocks/blocks/${searchQuery}`
      });

      return (Array.isArray(response) ? response : response.block_id ? [response] : []).map(
        ({ block_id }) => {
          return {
            id: block_id,
            name: block_id,
            url: `/explorer/block-rewards/${block_id}`
          };
        }
      );
    } catch (e) {
      return [];
    }
  },
  fetchDeviceBlocksBySearchQuery: async ({ searchQuery = "", blockId }) => {
    const response = await executeAPIRequest<BlockRecordResponse>({
      method: "get",
      url: `/io-blocks/blocks/${blockId}/workers/${searchQuery}`
    });
    return normalizeBlockRecord(response);
  },
  fetchUserBlockRewards: async ({ from_date, to_date }) => {
    const response = await executeAPIRequest<UsereBlockEarningsResponse>({
      method: "get",
      url: `/io-blocks/users/block-rewards-summary/${from_date}/${to_date}`
    });

    return normaliseUserBlockRewards(response);
  },
  fetchUserLatestBlockRewards: async () => {
    const userId = useUserStore.getState().userId;

    const response = await executeAPIRequest<UserLatestBlockRewardsResponse>({
      method: "get",
      url: `/io-blocks/users/${userId}/latest-block-rewards`
    });

    return normaliseUserLatestBlockRewards(response);
  }
}));

const normaliseUserLatestBlockRewards = (response: UserLatestBlockRewardsResponse) => {
  return {
    sumRewardedForUser: response.sum_rewarded_for_user
  } as UserLatestBlockRewards;
};

export const normalizeBlockDetail = (item: BlockRewardResponse) => {
  return {
    status: item.status,
    timeAndDate: item.time_and_date,
    id: item.block_id,
    totalRewards: item.total_block_emission,
    nominatedWorkers: item.nominated_workers,
    succeededWorkers: item.succeeded,
    failedWorkers: item.failed,
    numCpuWorkers: item.num_cpu_workers,
    numGpuWorkers: item.num_gpu_workers,
    safetyModuleEmission: item.safety_module_emission,
    workerEmission: item.worker_emission,
    href:
      item.status.toLowerCase() === "completed"
        ? `/explorer/block-rewards/${item.block_id}`
        : undefined
  };
};

export const normalizeBlockRecord = (record: BlockRecordResponse) => {
  return {
    isBaremetal: record.is_baremetal,
    isKubernetes: record.is_k8s,
    status: record.status,
    timeAndDate: record.time_and_date,
    blockId: record.block_id,
    deviceId: record.device_id,
    processorQuantity: record.processor_quantity,
    processor: record.processor,
    powStatus: record.pow,
    potlStatus: record.potl,
    uptimeInMinutes: record.uptime_in_minutes,
    totalScore: record.total_score,
    normalizedScore: record.normalized_score,
    rewarded: record.rewarded,
    txidUrl: record.txid_url,
    potl: record.potl_success_list,
    pow: record.pow_success_list,
    brand: getBrandByProcessorName(record.brand_name),
    connectivityTier: CONNECTIVITY_TIER_ICONS.find(
      (item) => item.label.toLowerCase() === record.connectivity_tier.toLowerCase()
    )
  };
};

const getBrandByProcessorName = (name: string) => {
  const lower = name.toLowerCase();
  if (lower.includes("nvidia")) {
    return HARDWARE_MAP.nvidia;
  }
  if (lower.includes("amd")) {
    return HARDWARE_MAP.amd;
  }
  if (lower.includes("apple")) {
    return HARDWARE_MAP.apple;
  }
  return {
    hardwareManufacturerColor: "text-gray",
    hardwareManufacturerIcon: CpuIcon,
    hardwareManufacturer: ""
  };
};

const normaliseUserBlockRewards = (data: UsereBlockEarningsResponse) => {
  return {
    totalBlockRewards: data.total_block_rewards,
    totalBlocksEarned: data.total_blocks_earned,
    totalBlocksFailed: data.total_blocks_failed,
    totalBlocksMissed: data.total_blocks_missed,
    datewiseEarningsSummary: data.datewise_earnings_summary.map((reward) => {
      return {
        rewards: reward.rewards,
        dateWithYear: reward.date_with_year
      } as BlockDailyEarnings;
    })
  } as UsereBlockEarningsType;
};
