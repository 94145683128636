import { IconProps } from ".";

export const CloudIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 64 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.108 9C1.548 9 0.672 8.112 0.672 6.552V3.048C0.672 1.488 1.548 0.599999 3.108 0.599999H10.332C10.524 0.599999 10.62 0.696 10.62 0.887999V1.44C10.62 1.632 10.524 1.74 10.332 1.74H3.42C2.472 1.74 1.956 2.244 1.956 3.192V6.408C1.956 7.356 2.472 7.86 3.42 7.86H10.332C10.524 7.86 10.62 7.968 10.62 8.16V8.712C10.62 8.904 10.524 9 10.332 9H3.108ZM14.9254 6.408C14.9254 7.356 15.4294 7.86 16.3774 7.86H23.1094C23.3014 7.86 23.3974 7.968 23.3974 8.16V8.712C23.3974 8.904 23.3014 9 23.1094 9H16.0774C14.5054 9 13.6294 8.112 13.6294 6.552V0.887999C13.6294 0.696 13.7374 0.599999 13.9294 0.599999H14.6374C14.8294 0.599999 14.9254 0.696 14.9254 0.887999V6.408ZM28.54 9C26.98 9 26.104 8.112 26.104 6.552V3.048C26.104 1.488 26.98 0.599999 28.54 0.599999H34.264C35.836 0.599999 36.712 1.488 36.712 3.048V6.552C36.712 8.112 35.836 9 34.264 9H28.54ZM33.964 7.86C34.912 7.86 35.416 7.356 35.416 6.408V3.192C35.416 2.244 34.912 1.74 33.964 1.74H28.852C27.904 1.74 27.388 2.244 27.388 3.192V6.408C27.388 7.356 27.904 7.86 28.852 7.86H33.964ZM50.2372 6.552C50.2372 8.112 49.3612 9 47.8012 9H42.3532C40.7812 9 39.9052 8.112 39.9052 6.552V0.887999C39.9052 0.696 40.0132 0.599999 40.2052 0.599999H40.9132C41.1052 0.599999 41.2012 0.696 41.2012 0.887999V6.408C41.2012 7.356 41.7052 7.86 42.6532 7.86H47.4892C48.4372 7.86 48.9412 7.356 48.9412 6.408V0.887999C48.9412 0.696 49.0492 0.599999 49.2412 0.599999H49.9492C50.1412 0.599999 50.2372 0.696 50.2372 0.887999V6.552ZM53.5425 0.887999C53.5425 0.696 53.6385 0.599999 53.8305 0.599999H61.0905C62.9265 0.599999 63.9105 1.62 63.9105 3.42V6.18C63.9105 7.98 62.9265 9 61.0905 9H53.8305C53.6385 9 53.5425 8.904 53.5425 8.712V0.887999ZM62.6145 6.048V3.552C62.6145 2.376 61.9785 1.74 60.7905 1.74H54.8385V7.86H60.7905C61.9785 7.86 62.6145 7.224 62.6145 6.048Z"
        fill="url(#paint0_linear_15661_5892)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15661_5892"
          x1="-4.7134"
          y1="1.14887"
          x2="45.7108"
          y2="1.14887"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A9AC" />
          <stop offset="0.0091653" stopColor="#AAACAF" />
          <stop offset="0.1095" stopColor="#C9CACC" />
          <stop offset="0.203" stopColor="#DFE0E1" />
          <stop offset="0.2859" stopColor="#ECEDEE" />
          <stop offset="0.35" stopColor="#F1F2F2" />
          <stop offset="0.4681" stopColor="#EAEBEC" />
          <stop offset="0.6561" stopColor="#D7D8DA" />
          <stop offset="0.8892" stopColor="#B8BABC" />
          <stop offset="1" stopColor="#A7A9AC" />
        </linearGradient>
      </defs>
    </svg>
  );
};
