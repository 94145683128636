import { IoIcon } from "@/components/Icons/IoIcon";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Tool } from "@/types";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";

type ToolLogoProps = {
  tool: Tool;
  isVisibleIOIcon?: boolean;
};

export const ToolLogo: React.FC<ToolLogoProps> = ({ tool, isVisibleIOIcon }) => {
  return (
    <>
      {tool.href.indexOf("://") > -1 && (
        <Button
          size="icon"
          variant={"outline"}
          className="absolute right-2 top-2 h-3 w-3 rounded-full text-gray-dark-1000 md:right-[15px] md:top-[15px] md:h-6 md:w-6"
        >
          <ArrowUpRightIcon className="h-[11px]" />
        </Button>
      )}
      {tool.logoImage && (
        <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-row items-center justify-center">
          <img className="h-[25px]" src={tool.logoImage} />
        </div>
      )}
      <div className="relative flex flex-row gap-2">
        {tool.logo && (
          <div className={`flex flex-row items-center gap-2`}>
            <IoIcon className="h-[12px] max-md:hidden" />
            <Separator orientation="vertical" className="max-md:hidden" />
            {!isVisibleIOIcon && (
              <tool.logo
                data-testid={"ToolLogo_logo"}
                className="h-[10px] w-[10px] min-w-[10px] md:h-3 md:w-3"
              />
            )}
          </div>
        )}
        {tool.icon && <tool.icon className="flex h-[8px] items-start md:h-3 md:max-w-max" />}
      </div>
    </>
  );
};
