import { NvidiaIcon } from "@/components/Icons/NvidiaIcon";
import { DeviceStatusType } from "@/utils/device";

export type MiddlewareUserStakedDeviceResponse = {
  id: number;
  stake_info_id: number;
  device_id: string;
  user_id: string;
  user_account: string;
  stake_amount: string;
  total_reward_amount: string;
  create_block_time: string;
  create_date: string;
  update_date: string;
  freeze_amount: string;
  freeze_end_time: string;
  withdrawable: string;
  status: string;
};

export type MiddlewareStakeEligibleDeviceResponse = {
  create_date: string;
  device_id: string;
  id: number;
  last_rewards_time: string | null;
  minimum_stake_amount: string;
  total_rewards: string;
  total_stake_account: string;
  total_stake_amount: string;
  update_date: string;
};

export type MiddlewareStakeEligibleDevice = {
  createDate: string;
  deviceId: string;
  deviceName: string;
  id: string;
  lastRewardsTime: string | null;
  minimumStakeAmount: number;
  totalRewards: number;
  totalStakeAccountCount: number;
  totalStakeAmount: number;
  updateDate: string;
};

export enum UserStakedDeviceStatusType {
  SUFFICIENT = "sufficient",
  INSUFFICIENT = "insufficient"
}

export enum StakeEventType {
  STAKED_DEVICE_UPDATED = "STAKED_DEVICE_UPDATED",
  STAKE_UPDATED = "STAKE_UPDATED"
}

export enum ProxyToMiddlewareEndpoint {
  STAKE = "STAKE",
  UNSTAKE = "UNSTAKE",
  WITHDRAW = "WITHDRAW",
  CREATECOSTAKE = "CREATECOSTAKE",
  TAKECOSTAKE = "TAKECOSTAKE",
  CANCELCOSTAKE = "CANCELCOSTAKE",
  UNSTAKECOSTAKER = "UNSTAKECOSTAKER",
  UNSTAKECOSTAKEALL = "UNSTAKECOSTAKEALL",
  WITHDRAWCOSTAKER = "WITHDRAWCOSTAKER",
  INFO = "INFO"
}

export type UserStakedDevicesRequestOptions = {
  page?: number;
  limit?: number;
  status?: string;
  deviceId?: string;
  hardwareName?: string;
};

export type UserStakedDeviceResult = {
  resultCount?: number;
  results: UserStakedDevice[];
  statuses: string[];
};

export type UserStakedDeviceResponse = {
  device_id: string;
  status: string;
  device_name: string;
  minimum_staked: number;
  hardware_quantity: number;
  hardware_name: string;
  brand_name: string;
  block_rewards: number;
  amount_staked: number;
  amount_frozen: number;
  amount_withdrawable: number;
  apr_estimate: number;
  freeze_end_time?: string;
  user_account?: string;
  co_stake_status?: string;
  earned_block_rewards: string;
  estimated_block_rewards: string;
  shared_block_rewards_percent: number;
  co_stake_amount: number;
  staking_allowed?: boolean;
  overflow_amount: number;
  net_amount_staked: number;
  co_stake_offer_id?: string;
};

export type UserStakedDevice = {
  id: string;
  stakeInfoId?: number;
  deviceId: string;
  deviceName?: string;
  minimumStaked: number;
  userId?: string;
  userAccount?: string;
  amountStaked: number;
  amountFrozen: number;
  amountWithdrawable: number;
  totalRewardAmount?: number;
  createBlockTime?: string;
  createDate?: string;
  updateDate?: string;
  freezeEndTime?: Date;
  status: UserStakedDeviceStatusType;
  blockRewards?: number;
  aprEstimate?: number;
  deviceStatus?: DeviceStatusType;
  hardwareQuantity: number;
  hardwareName: string;
  hardwareManufacturerColor: string;
  hardwareManufacturerIcon?: typeof NvidiaIcon;
  hardwareManufacturer: string;
  hasStakedMinimum: boolean;
  frozenTimeRemaining?: string;
  frozenTimeShortRemaining?: string;
  frozenTimeRemainingAsPercentage?: number;
  stakedWalletAddress?: string;
  estimatedBlockRewards: number;
  sharedBlockRewardsPercent: number;
  costakerEstimatedBlockRewards: number;
  coStakeAmount: number;
  coStakeContribution: number;
  ownerContribution: number;
  ownerStakeAmount: number;
  coStakeStatus: string;
  coStakerAmount: number;
  stakingAllowed?: boolean;
  overflowAmount: number;
  netAmountStaked: number;
  canUnstakeCustomAmount?: boolean;
  coStakeOfferId?: string;
  type: "staked" | "costaked";
  isCoStaker?: boolean;
};

export type StakableDeviceOffersRequestOptions = {
  page?: number;
  limit?: number;
};

export type StakableDeviceOffersResult = {
  resultCount: number;
  results: StakableDeviceOffer[];
};

export type StakableDeviceOffer = Record<string, string>;

export type CoStakingOfferFormType = {
  contribution: number;
  sharePercentage: number;
};

export interface CoStakableDeviceResponse {
  id: number;
  device_id: string;
  owner_account: string;
  owner_id: string;
  costaker_account: string;
  costaker_id: string;
  shared_block_rewards_percent: number;
  status: string;
  co_stake_amount: number;
  created_at: string;
  minimum_stake_amount: number;
  stake_amount: number;
  hardware_id: number;
  hardware_quantity: number;
  brand_id: number;
  hardware_name: string;
  brand_name: string;
  total_count: number;
  earned_block_rewards: number;
  estimated_block_rewards: number;
  reliability_score: number;
  co_stake_offer_id: string;
  cooldown_ends_at: string;
}

export interface CoStakableDevice {
  id: number;
  deviceId: string;
  ownerAccount: string;
  ownerId: string;
  coStakerAccount: string;
  coStakerId: string;
  sharedBlockRewardsPercent: number;
  status: string;
  coStakeAmount: number;
  createdAt: string;
  minimumStakeAmount: number;
  stakeAmount: number;
  hardwareId: number;
  hardwareQuantity: number;
  brandId: number;
  brandName: string;
  totalCount: number;
  estimatedBlockRewards: number;
  coStakerEstimatedBlockRewards: number;
  coStakeContribution: number;
  ownerContribution: number;
  ownerStakeAmount: number;
  hardwareName: string;
  hardwareManufacturerColor: string;
  hardwareManufacturerIcon?: typeof NvidiaIcon;
  hardwareManufacturer: string;
  reliabilityScore: number;
  minimumStaked: number;
  canUnstakeCustomAmount?: boolean;
  amountStaked: number;
  coStakeOfferId: string;
  amountWithdrawable: number;
  type: string;
  frozenTimeRemaining?: string;
  frozenTimeShortRemaining?: string;
  frozenTimeRemainingAsPercentage?: number;
  amountFrozen: number;
  earnedBlockRewards: number;
}

export type CoStakeFilterFormType = {
  hardware: string;
  device: string;
  blockRewardPercentage: number[];
  requestedAmountMin: string;
  requestedAmountMax: string;
  deviceReliabilityScore: number[];
};

export type DeviceHardwareResponse = {
  hardware_id: number;
  brand_id: number;
  brand_name: string;
  hardware_name: string;
};

export type StakedTotalResult = {
  totalStakedAmount: number;
  totalInCooldown: number;
  totalWithdrawable: number;
  updatedDevices: UserStakedDevice[];
};

export type CoStakedTotalResult = {
  totalStakedAmount: number;
  totalInCooldown: number;
  totalWithdrawable: number;
  updatedDevices: CoStakableDevice[];
};

export type UserStakingTransactionsResponse = {
  date: string;
  hash: string;
  type: string;
  amount: number;
  is_amount_withdrawn: boolean;
  user_wallet: string;
};

export type UserStakingTransactionsType = {
  date: string;
  hash: string;
  type: string;
  amount: number;
  isAmountWithdrawn: boolean;
  userWallet: string;
  cooldownEndTime?: string;
  cooldownTimeRemaining?: string;
  cooldownEndTimeAsPercentage?: number;
  getCooldownTooltip?: (userTimezone: string | undefined) => string | undefined;
};

export type StakingRewardsType = {
  date: string;
  rewards: number;
};

export type UserStakingRewardsResponse = {
  total_user_rewards: number;
  todays_total_rewards: number;
  rewards: StakingRewardsType[];
};

export type UserStakingRewardsType = {
  totalUserRewards: number;
  todaysTotalRewards: number;
  rewards: StakingRewardsType[];
};

export type DayAprType = {
  date: string;
  apr: number;
};

export type WithdrawStakingAmountType = {
  totalAmountInCooldown: number;
  totalWithdrawableAmount: number;
};
