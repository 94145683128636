import { IconProps } from ".";

export const CoinIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 43 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.108 9C1.548 9 0.672 8.112 0.672 6.552V3.048C0.672 1.488 1.548 0.599999 3.108 0.599999H10.332C10.524 0.599999 10.62 0.696 10.62 0.887999V1.44C10.62 1.632 10.524 1.74 10.332 1.74H3.42C2.472 1.74 1.956 2.244 1.956 3.192V6.408C1.956 7.356 2.472 7.86 3.42 7.86H10.332C10.524 7.86 10.62 7.968 10.62 8.16V8.712C10.62 8.904 10.524 9 10.332 9H3.108ZM16.0174 9C14.4574 9 13.5814 8.112 13.5814 6.552V3.048C13.5814 1.488 14.4574 0.599999 16.0174 0.599999H21.7414C23.3134 0.599999 24.1894 1.488 24.1894 3.048V6.552C24.1894 8.112 23.3134 9 21.7414 9H16.0174ZM21.4414 7.86C22.3894 7.86 22.8934 7.356 22.8934 6.408V3.192C22.8934 2.244 22.3894 1.74 21.4414 1.74H16.3294C15.3814 1.74 14.8654 2.244 14.8654 3.192V6.408C14.8654 7.356 15.3814 7.86 16.3294 7.86H21.4414ZM28.7385 0.887999V8.712C28.7385 8.904 28.6305 9 28.4385 9H27.7305C27.5385 9 27.4425 8.904 27.4425 8.712V0.887999C27.4425 0.696 27.5385 0.599999 27.7305 0.599999H28.4385C28.6305 0.599999 28.7385 0.696 28.7385 0.887999ZM32.0419 3.048C32.0419 1.488 32.9179 0.599999 34.4899 0.599999H39.9379C41.4979 0.599999 42.3739 1.488 42.3739 3.048V8.712C42.3739 8.904 42.2779 9 42.0859 9H41.3779C41.1859 9 41.0779 8.904 41.0779 8.712V3.192C41.0779 2.244 40.5739 1.74 39.6259 1.74H34.7899C33.8419 1.74 33.3379 2.244 33.3379 3.192V8.712C33.3379 8.904 33.2419 9 33.0499 9H32.3419C32.1499 9 32.0419 8.904 32.0419 8.712V3.048Z"
        fill="url(#paint0_linear_15661_5907)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15661_5907"
          x1="-3.19061"
          y1="1.14887"
          x2="30.9427"
          y2="1.14887"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A9AC" />
          <stop offset="0.0091653" stopColor="#AAACAF" />
          <stop offset="0.1095" stopColor="#C9CACC" />
          <stop offset="0.203" stopColor="#DFE0E1" />
          <stop offset="0.2859" stopColor="#ECEDEE" />
          <stop offset="0.35" stopColor="#F1F2F2" />
          <stop offset="0.4681" stopColor="#EAEBEC" />
          <stop offset="0.6561" stopColor="#D7D8DA" />
          <stop offset="0.8892" stopColor="#B8BABC" />
          <stop offset="1" stopColor="#A7A9AC" />
        </linearGradient>
      </defs>
    </svg>
  );
};
