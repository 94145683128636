import { IconProps } from ".";

export const DbunkerBWIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.80873 11.3571V19.5H9.78262C11.8008 19.5 13.5759 19.1019 15.1076 18.3057C16.6573 17.5095 17.8556 16.3966 18.7026 14.9672C19.5675 13.5376 20 11.8819 20 10C20 8.1181 19.5675 6.46238 18.7026 5.03285C17.8556 3.60334 16.6573 2.49047 15.1076 1.69429C13.5759 0.898095 11.8008 0.5 9.78262 0.5H1.80873V8.64281H0V11.3571H1.80873ZM4.51175 11.3571V17.1385H9.62044C11.1882 17.1385 12.5397 16.84 13.6749 16.2428C14.8282 15.6457 15.7202 14.8134 16.3509 13.7457C16.9817 12.66 17.297 11.4115 17.297 10C17.297 8.57047 16.9817 7.32191 16.3509 6.25428C15.7202 5.18666 14.8282 4.35428 13.6749 3.75715C12.5397 3.16 11.1882 2.86143 9.62044 2.86143H4.51175V8.64281H8.10904V11.3571H4.51175Z"
        fill="white"
        style={{ fill: "white", fillOpacity: "1" }}
      />
    </svg>
  );
};
