import { IconProps } from ".";

export const IoIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 28 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.9273 2.97475e-05H2.91928L7.1669 2.82797H19.9273C20.4226 2.82797 20.8303 3.22053 20.8303 3.70276V5.81341L23.7496 7.75686V3.70276C23.7496 1.66723 22.0311 2.97475e-05 19.9273 2.97475e-05Z"
        fill="url(#paint0_linear_14702_15586)"
      />
      <path
        d="M10.992 12H28L23.7524 9.17207H10.992C10.4967 9.17207 10.089 8.77708 10.089 8.29727V6.18662L7.16966 4.24317V8.29727C7.16966 10.3377 8.88572 12 10.992 12Z"
        fill="url(#paint1_linear_14702_15586)"
      />
      <path d="M2.9193 4.24314H0V12H2.9193V4.24314Z" fill="url(#paint2_linear_14702_15586)" />
      <path
        d="M0 0V2.42325L2.81674 4.29885L2.9193 4.24314L2.91928 2.97475e-05L0 0Z"
        fill="url(#paint3_linear_14702_15586)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14702_15586"
          x1="-2.03039"
          y1="2.14887"
          x2="19.6908"
          y2="2.14887"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A9AC" />
          <stop offset="0.0091653" stopColor="#AAACAF" />
          <stop offset="0.1095" stopColor="#C9CACC" />
          <stop offset="0.203" stopColor="#DFE0E1" />
          <stop offset="0.2859" stopColor="#ECEDEE" />
          <stop offset="0.35" stopColor="#F1F2F2" />
          <stop offset="0.4681" stopColor="#EAEBEC" />
          <stop offset="0.6561" stopColor="#D7D8DA" />
          <stop offset="0.8892" stopColor="#B8BABC" />
          <stop offset="1" stopColor="#A7A9AC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_14702_15586"
          x1="-2.03039"
          y1="2.14887"
          x2="19.6908"
          y2="2.14887"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A9AC" />
          <stop offset="0.0091653" stopColor="#AAACAF" />
          <stop offset="0.1095" stopColor="#C9CACC" />
          <stop offset="0.203" stopColor="#DFE0E1" />
          <stop offset="0.2859" stopColor="#ECEDEE" />
          <stop offset="0.35" stopColor="#F1F2F2" />
          <stop offset="0.4681" stopColor="#EAEBEC" />
          <stop offset="0.6561" stopColor="#D7D8DA" />
          <stop offset="0.8892" stopColor="#B8BABC" />
          <stop offset="1" stopColor="#A7A9AC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_14702_15586"
          x1="-2.03039"
          y1="2.14887"
          x2="19.6908"
          y2="2.14887"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A9AC" />
          <stop offset="0.0091653" stopColor="#AAACAF" />
          <stop offset="0.1095" stopColor="#C9CACC" />
          <stop offset="0.203" stopColor="#DFE0E1" />
          <stop offset="0.2859" stopColor="#ECEDEE" />
          <stop offset="0.35" stopColor="#F1F2F2" />
          <stop offset="0.4681" stopColor="#EAEBEC" />
          <stop offset="0.6561" stopColor="#D7D8DA" />
          <stop offset="0.8892" stopColor="#B8BABC" />
          <stop offset="1" stopColor="#A7A9AC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_14702_15586"
          x1="-2.03039"
          y1="2.14887"
          x2="19.6908"
          y2="2.14887"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A9AC" />
          <stop offset="0.0091653" stopColor="#AAACAF" />
          <stop offset="0.1095" stopColor="#C9CACC" />
          <stop offset="0.203" stopColor="#DFE0E1" />
          <stop offset="0.2859" stopColor="#ECEDEE" />
          <stop offset="0.35" stopColor="#F1F2F2" />
          <stop offset="0.4681" stopColor="#EAEBEC" />
          <stop offset="0.6561" stopColor="#D7D8DA" />
          <stop offset="0.8892" stopColor="#B8BABC" />
          <stop offset="1" stopColor="#A7A9AC" />
        </linearGradient>
      </defs>
    </svg>
  );
};
