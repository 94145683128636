import { AuthToken } from "./auth";

export type UserMetaData = {
  io_id: string;
  name: string;
  solana_address: string;
  phone_code: string;
  phone_number: string;
  phone_number_code: string;
  phone_number_verified: boolean;
  is_provider: boolean;
  approved_terms_version?: string;
  wallet_type: string;
  wallet_address: string;
  wallet_address_solana?: string;
  wallet_address_aptos?: string;
  wallet_verified: boolean;
  role: string;
  viewed_welcome_message?: boolean;
  viewed_io_elements_message?: boolean;
  picture: string;
  timezone: string;
  solana_wallet_addresses?: UserWalletType[];
  aptos_wallet_addresses?: UserWalletType[];
};

export enum BalanceType {
  CLOUD = "cloud",
  WORKER = "worker",
  BC8 = "bc8",
  IOWORKER = "ioWorker",
  IOCLOUD = "ioCloud"
}

export enum NotificationType {
  SUCCESS = "success",
  CRITICAL = "critical",
  ERROR = "error",
  INFO = "info",
  PROMO = "promo"
}

export type NotificationFilterType = "device" | "cluster" | "all";

export enum CookiePreferenceType {
  FUNCTIONAL = "functional",
  ANALYTICS = "analytics",
  TRACKING = "tracking"
}

export type UserAuthToken = AuthToken & {
  "https://io.net/user": {
    created: boolean;
    io_id: string;
    primary_mail: string;
  };
};

export type UserInfo = {
  blockRewardsSuspicious?: boolean;
  isWorker?: boolean;
  isCloud?: boolean;
  inventoryAccess?: boolean;
};

export type UserWalletType = {
  wallet_address: string;
  is_primary: boolean;
  is_active: boolean;
  wallet_type: "solana" | "aptos";
  created_at: string;
};
