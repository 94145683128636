import { useUserStore } from "@/store/user";
import { useEffect, useState } from "react";
import { BellIcon } from "@/components/Icons/BellIcon";
import { Button } from "@/components/ui/button";
import { NOTIFICATION_MAP } from "@/utils/mapping";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { NotificationFilterType, NotificationType } from "@/types/user";
import { UserNotificationResults } from "../UserNotificationResults/UserNotificationResults";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem
} from "@/components/ui/select";
import { usePaginatedUserNotificationStore } from "@/store/paginated";

const NOTIFICATION_TYPES = [
  {
    label: "All",
    value: "all"
  },
  {
    label: "Device Related",
    value: "device"
  },
  {
    label: "Account Related",
    value: "cluster"
  }
];

export const UserNotificationsButton = () => {
  const [open, setOpen] = useState(false);
  const [hasUnread, setHasUnread] = useState(false);
  const resultCount = usePaginatedUserNotificationStore((state) => state.resultCount);
  const markAllNotificatiosnAsRead = useUserStore((store) => store.markAllNotificatiosnAsRead);
  const [priority, setPriority] = useState<NotificationType | "all">("all");
  const [type, setType] = useState<NotificationFilterType>("all");
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const fetchResults = useUserStore((state) => state.fetchNotificationsRequest);
  const fetchAll = usePaginatedUserNotificationStore((state) => state.fetchResults);
  const reset = usePaginatedUserNotificationStore((state) => state.reset);
  const [isFull, setIsFull] = useState(false);

  const handleMarkAllRead = async () => {
    setIsLoading(true);

    try {
      await markAllNotificatiosnAsRead();
      if (isFull) {
        await fetchAll();
      } else {
        reset();
      }
      toast({ variant: "info", title: "All messages marked as read" });
    } catch (e) {
      toast({ variant: "error", title: e instanceof Error ? e.message : "Something went wrong" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      return;
    }
    const interval = setInterval(async () => {
      const response = await fetchResults({
        page: 1,
        limit: 5,
        seen: false
      });
      setHasUnread(!!response.results.length);
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [open, setHasUnread, fetchResults]);

  return (
    <Popover
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
      }}
    >
      <PopoverTrigger asChild>
        <button
          data-testid={"UserNotificationsButton_trigger"}
          className="subtext-small flex flex-row items-center gap-2 text-gray focus:outline-none"
        >
          <div className="relative flex h-[32px] w-[32px] items-center justify-center rounded-full border border-gray-dark-1200">
            <BellIcon
              data-testid={"UserNotificationsButton_bellIcon"}
              className={`h-[16px] text-gray-dark-1000`}
            />
            {hasUnread && (
              <div className="absolute bottom-[-2px] right-[-2px] h-[12px] w-[12px] rounded-full border-2 border-black bg-red-light-800"></div>
            )}
          </div>
        </button>
      </PopoverTrigger>
      <PopoverContent
        className={`relative z-40 ${isFull ? "mr-[-92px] mt-[49px] min-h-[calc(100vh-100px)] w-[100vw] rounded-none lg:w-[600px]" : "mt-3 min-h-[260px] w-[370px] rounded-[12px]"}  flex flex-col  border border-gray-dark-900 bg-black p-0`}
        align="end"
      >
        <div className="flex flex-1 flex-col">
          <div
            className={`flex flex-col items-start gap-2 md:flex-row ${isFull ? "md:items-end" : "items-center"} flex-wrap border-b border-b-gray-dark-900 p-6`}
          >
            <div className={`flex gap-2 ${isFull ? "flex-col" : "flex-row items-center"}`}>
              {!isFull && <BellIcon className={`h-[16px] text-gray-dark-1000`} />}
              <span className={`${isFull ? "title-h5" : "label-small"} text-white`}>
                Notifications{" "}
                {!!resultCount && <span className=" text-gray-dark-1000">({resultCount})</span>}
              </span>
              {isFull && (
                <p className="subtext-small text-gray-dark-1000">
                  Be updated on your notifications all the time
                </p>
              )}
            </div>

            {!!resultCount && (
              <Button
                variant="link"
                onClick={handleMarkAllRead}
                className={cn(
                  "subtext-small ml-auto h-auto gap-2 p-0 text-gray-dark-1000 underline",
                  isLoading ? "pointer-events-none" : ""
                )}
              >
                <span>Mark All As Read</span>
              </Button>
            )}
            {isFull && (
              <div className="mt-4 flex w-full flex-col items-center gap-4 lg:flex-row">
                <div className="hidden w-full gap-1 rounded-lg border border-gray-dark-900 bg-gray-dark-1400 p-[1px] md:visible md:flex lg:w-fit">
                  {NOTIFICATION_TYPES.map((item) => (
                    <button
                      type="button"
                      onClick={() => {
                        setType(item.value as NotificationFilterType);
                      }}
                      key={`tab-${item.value}`}
                      className={cn(
                        "label-small inline-flex w-[125px] items-center justify-center whitespace-nowrap rounded-[8px] px-3 py-1",
                        type === item.value
                          ? "bg-gray-dark-1100 text-white"
                          : " text-gray-dark-1000"
                      )}
                    >
                      {item.label}
                    </button>
                  ))}
                </div>
                <Select
                  onValueChange={(e: NotificationType) => setPriority(e)}
                  value={priority}
                  name="timezone"
                >
                  <SelectTrigger className="h-[32px]">
                    <SelectValue>
                      <div className="group-active flex flex-row items-center gap-2 text-gray-dark-1000">
                        <span>{(priority && NOTIFICATION_MAP[priority]?.label) || "All"}</span>
                      </div>
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="all">All</SelectItem>
                      {Object.values(NOTIFICATION_MAP).map((item) => {
                        return (
                          <SelectItem key={item.type} value={item.type}>
                            {item.label}
                          </SelectItem>
                        );
                      })}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            )}
          </div>
          <div
            className={`custom-scrollbar ${isFull ? "max-h-[calc(100vh-100px-153px)]" : "max-h-[530px]"} overflow-y-auto overflow-x-hidden px-6 py-4`}
          >
            <UserNotificationResults
              setIsFull={setIsFull}
              isFull={isFull}
              type={type}
              priority={priority}
            />
          </div>
          {!isFull && (
            <div className="border-t border-b-gray-dark-900 px-6 py-4">
              <Button onClick={() => setIsFull(true)} variant="outline" className="w-full">
                View All Notifications
              </Button>
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
