import { parse } from "qs";

export let hasRedirected = false;

export const redirectOnce = (url: string) => {
  console.log("redirectOnce", url, hasRedirected);
  if (hasRedirected) {
    return;
  }
  hasRedirected = true;
  window.location.href = url;

  setTimeout(() => {
    hasRedirected = false;
  }, 500);
};

export const redirectUsingQuery = (defaultUrl: string) => {
  const parsed = parse(window.location.search?.slice(1));
  return (parsed.redirect as string) ?? defaultUrl;
};

window.addEventListener(
  "popstate",
  () => {
    hasRedirected = false;
  },
  false
);
