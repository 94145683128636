import { PublicKey } from "@solana/web3.js";
import { IDL } from "./programIdl";
import { IDLV2, Iostaking } from "./programIdlV2";
import env from "@/env";
import { getStakingNetworkApiEndpoint } from "@/utils/staking";

export const CLUSTER = env.STAKING_NETWORK;

// export const SOLANA_HOST = clusterApiUrl(CLUSTER);
export const SOLANA_HOST = getStakingNetworkApiEndpoint();

export const IO_STAKING_PROGRAM_ID = new PublicKey(env.STAKING_CONTRACT_ADDRESS);

export const NEW_PROGRAM_ID = new PublicKey(IDLV2.address.toString());

export const IO_STAKING_IDL = IDL;

export const IO_STAKING_IDL_V2 = IDLV2 as Iostaking;
