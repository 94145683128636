import { ToolLogo } from "@/components/Common/ToolLogo/ToolLogo";
// import { UserBalance } from "@/components/Common/UserBalance/UserBalance";
import { useMainStore } from "@/store/main";
import { SmartLink } from "../Common/SmartLink/SmartLink";
import { Tool } from "@/types";
// import { BalanceType } from "@/types/user";

type IoElementsListType = {
  height?: string;
  padding?: string;
  classColumn2?: string;
  onClick?: (item: Tool) => void;
};

export const IoElementsList = ({ onClick }: IoElementsListType) => {
  const getToolOptions = useMainStore((state) => state.getToolOptions);

  return getToolOptions().map((item) => {
    const { value, href } = item;
    // const withBalance = value === "worker" || value === "cloud";
    return (
      <SmartLink
        data-testid={`MainLogo_${value}Link`}
        key={href}
        href={href}
        onClick={onClick ? () => onClick(item) : undefined}
      >
        <div
          style={{
            backgroundImage: `url(${item.bg})`,
            backgroundSize: "101% 101%"
          }}
          className={`item relative flex h-20 cursor-pointer flex-col items-stretch justify-between rounded-[8px] border border-gray-dark-900 bg-gray-dark-600 bg-cover
             bg-bottom bg-no-repeat p-2 transition-colors hover:border-gray-dark-1200 md:h-[120px] md:p-4
            `}
        >
          <ToolLogo tool={item} />
        </div>
      </SmartLink>
    );
  });
};
