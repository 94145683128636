import { IconProps } from ".";

export const ThreeDotsIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 16 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="2" cy="2.5" r="2" fill="currentColor" />
      <circle cx="8" cy="2.5" r="2" fill="currentColor" />
      <circle cx="14" cy="2.5" r="2" fill="currentColor" />
    </svg>
  );
};
