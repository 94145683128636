import { ThreeDotsIcon } from "@/components/Icons/ThreeDotsIcon";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { UserNotification } from "@/types";

type NotificationItemType = {
  children?: React.ReactNode;
  notification: UserNotification;
  markAsRead?: (item: UserNotification) => void;
};

export const NotificationItem = ({ notification, markAsRead }: NotificationItemType) => {
  return (
    <div
      className={`relative mb-2 flex flex-row rounded-[12px] border border-gray-dark-900 bg-opacity-50 ${!notification.isRead ? notification.gradient + " bg-gradient-to-r to-transparent" : ""} p-3`}
    >
      {notification.icon ? (
        <notification.icon
          className={`mr-3 mt-[2px] h-[16px] ${notification.isRead ? "[&_path]:!fill-gray-dark-1000" : ""}`}
        />
      ) : null}
      <div className="flex-1">
        <div
          className={`label-small group relative mb-1 pr-6 capitalize ${notification.isRead ? "text-gray-dark-1000" : "text-white"} `}
        >
          {notification.title}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <button
                className={`justify-centertransition-all absolute bottom-0 right-0 top-0 m-auto flex h-7 w-7 items-center`}
              >
                <ThreeDotsIcon className="h-4 w-4" />
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="min-w-max border-0 p-0">
              <DropdownMenuItem className="border-0 p-0">
                <Button variant="outline" onClick={() => markAsRead && markAsRead(notification)}>
                  {notification.isRead ? "Mark As Unread" : "Mark As Read"}
                </Button>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="subtext-small text-gray-dark-1000">{notification.text}</div>
      </div>
    </div>
  );
};
