import * as anchor from "@project-serum/anchor";
import { AnchorProvider, Program, Provider } from "@coral-xyz/anchor-0.30.1";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { IO_STAKING_IDL, IO_STAKING_IDL_V2, IO_STAKING_PROGRAM_ID } from "./const";
import { Iostaking } from "./programIdlV2";
import { Connection } from "@solana/web3.js";
import { AnchorWallet } from "@solana/wallet-adapter-react";

export function getProgramInstance(connection: Connection, wallet: AnchorWallet) {
  if (!wallet.publicKey) throw new WalletNotConnectedError();

  const provider = new anchor.AnchorProvider(
    connection,
    wallet,
    anchor.AnchorProvider.defaultOptions()
  );

  const idl = IO_STAKING_IDL;

  const programId = IO_STAKING_PROGRAM_ID;

  const program = new anchor.Program(idl as anchor.Idl, programId, provider);

  return program;
}

export function getProgramInstanceV2(connection: Connection, wallet: AnchorWallet) {
  if (!wallet.publicKey) throw new WalletNotConnectedError();

  const provider = new AnchorProvider(connection, wallet, {
    commitment: "confirmed"
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const program = new Program(IO_STAKING_IDL_V2 as any, provider) as Program<Iostaking>;

  return program;
}

export function getProgramInstanceInternal(connection: Connection) {
  const provider = { connection: connection } as Provider;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const program = new Program(IO_STAKING_IDL_V2 as any, provider) as Program<Iostaking>;

  return program;
}
