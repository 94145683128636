import { IconProps } from ".";

export const AuthIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.076 0.887999V8.712C2.076 8.904 1.968 9 1.776 9H1.068C0.876 9 0.78 8.904 0.78 8.712V0.887999C0.78 0.696 0.876 0.599999 1.068 0.599999H1.776C1.968 0.599999 2.076 0.696 2.076 0.887999ZM5.43938 0.887999C5.43938 0.696 5.53538 0.599999 5.72738 0.599999H12.9874C14.8234 0.599999 15.8074 1.62 15.8074 3.42V6.18C15.8074 7.98 14.8234 9 12.9874 9H5.72738C5.53538 9 5.43938 8.904 5.43938 8.712V0.887999ZM14.5114 6.048V3.552C14.5114 2.376 13.8754 1.74 12.6874 1.74H6.73538V7.86H12.6874C13.8754 7.86 14.5114 7.224 14.5114 6.048Z"
        fill="url(#paint0_linear_15661_5897)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15661_5897"
          x1="-1.23274"
          y1="1.14887"
          x2="11.9551"
          y2="1.14887"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7A9AC" />
          <stop offset="0.0091653" stopColor="#AAACAF" />
          <stop offset="0.1095" stopColor="#C9CACC" />
          <stop offset="0.203" stopColor="#DFE0E1" />
          <stop offset="0.2859" stopColor="#ECEDEE" />
          <stop offset="0.35" stopColor="#F1F2F2" />
          <stop offset="0.4681" stopColor="#EAEBEC" />
          <stop offset="0.6561" stopColor="#D7D8DA" />
          <stop offset="0.8892" stopColor="#B8BABC" />
          <stop offset="1" stopColor="#A7A9AC" />
        </linearGradient>
      </defs>
    </svg>
  );
};
